import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserCertificateBody = () => {
  return (
    <AppUserDetailsLayout moduleName="certifications">
      <div class="table_area">
        <div class="tablesrch_top">
          <div class="table_srch_wrap">
            <button type="submit" class="tble_srchbtn">
              <span class="material-icons-outlined">search</span>
            </button>
            <input
              type="text"
              value=""
              class="form-control"
              placeholder="Search by survey name"
            />
          </div>
          <div class="table_action">
            <ul>
              <li class="dropdown">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  class="basic_btn ylw_btn "
                >
                  Actions
                  <span class="material-icons-outlined">arrow_drop_down</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="javascript:void(0);">Add New </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Delete Selected</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Update Rank</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_innr_scroll">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="head_chk_outer">
                    <div class="profile_chkList table_chk">
                      <label class="radio-check">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </div>
                    <div class="chk_drop dropdown">
                      <a href="javascript:void(0);" data-bs-toggle="dropdown">
                        {" "}
                        <span class="material-icons-outlined">
                          arrow_drop_down
                        </span>
                      </a>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Date</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Certification</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Marks Obtain</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Marks Required</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Valid From</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Valid To</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Grade</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>

                <th>
                  <div class="tble_head">
                    <span>Function</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">In Progress</h6>
                  </div>
                </td>
                <td>Google Designer Certificate</td>
                <td>
                  7<span>/10</span>
                </td>
                <td>
                  5<span>/10</span>
                </td>
                <td>01-01-2022</td>
                <td>01-01-2025</td>
                <td>70%</td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_list">
          <ul>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_back_ios</i>
              </a>
            </li>
            <li class="active">
              <a href="javascript:void(0);">1</a>
            </li>
            <li>
              <a href="javascript:void(0);">2</a>
            </li>
            <li>
              <a href="javascript:void(0);">3</a>
            </li>
            <li>
              <a href="javascript:void(0);">..</a>
            </li>
            <li>
              <a href="javascript:void(0);">10</a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_forward_ios</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default UserCertificateBody;
