import React from "react";
import { Link } from "react-router-dom";

const ChallengeMemberHeader = () => {
  return (
    <div class="filter-container mb-3">
      <div class="d-flex justify-content-sm-end align-items-center">
        <button
          type="button"
          class="btn btn-info d-flex align-items-center rounded-0 shadow-none me-3"
          data-bs-toggle="modal"
          data-bs-target="#saveChallengeMemberModal"
          area-label="saveChallengeMemberModalLabel"
        >
          <i class="material-icons me-2">add</i> Invite Contact
        </button>

        <div class="dropdown">
          <button
            class="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul class="dropdown-menu dropdown-menu-end rounded-0 border-0">
            <li>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#saveChallengeMemberModal"
                aria-controls="saveChallengeMemberModal"
              >
                Invite Contact
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                Send Mail
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChallengeMemberHeader;
