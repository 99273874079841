import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LessonHeader = ({
  updateBulkLessonHandler = () => {},
  deleteMassiveLessonHandler = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="filter-container mb-3">
      <div className="d-flex flex-wrap justify-content-end align-items-center gap-3">
        <div className="dropdown" id="module-mass-action">
          <button
            className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => {
                  document.body.classList.toggle("emailpopup_open");
                }}
              >
                {t("Add New")}
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                onClick={updateBulkLessonHandler}
              >
                {t("Update Rank")}
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                onClick={deleteMassiveLessonHandler}
              >
                {t("Delete Selected")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LessonHeader;
