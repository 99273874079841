import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const AppuserCourseBody = () => {
  return (
    <AppUserDetailsLayout moduleName="courses">
      <div class="table_area">
        <div class="tablesrch_top">
          <div class="table_srch_wrap">
            <button type="submit" class="tble_srchbtn">
              <span class="material-icons-outlined">search</span>
            </button>
            <input
              type="text"
              value=""
              class="form-control"
              placeholder="Search by survey name"
            />
          </div>
          <div class="table_action">
            <ul>
              <li class="dropdown">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  class="basic_btn ylw_btn "
                >
                  Actions
                  <span class="material-icons-outlined">arrow_drop_down</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="javascript:void(0);">Add New </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Delete Selected</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Update Rank</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_innr_scroll">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="tble_head">
                    <span>Date/Profile</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Survey</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Module</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Component</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Question</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Rating</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Action</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">Pending</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a
                      href="javascript:void(0);"
                      class="action_details_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#feedback_modal"
                    >
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">Pending</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  3 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  4 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  2 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  3 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="date_text feedbckdate_txt">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                  <div class="date_usr_outer">
                    <div class="date_usrpic">
                      <img src="images/user1.png" alt="" />
                    </div>
                    <div class="date_usrtext">
                      <h5>Hello Luthar</h5>
                    </div>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  2 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <a href="javascript:void(0);" class="action_details_btn">
                      Details
                      <i class="material-icons-outlined">arrow_forward_ios</i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_list">
          <ul>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_back_ios</i>
              </a>
            </li>
            <li class="active">
              <a href="javascript:void(0);">1</a>
            </li>
            <li>
              <a href="javascript:void(0);">2</a>
            </li>
            <li>
              <a href="javascript:void(0);">3</a>
            </li>
            <li>
              <a href="javascript:void(0);">..</a>
            </li>
            <li>
              <a href="javascript:void(0);">10</a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_forward_ios</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default AppuserCourseBody;
