import React from "react";
import { Link } from "react-router-dom";

const MailTemplateListHeader = ({ deleteTemplates = () => {} }) => {
  return (
    <div className="filter-container mb-3">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div className="d-flex justify-content-sm-end align-items-center">
            <Link
              to="/admin/communicationtemplate/create"
              className="btn btn-info rounded-0 shadow-none me-3"
            >
              <i className="material-icons">add</i> Add New
            </Link>

            <div className="dropdown">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={deleteTemplates}
                  >
                    Delete Selected
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailTemplateListHeader;
