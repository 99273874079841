/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const AssignBlogModal = ({
  afterModalClose = () => {},
  selectedFaqId = null,
  setSelectedFaqId = () => {},
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation();

  const [blogLoading, setBlogLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [faqBlogs, setFaqBlogs] = useState([]);

  const [blogName, setBlogName] = useState("");

  const [checkedBlogs, setCheckedBlogs] = useState({}); // To store checked state

  const [isSaving, setisSaving] = useState(false);

  const [validation, setValidation] = useState({
    blogError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (faqBlogs.length === 0) {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get blog list
  const getAllBlogList = async () => {
    try {
      setBlogLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_BLOG +
        `?modulefrom=backend&userid=${userInfo._id}`;

      const response = await getData(requestURL);

      setBlogLoading(false);

      console.log(response);

      if (response.status) {
        setBlogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Filtered list based on search input if length > 2
  const filteredBlogs =
    blogName.length > 2
      ? blogList.filter((course) =>
          course.name.toLowerCase().includes(blogName.toLowerCase())
        )
      : blogList;

  //function for check expert
  const blogCheckHandler = (blogId, isChecked) => {
    setCheckedBlogs((prevState) => ({
      ...prevState,
      [blogId]: isChecked, // Update checked state for the specific expert
    }));

    if (isChecked) {
      // Add to faqExperts if checked, if not already there
      if (!faqBlogs.includes(blogId)) {
        setFaqBlogs((prevState) => [...prevState, blogId]);
      }
    } else {
      // Remove from faqExperts if unchecked
      setFaqBlogs((prevState) => prevState.filter((id) => id !== blogId));
    }
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const data = response.data;

        if (data.posts && data.posts.length > 0) {
          for (let post of data.posts) {
            blogCheckHandler(post, true);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save faq function
  const saveFaqHandler = async (e) => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let apiData = {
          posts: faqBlogs,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedFaqId) {
          requestURL += url.API_UPDATE_FAQ + `/${selectedFaqId}?token=${token}`;

          response = await putData(requestURL, apiData);
        }

        setisSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal
          let currentModal = document.querySelector("#assignBlogModal");
          let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
          bootstrapModal.hide();

          resetHandler();
          afterModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setBlogList([]);
    setFaqBlogs([]);
    setBlogName("");
    setCheckedBlogs({});
    setSelectedFaqId(null);

    setValidation({
      blogError: false,
    });
  };

  useEffect(() => {
    if (selectedFaqId) {
      getAllBlogList();
      getFaqDetails();
    }
  }, [selectedFaqId]);

  return (
    <div
      className="modal fade"
      id="assignBlogModal"
      aria-labelledby="assignBlogModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="modal-title w-100" id="assignBlogModalLabel">
              Assign Contents
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 m-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="search position-relative mb-4">
                <button className="d-block position-absolute top-50 text-primary material-icons bg-transparent ms-3 translate-middle-y">
                  search
                </button>
                <input
                  type="text"
                  className="form-control bg-gray-100 rounded-0 shadow-none ps-5"
                  placeholder="Search post by name"
                  id="expertSearch"
                  value={blogName}
                  onChange={(e) => setBlogName(e.target.value)}
                />
                {validation.blogError && (
                  <div className="text-danger mt-2">
                    Please select at least one posts.
                  </div>
                )}
              </div>

              <div className="form-row mb-4">
                <label htmlFor="question" className="fw-bold mb-2">
                  Content / Post
                </label>

                <ul
                  className="courses"
                  id="appcourses"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {blogLoading
                    ? [1, 2, 3].map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="px-3 py-3 bg-gray-100 text-green d-flex align-items-center gap-3 placeholder-glow"
                          >
                            <span className="placeholder col-6"></span>
                          </li>
                        );
                      })
                    : filteredBlogs.map((blog, index) => {
                        return (
                          <li
                            key={index}
                            className="px-3 py-3 bg-gray-100 text-green d-flex align-items-center gap-3"
                          >
                            <input
                              type="checkbox"
                              className="w-auto"
                              id={`expert_checkbox_${blog._id}`}
                              checked={!!checkedBlogs[blog._id]} // Checked state
                              value={blog._id}
                              onChange={(e) =>
                                blogCheckHandler(blog._id, e.target.checked)
                              }
                            />
                            <span className="d-block">{blog.name}</span>
                          </li>
                        );
                      })}
                </ul>
              </div>
              <div className="form-action">
                <button
                  type="button"
                  className="btn btn-yellow rounded-0 m-0"
                  onClick={saveFaqHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Add Content
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignBlogModal;
