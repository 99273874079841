import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserAccountBody = () => {
  return (
    <AppUserDetailsLayout moduleName="account">
      <div class="interest_hdng">
        <div class="accoun_left_con">
          <h4>Close Account</h4>
          <p>
            Hello Akash Ghosal, we would love for you to stay... but if you want
            to leave us we thank you for the time we have been together.
          </p>
        </div>

        <div class="mail_text">
          <label>Email</label>
          <a href="mailto:dummy.name@email.com">dummy.name@email.com</a>
        </div>
      </div>
      <div class="profile_graybx_Outer">
        <div class="profile_graybx_item">
          <div class="profile_fillOuter wd_260">
            <div class="profile_fill_row">
              <label>Because you are leaving? </label>
              <div class="input_fill">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter feedback"
                />
              </div>
            </div>
            <div class="profile_fill_row">
              <label>How can we convince you to stay?</label>
              <div class="input_fill">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter feedback"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="prfile_btnOuter">
        <div class="profile_leftBtn"></div>
        <div class="profile_rightBtn">
          <button class="basic_btn">Close Account</button>
          <button class="basic_btn ylw_btn">Giving Another Chance</button>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default UserAccountBody;
