import React from "react";
import { Link, useParams } from "react-router-dom";

const ChallengeDetailsCommonHeader = ({ moduleName = "" }) => {
  const { id } = useParams();

  return (
    <section className="course_tablinks_outer learning_coursetablinks">
      <div className="container-fluid">
        <div className="tab_links">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                to={`/admin/challanges/save/${id}`}
                className={`nav-link ${
                  moduleName === "details" ? "active" : ""
                }`}
              >
                <i className="material-icons-outlined">save</i>
                <span>Save Challenge</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/challanges/phases/${id}`}
                className={`nav-link ${
                  moduleName === "process" ? "active" : ""
                }`}
              >
                <i className="material-icons-outlined">developer_board</i>
                <span>Process</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/challanges/addmedia/${id}`}
                className={`nav-link ${moduleName === "docs" ? "active" : ""}`}
              >
                <i className="material-icons-outlined">quiz</i>
                <span>DOCs</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/challanges/members/${id}`}
                className={`nav-link ${
                  moduleName === "members" ? "active" : ""
                }`}
              >
                <i className="material-icons-outlined">group_add</i>
                <span>Members</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/challanges/responses/report/${id}`}
                className={`nav-link ${
                  moduleName === "responses" ? "active" : ""
                }`}
              >
                <i className="material-icons-outlined">quickreply</i>
                <span>Responses</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ChallengeDetailsCommonHeader;
