/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";
import { downloadFileHandler } from "Helper/CommonHelper/CommonHelper";

const TaskDetailsPopup = ({
  selectedTaskId,
  setselectedTaskId,
  afterpopupClose,
  setShowAlert,
  setMessageType,
  setAlertMessage,
}) => {
  const { id: courseId } = useParams();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState("");
  const [submitDate, setsubmitDate] = useState("");
  const [reviewDate, setreviewDate] = useState("");
  const [completedStatus, setcompletedStatus] = useState("");
  const [lessonName, setlessonName] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [score, setscore] = useState("");

  const [commentList, setCommentList] = useState([]);

  const [comment, setcomment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log("task details", response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
        setlessonName(response.data.lessonname);
        setscore(response.data?.score);
        setcompletedStatus(response.data.completedpercent);
        setstartDate(response.data.taskstartdate);
        setsubmitDate(response.data.tasksubmitdate);
        setreviewDate(response.data.taskreviewdate);

        setCommentList(response.data.comments);

        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for task approval
  const taskApproveHandler = async (status = "0") => {
    const todayValue = new Date().toISOString().split("T")[0];

    try {
      setIsSaving(true);

      let taskData = {
        approvalstatus: status,
        taskcomment: comment,
      };

      if (status === "1") {
        taskData.completedstatus = "3";
        taskData.reviewdate = todayValue;
      }

      console.log(taskData);

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_LESSON_ASSIGNMENT +
        `/${selectedTaskId}?token=${token}`;

      const response = await putData(requestURL, taskData);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        resetHandler();
        afterpopupClose();
        popupToggle();
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  // Reset all the form fields and state variables to their initial values
  const resetHandler = () => {
    settitle("");
    setdetails("");
    setlessonName("");
    setUploadedDocFiles([]);
    setselectedTaskId(null);
    setCommentList([]);
    setcomment("");
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
    }
  }, [selectedTaskId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link to="#" onClick={popupToggle}>
                <i className="material-icons-round"> close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>Review assignments by subscribers</h3>

              <p className="mb-1">
                {completedStatus === "3" ? (
                  <span className="passed align-items-center text-success ownerapproved">
                    <i className="material-icons me-1">check_circle</i> Accepted
                    and Approved
                  </span>
                ) : (
                  <span className="passed align-items-center text-warning ownerapprovalpending">
                    <i className="material-icons me-1">access_time</i> Pending
                    Approval
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="course_form_details_innr ps-3 pe-2">
        <div className="course_detailsbx_item">
          <div className="profile_fillOuter">
            <div className="profile_fill_row course_fill_row">
              <label>Lesson</label>
              <div
                className="form-control border-0 formtaskdata"
                id="tasklesson"
              >
                {lessonName}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Task </label>
              <div className="form-control border-0">
                <span className="formtaskdata" id="tasktitle">
                  {title}
                </span>
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Details</label>
              <div
                className="form-control border-0  formtaskdata"
                id="taskdescription"
                dangerouslySetInnerHTML={{ __html: details }}
              ></div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Start Date</label>
              <div
                className="form-control border-0  formtaskdata"
                id="taskstart"
              >
                {startDate}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Submit Date</label>
              <div
                className="form-control border-0  formtaskdata"
                id="tasksubmit"
              >
                {submitDate}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Review Date</label>
              <div
                className="form-control border-0 formtaskdata"
                id="taskreviewed"
              >
                {reviewDate}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Attachments</label>

              <div
                className="form-control border-0  formtaskdata"
                id="taskattachment"
              >
                {uploadedDocFiles.map((file, index) => {
                  return (
                    <div
                      className="mb-2"
                      key={index}
                      onClick={() => {
                        downloadFileHandler(file);
                      }}
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                      <Link to="#">{file.name}</Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Comments</label>
              <div
                className="form-control border-0  formtaskdata"
                id="taskcomment"
              >
                {commentList.map((comment, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <p>{comment.description}</p>
                      <i className="text-gray">
                        {comment.user} - {comment.startdate}
                      </i>
                    </div>
                  );
                })}
              </div>
            </div>

            {completedStatus === "3" ? null : (
              <div className=" course_fill_row reviewfield">
                <hr />
                <label>Comment on Task Review</label>
                <div className="input_fill description_fill mt-1">
                  <textarea
                    className="form-control"
                    name="reviewcomment"
                    value={comment}
                    onChange={(e) => setcomment(e.target.value)}
                  ></textarea>
                </div>
              </div>
            )}

            {completedStatus === "3" ? null : (
              <div className="profile_fill_row course_fill_row my-2 reviewfield">
                <div className="input_fill">
                  <div className="profile_rightBtn">
                    <button
                      type="button"
                      name="approvalstatus"
                      className="basic_btn ylw_btn"
                      onClick={() => {
                        taskApproveHandler("1");
                      }}
                      style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                      disabled={isSaving ? true : false}
                    >
                      Approve
                      {isSaving && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                    <button
                      type="button"
                      name="approvalstatus"
                      className="basic_btn btn-info"
                      onClick={() => {
                        taskApproveHandler("2");
                      }}
                      style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                      disabled={isSaving ? true : false}
                    >
                      Reject
                      {isSaving && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                    <button
                      type="button"
                      className="basic_btn btn-gray"
                      onClick={() => {
                        popupToggle();
                        resetHandler();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsPopup;
