import React from "react";
import { Link } from "react-router-dom";

const DashboardProgress = () => {
  return (
    <div className="dashboad_progress_outer">
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  style={{
                    "--percent": "50",
                  }}
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Points</h5>
                <h3>3/10</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  style={{
                    "--percent": "20",
                  }}
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Courses</h5>
                <h3>2/5</h3>
                <span className="avg_round">Avg 6</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 10"
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Certifications</h5>
                <h3>1/5</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 25"
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Survey</h5>
                <h3>1/5</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 70"
                ></circle>
              </svg>
              <div className="number_value">
                <h5> Winning forum</h5>
                <h3>3/5</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 80"
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Events</h5>
                <h3>8/10</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 50"
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Jobs/ Challenges</h5>
                <h3>2/5</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx="105" cy="105" r="100"></circle>
                <circle
                  cx="105"
                  cy="105"
                  r="100"
                  //   style="--percent: 10"
                ></circle>
              </svg>
              <div className="number_value">
                <h5>Feedback</h5>
                <h3>1/5</h3>
                <span className="avg_round">Avg 5</span>
              </div>
            </div>
            <div className="details_link">
              <Link to="#">
                <span>Open Details</span>
                <i className="material-icons-outlined">arrow_forward_ios</i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardProgress;
