import { Link } from "react-router-dom";
import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";

const CourseUploadBody = () => {
  return (
    <>
      <AuthHeader />

      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <div className="row col-12">
                <h5 className="name mb-2">
                  <Link to="#" className="text-warning fw-bold">
                    No courses found to import
                  </Link>
                </h5>
              </div>

              <hr />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseUploadBody;
