/* eslint-disable */
import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import AppUserDetailsCommonHeader from "./AppUserDetailsCommonHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const AppUserDetailsLayout = ({
  children,
  moduleName = "",
  breadcrumbText = [],
}) => {
  return (
    <>
      <AuthHeader />

      <AppUserDetailsCommonHeader moduleName={moduleName} />

      <section className="profile_tabcontent_sec">
        <div className="container-fluid">
          <BreadCrumb breadCrumbText={breadcrumbText} />

          {children}
        </div>
      </section>
    </>
  );
};

export default AppUserDetailsLayout;
