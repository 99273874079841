/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { putData } from "Utils/Gateway";

const UpdateCertificateModal = ({
  closeModalHandler = () => {},
  afterModalClose = () => {},
  selectedSubscriberId,
  selectedSubscriberUserId,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [comment, setComment] = useState("");
  const [isUpdating, setisUpdating] = useState(false);

  //function for update subscriber
  const updateSubscriberHandler = async () => {
    const todayValue = new Date().toISOString().split("T")[0];

    setisUpdating(true);

    try {
      let subscriberData = {
        iscertified: true,
        certificationdate: todayValue,
        certificationcomment: comment,
      };

      console.log(subscriberData);

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_COURSE_SUBSCRIBER +
        `/${selectedSubscriberId}?token=${token}&courseid=${courseId}&coursesubscriberid=${selectedSubscriberUserId}&coursememberstatus="1`;

      console.log(requestURL);

      const response = await putData(requestURL, subscriberData);

      setisUpdating(false);

      console.log(response);

      setisUpdating(false);

      if (response.status) {
        resetHandler();
        afterModalClose();
        // close the modal
        let currentModal = document.querySelector("#modal_savequestion");
        let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
        bootstrapModal.hide();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setComment("");
  };

  return (
    <div class="edit_modalbx">
      <div
        class="modal fade show"
        id="modal_savequestion"
        tabindex="-1"
        role="dialog"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeModalHandler();
                  resetHandler();
                }}
              >
                <span class="material-icons-outlined">close </span>
              </button>
            </div>
            <div class="modal-body edit_modalbody">
              <div class="srvay_feedhdng">
                <h2>Certification Approval</h2>
              </div>

              <div class="edit_fillOuter">
                <div class="row">
                  <div class="col-md-12">
                    <div class="input_fill white_fill">
                      <label>
                        Confirm certification approval with comments for academy
                        *
                      </label>

                      <textarea
                        name="comment"
                        class="form-control mw-100"
                        cols="30"
                        rows="100"
                        style={{
                          resize: "auto",
                          minHeight: "unset",
                          height: "80px",
                          width: "880px",
                        }}
                        required=""
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="submit_survay">
                      <button
                        type="button"
                        class="basic_btn ylw_btn"
                        style={{
                          cursor: isUpdating ? "not-allowed" : "pointer",
                        }}
                        disabled={isUpdating ? true : false}
                        onClick={updateSubscriberHandler}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCertificateModal;
