/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import SlideHeader from "../Header/SlideHeader";
import SaveSlideModal from "../Modal/SaveSlideModal";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import Loading from "Components/Common/Loading/Loading";

const SlideList = () => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Administration") },
    { title: t("Slides") },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [slideList, setSlideList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedSlideIds, setSelectedSildeIds] = useState([]);
  const [selectedSlideId, setSelectedSlideId] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all slides
  const getAllSlides = async () => {
    setSelectedSildeIds([]);
    setRowSelection({});

    try {
      let requestURL = url.API_BASE_URL + url.API_GET_ALL_SLIDES;

      const response = await getData(requestURL);

      if (response.status) {
        setMessageType("success");
        setSlideList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedSlideList = [...slideList];
    const rowIndex = updatedSlideList.findIndex(
      (slide) => slide._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedSlideList[rowIndex].order = value;
      setSlideList(updatedSlideList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (action = "") => {
    if (selectedSlideIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = slideList.filter((item) =>
          selectedSlideIds.includes(item._id)
        );

        let apiData = {
          slidelist: listToBeUpdated,
        };

        if (action === "delete") {
          apiData.status = "0";
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_SLIDE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllSlides();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one blog");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllSlides();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#modal_saveslide"
          aria-controls="modal_saveslide"
          onClick={() => {
            setSelectedSlideId(row.original._id);
          }}
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "title",
      header: t("Title"),
      size: 200,
    },
    {
      accessorKey: "slidelanguage",
      header: t("Language"),
      size: 150,
    },
    {
      accessorKey: "code",
      header: t("Code"),
      size: 150,
    },
    {
      accessorKey: "order",
      header: t("Order"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="number"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.order}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "slidebanner",
      header: t("Banner"),
      size: 150,
      Cell: ({ row }) => (
        <img
          width={150}
          height={70}
          className="thumbnail"
          src={url.SERVER_URL + row.original.slidebanner}
        ></img>
      ),
    },
    {
      accessorKey: "createddate",
      header: t("Date"),
      size: 150,
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [i18n.language, slideList]
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedSildeIds(selectedIdsArray);
    } else {
      setSelectedSildeIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />
            <SlideHeader updateBulkHandler={updateBulkHandler} />

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={slideList} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                  columnOrder: columnOrder,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                  columnOrder: columnOrder,
                }}
                enableColumnOrdering={true}
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Title"),
                }}
              />
            </div>
          </div>
        </div>

        {/* modal  */}
        <SaveSlideModal
          afterModalClose={() => {
            setTimeout(() => {
              getAllSlides();
            }, 1500);
          }}
          selectedSlideId={selectedSlideId}
          setSelectedSlideId={setSelectedSlideId}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {isUpdating && <Loading />}

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </>
  );
};

export default SlideList;
