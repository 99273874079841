import * as url from "Helper/UrlHelper";
import { getData } from "Utils/Gateway";
export const getCurrentTime = () => {
  // Create a new Date object
  const now = new Date();

  // Get hours and minutes
  let hours = now.getHours().toString().padStart(2, "0"); // Ensure 2 digits for hours
  const minutes = now.getMinutes().toString().padStart(2, "0"); // Ensure 2 digits for minutes

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0 (midnight), set it to 12

  // Combine hours, minutes, and AM/PM in hh:mm AM/PM format
  const currentTime = `${hours
    .toString()
    .padStart(2, "0")}:${minutes} ${period}`;

  return currentTime;
};

export const getAllTagsByUser = async () => {
  const token = localStorage.getItem("token");

  try {
    let requestURL =
      url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

    const response = await getData(requestURL);

    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const getAllCategories = async (parentSlug) => {
  try {
    let requestURL =
      url.API_BASE_URL + url.API_GET_CATEGORIES + `?ecosystemslug=INDRA`;

    if (parentSlug && parentSlug !== "") {
      requestURL += `&slug=${parentSlug}`;
    }

    const response = await getData(requestURL);

    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const downloadFileHandler = async (file) => {
  try {
    const response = await fetch(file.path);
    const blob = await response.blob();
    const fileName = file.name;
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};
