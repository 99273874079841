/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const AddToListModal = ({
  selectedIds = [],
  afterModalClose = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  const [allList, setAllList] = useState([]);

  const [listValue, setlistValue] = useState(null);
  const [selectedListId, setselectedListId] = useState(null);
  const [listContacts, setListContacts] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  //get all lsit
  const getAllList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_LIST + `?token=${token}`;

      const response = await getData(requestURL);

      //   console.log(response);

      if (response.status) {
        setAllList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select list
  const listSelectionHandler = (val) => {
    if (val) {
      setlistValue(val);
      setselectedListId(val.value);

      const contacts = val.contactids;
      setListContacts(contacts);
    } else {
      setlistValue(null);
      setselectedListId(null);
      setListContacts([]);
    }
  };

  //add to list function
  const addToListHandler = async () => {
    if (selectedListId && selectedIds.length > 0) {
      if (selectedListId) {
        try {
          setIsAdding(true);

          let listData = {
            contactids: [...new Set([...listContacts, ...selectedIds])],
          };

          let requestURL =
            url.API_BASE_URL +
            url.API_UPDATE_A_LIST +
            `/${selectedListId}` +
            `?token=${token}`;

          const response = await putData(requestURL, listData);

          setIsAdding(false);

          if (response.status) {
            resetHandler();
            setMessageType("success");
            // close the modal of addLabel
            let listModal = document.querySelector("#addToListModal");
            let modal = bootstrap.Modal.getInstance(listModal);
            modal.hide();

            afterModalClose();
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }
      } else {
        setAlertMessage("Please select a list");
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please select a user from the list");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setlistValue(null);
    setselectedListId(null);
    setListContacts([]);
  };

  useEffect(() => {
    getAllList();
  }, []);

  return (
    <div
      className="modal fade"
      id="addToListModal"
      aria-labelledby="addToListModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 border-0">
            <h2 className="text-green mb-0" id="addToListModalLabel">
              {t("Add to List")}
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="form-row">
              <label htmlFor="list" className="fw-bold mb-2">
                {t("Select List")}
              </label>
              <Select
                isClearable
                placeholder={t("Select List")}
                options={allList}
                value={listValue}
                onChange={(val) => listSelectionHandler(val)}
              />
            </div>
          </div>
          <div className="modal-footer border-0 p-4">
            <button
              type="button"
              className="btn btn-yellow d-flex align-items-center rounded-0 m-0 ms-auto"
              onClick={addToListHandler}
              style={{ cursor: isAdding ? "not-allowed" : "pointer" }}
              disabled={isAdding ? true : false}
            >
              {isAdding ? (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i className="material-icons me-2">save</i>
              )}

              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToListModal;
