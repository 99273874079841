/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import EventListHeader from "./Header/EventListHeader";
import EventListFilterModal from "./Modal/EventListFilterModal";

const EventListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const breadCrumbText = [{ title: t("Events"), link: "#" }];

  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterRank, setFilterRank] = useState("");
  const [filterZipCode, setFilterZipCode] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterSearchings, setFilterSearchings] = useState([]);
  const [filterInterests, setFilterInterests] = useState([]);

  const [isRefresh, setIsRefresh] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedEventIds, setSelectedEventIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get course list
  const getAllEvents = async () => {
    setRowSelection({});
    setSelectedEventIds([]);

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&modulefrom=backend`;

      if (filterRank != "") {
        requestURL += "&filterrank=" + filterRank;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle != "") {
        requestURL += `&filtertitle=${filterTitle}`;
      }

      if (filterLocation != "") {
        requestURL += `&filteraddress=${filterLocation}`;
      }

      if (filterCategories.length > 0) {
        requestURL += `&filtercategory=${filterCategories.join(",")}`;
      }

      if (filterSearchings.length > 0) {
        requestURL += `&filtersearchings=${filterSearchings.join(",")}`;
      }

      if (filterInterests.length > 0) {
        requestURL += `&filterinterest=${filterInterests.join(",")}`;
      }

      if (filterZipCode !== "") {
        requestURL += `&filterzipcode=${filterZipCode}`;
      }

      console.log("url", requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setEventList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...eventList];

    const rowIndex = updatedList.findIndex(
      (event) => event._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].updatedrank = value;
      setEventList(updatedList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (e) => {
    if (selectedEventIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = eventList.filter((item) =>
          selectedEventIds.includes(item._id)
        );

        let apiData = {
          eventlist: listToBeUpdated,
        };

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_MASSIVE_EVENT + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllEvents();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one group");
    }
    setShowAlert(true);
  };

  //function for delete group
  const deleteMassiveHandler = async () => {
    if (selectedEventIds.length > 0) {
      const listToBeDelete = eventList.filter((item) =>
        selectedEventIds.includes(item._id)
      );

      try {
        let apiData = {
          eventlist: listToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_EVENTS + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllEvents();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one item");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setFilterTitle("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterLocation("");
    setFilterCategories([]);
    setFilterSearchings([]);
    setFilterInterests([]);
    setFilterRank("");
    setFilterZipCode("");

    setIsRefresh(true);
  };

  useEffect(() => {
    if (
      filterRank !== "" ||
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterTitle !== "" ||
      filterLocation !== "" ||
      filterZipCode !== "" ||
      filterCategories.length > 0 ||
      filterSearchings.length > 0 ||
      filterInterests.length > 0
    ) {
      getAllEvents();
    }
  }, [
    filterTitle,
    filterStartDate,
    filterEndDate,
    filterRank,
    filterLocation,
    filterZipCode,
    filterCategories,
    filterSearchings,
    filterInterests,
  ]);

  useEffect(() => {
    if (isRefresh) {
      getAllEvents();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getAllEvents();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "title",
      header: t("Title"),
      size: 200,
      Cell: ({ row }) => (
        <div className="event-name">
          <h6 className="mb-2">
            <Link to="#" className="text-green">
              {row.original.title}
            </Link>
          </h6>

          <p className="d-flex align-items-center fw-medium "></p>
          <div className="profile d-flex align-items-center mb-2">
            <div className="img-container rounded-circle overflow-hidden me-2">
              <img
                src={
                  row.original.moderatorimage === ""
                    ? "/images/default-user.png"
                    : url.SERVER_URL + row.original.moderatorimage
                }
                className="img-fluid thumbnail"
                width="25"
                height="25"
              />
            </div>
            <p className="name">{row.original.moderatorname}</p>
          </div>

          {row.original.rank === 0 ? (
            <p className="d-flex align-items-center fw-medium  reviewing">
              <i className="material-icons me-2">access_time</i>
              <span>Pending Publish</span>
            </p>
          ) : (
            <p className="d-flex align-items-center fw-medium  text-success">
              <i className="material-icons me-2">check_circle_outline</i>
              <span>Published</span>
            </p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <input
            type="number"
            className="form-control rounded-0 shadow-none bg-white"
            value={row.original.updatedrank ?? row.original.rank}
            onChange={(e) => rankInputChangeHandler(row, e.target.value)}
          />
        </div>
      ),
    },
    {
      accessorKey: "eventstartdate",
      header: t("Event Date"),
      size: 200,
    },
    {
      accessorKey: "groupmembercount",
      header: t("Members"),
      size: 200,
      Cell: ({ row }) => (
        <div className="d-flex flex-column gap-2">
          <p className="activemembers text-success">
            {" "}
            {row.original.activeeventmembercount} Active{" "}
          </p>

          <p className="invitedmembers text-gray-600">
            {row.original.eventmembercount} Interested{" "}
          </p>
        </div>
      ),
    },
    {
      accessorKey: "city",
      header: t("Place"),
      Cell: ({ row }) => (
        <div className="">
          <i className="material-icons me-1">pin_drop</i> {row.original.address}{" "}
          <br /> {row.original.city},
        </div>
      ),
    },
    {
      accessorKey: "eventaddeddate",
      header: t("Added on"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [eventList, i18n.language]
  );

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedEventIds(selectedIdsArray);
    } else {
      setSelectedEventIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />
          <EventListHeader
            refreshHandler={resetFilter}
            deleteHandler={deleteMassiveHandler}
            updateBulkHandler={updateBulkHandler}
          />

          <div className="table-wrapper mb-5">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={eventList} // data from api to be displayed
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{
                rowSelection,
              }} //pass our managed row selection state to the table to use
              initialState={{
                rowSelection,
              }}
              enableRowActions // show the action column
              enableColumnOrdering={true}
              renderRowActionMenuItems={({ row }) => {
                const menuItems = [
                  <MenuItem key="edit">
                    <Link
                      className="dropdown-item"
                      to={`/admin/event/update/${row.id}`}
                    >
                      {t("Edit")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="members">
                    <Link
                      className="dropdown-item"
                      to={`/admin/event/members/list/${row.id}`}
                    >
                      {t("Members")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="gallery">
                    <Link
                      className="dropdown-item"
                      to={`/admin/event/addmedia/${row.id}`}
                    >
                      {t("Gallery")}
                    </Link>
                  </MenuItem>,
                ];

                return menuItems;
              }} // action columns menu items
              //   onColumnVisibilityChange={onColumnVisiblityHandler}
              //   onColumnOrderChange={changeColumnOrderHandler}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by name / moderator"),
              }}
            />
          </div>
        </div>
      </section>

      <EventListFilterModal
        setFilterRank={setFilterRank}
        setFilterStartDate={setFilterStartDate}
        setFilterEndDate={setFilterEndDate}
        setFilterTitle={setFilterTitle}
        setFilterLocation={setFilterLocation}
        setFilterZipCode={setFilterZipCode}
        setFilterCategories={setFilterCategories}
        setFilterSearchings={setFilterSearchings}
        setFilterInterests={setFilterInterests}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default EventListBody;
