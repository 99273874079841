import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import DashboardFaq from "./DashboardFaq/DashboardFaq";
import DashboardProgress from "./DashboardProgress/DashboardProgress";

const DashboardBody = () => {
  return (
    <>
      <AuthHeader />

      <section className="dashboard_area">
        <div className="container-fluid">
          <div className="dashbrd_hdng">
            <h3>The Campions Way</h3>
          </div>

          <DashboardProgress />

          <DashboardFaq />
        </div>
      </section>
    </>
  );
};

export default DashboardBody;
