/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BreadCrumb = ({ breadCrumbText = [], displayName = "" }) => {
  const { t } = useTranslation();

  return (
    <div className="breadcrumb-container py-3">
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to="/admin/dashboard" className="text-blue">
            {t("Dashboard")}
          </Link>
        </li>

        {/* --- dynamic bread crumb text start ---- */}
        {breadCrumbText.map((item, index) => {
          return (
            <li
              key={index}
              className={`breadcrumb-item ${
                index === breadCrumbText.length - 1 && "active"
              } `}
            >
              {index === breadCrumbText.length - 1 ? (
                <span>{item.title}</span>
              ) : (
                <Link to={item.link ? item.link : "#"} className="text-blue">
                  {item.title}
                </Link>
              )}
            </li>
          );
        })}

        {/* <li className="breadcrumb-item">
          <a href="/crm-event-list.html/" className="text-blue">
            Events
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          <span>Figma Design Event 2022</span>
        </li> */}

        {displayName !== "" ? (
          <li className={`breadcrumb-item active`}>
            <span>{displayName}</span>
          </li>
        ) : null}
      </ol>
    </div>
  );
};

export default BreadCrumb;
