/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import {
  getData,
  postData,
  uploadMultipleFile,
  uploadSingleFile,
} from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

const GenerateDebateModal = ({
  afterModalClose = () => {},
  selectedFaqId = null,
  setSelectedFaqId = () => {},
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [categoryList, setcategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);
  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  const [tagValue, settagValue] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setisSaving] = useState(false);

  const editor = useRef(null);

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    height: "315",
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "CAT_ACTIVITY";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        try {
          setIsUploading(true);
          let requestURL =
            url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

          const response = await uploadMultipleFile(requestURL, targetFiles);

          console.log(response);

          setIsUploading(false);

          resetFile();

          if (response.status) {
            const uploadedFileValues = response.data.map(
              ({ name, path, _id, ...other }) => ({
                name,
                path: url.SERVER_URL + path,
                _id,
              })
            );

            const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

            //check old file exist or not if exist then new file will concat with old file
            if (media.length === 0) {
              setMedia(uploadedFileValues);
              setFileIds(fileIds);
            } else {
              setMedia([...media, ...uploadedFileValues]);
              setFileIds([...fileIds, ...fileIds]);
            }
          }
        } catch (error) {}
      }
    } else {
      return;
    }
  };

  //function for select tags
  const tagSelectionHandler = (val) => {
    settagValue(val);
    setselectedTags(val.map((item) => item.value));
  };

  //function for select categories
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    setselectedCategories(val.map((item) => item.value));
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("forum_attachFiles");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  const deletFileHanlder = (index) => {
    const updatedIds = [...fileIds];
    const updatedmedia = [...media];
    const fileMediaindex = updatedIds.indexOf(index);
    const fileIdIndex = updatedmedia.indexOf(index);
    updatedIds.splice(fileIdIndex, 1);
    updatedmedia.splice(fileMediaindex, 1);
    setFileIds(updatedIds);
    setMedia(updatedmedia);
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const data = response.data;

        settitle(data.question);
        setdetails(data.answer);
        setFileIds(data.uploadids);
        setMedia(
          data.uploadedfiles.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //generate debate function
  const generateDebateHandler = async (e) => {
    try {
      setisSaving(true);

      let apiData = {
        title,
        description: details,
        uploads: fileIds,
        tags: selectedTags,
        categoryids: selectedCategories,
        faqid: selectedFaqId,
      };

      let requestURL =
        url.API_BASE_URL + url.API_GENERATE_DEBATE + `?token=${token}`;

      const response = await postData(requestURL, apiData);

      setisSaving(false);

      console.log(response);

      if (response.status) {
        // close the modal
        let currentModal = document.querySelector("#generateDebateModal");
        let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
        bootstrapModal.hide();

        resetHandler();
        afterModalClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  const resetHandler = () => {
    setcategoryList([]);
    setTagList([]);
    settitle("");
    setdetails("");
    setMedia([]);
    setFileIds([]);
    settagValue(null);
    setcategoryValue(null);
    setselectedCategories([]);
    setselectedTags([]);
    resetFile();
    setSelectedFaqId(null);
  };

  useEffect(() => {
    if (selectedFaqId) {
      getFaqDetails();
      getAllCategoryList();
      getAllTags();
    }
  }, [selectedFaqId]);

  return (
    <div
      className="modal fade"
      id="generateDebateModal"
      aria-labelledby="generateDebateModalLabel"
      style={{ display: "none" }}
      data-bs-backdrop="static"
      data-bs-keyword="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="modal-title w-100" id="generateDebateModalLabel">
              Generate a Debate in Forum
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 m-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-row mb-4">
                <label htmlFor="postYourReply" className="fw-bold mb-2">
                  Post Your Reply
                </label>
                <input
                  type="text"
                  id="forum_faqquestion"
                  className="form-control text-green rounded-0"
                  placeholder="Question"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
              <div className="form-row mb-4">
                <label htmlFor="details" className="fw-bold mb-2">
                  Details
                </label>
                <JoditEditor
                  ref={editor}
                  value={details}
                  config={detailsEditorConfig}
                  onBlur={(newContent) => {
                    // console.log("New content in on blur----->", newContent);
                    setdetails(newContent);
                  }}
                  onChange={(newContent, event) => {
                    // setMailDescription(newContent);
                  }}
                />
              </div>

              <div className="form-row mb-4">
                <label htmlFor="project" className="fw-bold mb-2">
                  Attach File
                </label>

                <input
                  id="forum_attachFiles"
                  type="file"
                  multiple
                  className="form-control"
                  onChange={multipleFileUploadHandler}
                />

                {isUploading && (
                  <div
                    className="d-flex align-items-center"
                    style={{ backgroundColor: "#044954" }}
                  >
                    <h6 className="text-white fw-bold">
                      {t("Uploading Files")}
                    </h6>
                    {spinCount.map((item) => {
                      return (
                        <div
                          key={item}
                          className="spinner-grow mx-1 text-white"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                )}

                <ul className="d-flex flex-wrap gap-2 text-center faq-upload-files mt-4">
                  {media.map((file, index) => {
                    const fileExtension = file.name.split(".").pop();

                    return (
                      <li
                        key={index}
                        className="file d-flex flex-column align-items-center justify-content-center position-relative mw-350"
                      >
                        {fileExtension === "jpg" ||
                        fileExtension === "png" ||
                        fileExtension === "jpeg" ||
                        fileExtension === "gif" ||
                        fileExtension === "png" ? (
                          <>
                            <Link
                              to="#"
                              className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                              onClick={() => deletFileHanlder(index)}
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                            <img
                              src={file.path}
                              className="img-fluid mb-2 thumb-img"
                              width={150}
                              alt="17365...jpg"
                            />
                            <p className="name mb-1">17365...jpg</p>
                          </>
                        ) : null}
                        {fileExtension === "doc" || fileExtension === "txt" ? (
                          <>
                            <Link
                              to="#"
                              className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                              onClick={() => deletFileHanlder(index)}
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                            <img
                              src="/images/doc.svg"
                              className="img-fluid mb-2"
                              alt="doc"
                            />
                            <p className="name mb-1">{file.name}</p>
                          </>
                        ) : null}

                        {fileExtension === "pdf" ? (
                          <>
                            <Link
                              to="#"
                              className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                              onClick={() => deletFileHanlder(index)}
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                            <img
                              src="/images/pdf.svg"
                              className="img-fluid mb-2"
                              alt="17365...pdf"
                            />
                            <p className="name mb-1">{file.name}</p>
                          </>
                        ) : null}
                        {fileExtension === "xlsx" ? (
                          <>
                            <Link
                              to="#"
                              className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                              onClick={() => deletFileHanlder(index)}
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                            <img
                              src="/images/xls.svg"
                              className="img-fluid mb-2"
                              alt="xlsx"
                            />
                            <p className="name mb-1">{file.name}</p>
                          </>
                        ) : null}
                        {fileExtension === "mp4" ? (
                          <>
                            {" "}
                            <Link
                              to="#"
                              className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                              onClick={() => deletFileHanlder(index)}
                            >
                              <span className="d-block material-icons-outlined">
                                delete
                              </span>
                            </Link>
                            <video
                              controls
                              className="img-fluid mb-2"
                              width={200}
                            >
                              <source src={file.path} type="video/mp4" />
                            </video>
                            <p className="name mb-1">{file.name}</p>{" "}
                          </>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="form-row mb-4">
                <label htmlFor="project" className="fw-bold mb-2">
                  Tags
                </label>

                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder="Select tags"
                  options={tagList}
                  value={tagValue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>
              <div className="form-row mb-4">
                <label htmlFor="project" className="fw-bold mb-2">
                  Categories
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder="Select categories"
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => {
                    categorySelectionHandler(val);
                  }}
                />
              </div>
              <div className="form-action">
                <button
                  type="button"
                  className="btn btn-yellow rounded-0 m-0 d-flex align-items-center gap-2"
                  onClick={generateDebateHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  <span>Confirm &amp; Publish Debate</span>
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateDebateModal;
