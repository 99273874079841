/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import GroupDetailsCommonHeader from "../Common/GroupDetailsCommonHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const GroupGalleryBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: groupId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Group"), link: "/admin/group/manage" },
    { title: "" },
  ]);

  const [imageUploading, setImageUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id

  const [galleryImages, setGalleryImages] = useState([]);
  const [gallerImageIds, setgallerImageIds] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //upload image
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setImageUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setImageUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setUploadedImageId(response.data._id);
        setUploadedPreviewImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("image_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //delete image
  const deleteImageHandler = async (index) => {
    const filteredBlock = [...galleryImages];
    const filterIds = [...gallerImageIds];
    filteredBlock.splice(index, 1);
    filterIds.splice(index, 1);
    setgallerImageIds(filterIds);
    setGalleryImages(filteredBlock);

    try {
      let groupData = {
        gallery: filterIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (groupId) {
        requestURL += url.API_UPDATE_GROUP + `/${groupId}?token=${token}`;
        response = await putData(requestURL, groupData);
      }

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Image deleted successfully"));
        setTimeout(() => {
          setUploadedImageId(null);
          setUploadedPreviewImage("");
          getgroupDetails();
        }, 1500);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for get group details
  const getgroupDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${groupId}?token=${token}`;

      console.log("url of group details------>", requestURL);

      const response = await getData(requestURL);

      console.log("response of group details------>", response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Group"), link: "/admin/group/manage" },
          { title: response.data.title },
        ]);

        setGalleryImages(response.data.gallery);
        setgallerImageIds(response.data.gallery.map((item) => item._id));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save group
  const saveGroupHandler = async () => {
    try {
      setIsSaving(true);

      let groupData = {
        gallery: [...gallerImageIds, uploadedImageId],
      };

      console.log(groupData);

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (groupId) {
        requestURL += url.API_UPDATE_GROUP + `/${groupId}?token=${token}`;
        response = await putData(requestURL, groupData);
      }

      setIsSaving(false);

      if (response.status) {
        setMessageType("success");
        setTimeout(() => {
          setUploadedImageId(null);
          setUploadedPreviewImage("");
          getgroupDetails();
        }, 1500);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (groupId) {
      getgroupDetails();
    } else {
      setBreadCrumbText([
        { title: t("Group"), link: "/admin/group/manage" },
        { title: t("Save") },
        { title: "" },
      ]);
    }
  }, [groupId]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {groupId ? (
        <GroupDetailsCommonHeader groupId={groupId} moduleName="gallery" />
      ) : null}

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="event_gallery_wrapper" className="p-4 bg-gray-100">
            <div className="image-upload-container mb-5">
              <label
                htmlFor="featuredImage"
                className="d-block fw-bold text-primary mb-3"
              >
                Gallery Image
              </label>

              <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                <label
                  htmlFor="logoUpload"
                  className="text-nowrap me-4 text-primary"
                >
                  Upload Images
                </label>
                <div className="fields-container d-flex flex-fill">
                  <input
                    type="file"
                    id="image_upload"
                    name="galleryphoto"
                    className="form-control rounded-0 shadow-none"
                    placeholder="JPG, PNG, GIF"
                    accept="image/*"
                    onChange={imageUploadHandler}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={saveGroupHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Add to group
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>

              {imageUploading ? (
                <div className="ms-2 spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : null}

              {uploadedPreviewImage === "" ? null : (
                <div className="img-container position-relative">
                  <img
                    src={url.SERVER_URL + uploadedPreviewImage}
                    id="photopreview"
                    className="img-fluid"
                  />
                  <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                    <Link
                      to="#"
                      className="text-white"
                      onClick={() => {
                        setUploadedPreviewImage("");
                        setUploadedImageId(null);
                      }}
                    >
                      <i className="d-block material-icons icon-sm">cancel</i>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <div className="gallery-images">
              <h6 className="text-primary fw-bold mb-3">All Gallery Images</h6>

              <ul className="images d-flex flex-wrap">
                {galleryImages.map((galleryImage, index) => (
                  <li className="image" key={index}>
                    <div className="img-container position-relative">
                      <img
                        src={url.SERVER_URL + galleryImage.path}
                        alt="Event Gallery"
                        className="img-fluid"
                        width={600}
                      />
                      <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                        <a
                          href={url.SERVER_URL + galleryImage.path}
                          className="text-white"
                          target="_blank"
                        >
                          <i className="d-block material-icons icon-sm">
                            visibility
                          </i>
                        </a>
                        <Link
                          to="#"
                          className="text-white"
                          onClick={() => {
                            deleteImageHandler(index);
                          }}
                        >
                          <i className="d-block material-icons icon-sm">
                            delete
                          </i>
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default GroupGalleryBody;
