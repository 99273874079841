import React from "react";
import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";

const CommonLayout = ({ children }) => {
  return (
    <>
      <AuthHeader />
      {children}
    </>
  );
};

export default CommonLayout;
