import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChallengeListHeader = ({
  updateBulkHandler = () => {},
  reloadList = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="row align-items-center">
      <div className="col-sm-12">
        <div className="filter-container mb-3">
          <div className="d-flex flex-wrap justify-content-md-end align-items-center gap-2">
            <button
              type="button"
              className="btn btn-secondary rounded-0 shadow-none"
              onClick={reloadList}
            >
              <i className="material-icons">refresh</i>
            </button>
            <button
              type="button"
              className="btn btn-info rounded-0 shadow-none"
              data-bs-toggle="modal"
              data-bs-target="#challengelistfilterModal"
            >
              <i className="bi bi-funnel"></i>
            </button>
            <Link
              to="/admin/challanges/save"
              type="button"
              className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
            >
              <i className="material-icons me-2">add</i>
              {t("Add New")}
            </Link>
            <div className="dropdown">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => updateBulkHandler("delete")}
                  >
                    {t("Delete Selected")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => updateBulkHandler("update")}
                  >
                    {t("Update Rank")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeListHeader;
