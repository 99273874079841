/* eslint-disable */
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import * as url from "Helper/UrlHelper";

const ContactInfoBody = ({ contactData = {} }) => {
  const { t } = useTranslation();

  return (
    <div className="col-lg-3 mb-4 mb-lg-0">
      <div className="profile h-100 bg-gray-100">
        <div className="user d-flex align-items-center bg-gray-500 position-relative p-4 mb-4">
          <Link to="#" className="me-3">
            <img
              width={40}
              src={
                contactData.contactimage === ""
                  ? "/images/default-user.png"
                  : url.SERVER_URL + contactData.contactimage
              }
              alt="Pierre Gagnaire"
              className="img-fluid rounded-circle"
            />
          </Link>
          <div className="name">
            <h5 className="fw-bold mb-1">{contactData.contactname}</h5>
            <span className="designation text-gray-600">
              {contactData.contactposition}
            </span>
          </div>
          <div className="dropdown position-absolute">
            <Link
              to="#"
              className="dropdown-toggle text-gray"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end border-0 rounded-0 mt-3"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  target="_blank"
                  to={`/admin/userdetails/manage/personalinfo/${contactData.contactpersonid}`}
                >
                  <i className="material-icons icon-sm me-2">edit</i>
                  {t("Manage Profile")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="contacts-info p-4">
          <h5 className="fw-bold mb-3">Contacts</h5>
          <ul className="contacts">
            <li className="email mb-3">
              <Link to="#" className="text-primary d-flex align-items-center">
                <i className="material-icons fs-18 me-2">mail_outline</i>{" "}
                {contactData.contactemail}
              </Link>
            </li>
            <li className="phone mb-3">
              <Link to="#" className="text-primary d-flex align-items-center">
                <i className="material-icons fs-18 me-2">phone_in_talk</i>{" "}
                {contactData.contactphone}
              </Link>
            </li>
            <li className="address d-flex mb-3">
              <svg
                width="18"
                height="21"
                className="me-2"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.96638 7.70038V4.31718L5.99998 2.96678L8.03358 4.31718V7.70038H6.63358V5.70038H5.36638V7.70038H3.96638ZM5.99998 11.8004C7.34452 10.5673 8.33625 9.45345 8.97518 8.45878C9.61411 7.46412 9.93358 6.57798 9.93358 5.80038C9.93358 4.58918 9.55572 3.60865 8.79998 2.85878C8.04425 2.10892 7.11092 1.73398 5.99998 1.73398C4.88905 1.73398 3.95572 2.10892 3.19998 2.85878C2.44425 3.60865 2.06638 4.58918 2.06638 5.80038C2.06638 6.57798 2.38585 7.46412 3.02478 8.45878C3.66372 9.45345 4.65545 10.5673 5.99998 11.8004ZM5.99998 13.6668C4.21118 12.1228 2.87518 10.7063 1.99198 9.41718C1.10825 8.12812 0.666382 6.92252 0.666382 5.80038C0.666382 4.12252 1.19972 2.79185 2.26638 1.80838C3.33305 0.825451 4.57758 0.333984 5.99998 0.333984C7.42238 0.333984 8.66691 0.825451 9.73358 1.80838C10.8002 2.79185 11.3336 4.12252 11.3336 5.80038C11.3336 6.92252 10.8917 8.12812 10.008 9.41718C9.12478 10.7063 7.78878 12.1228 5.99998 13.6668Z"
                  fill="#1A2034"
                ></path>
              </svg>
              <p>
                <span className="d-block text-primary mb-2">
                  {contactData.contactaddress}
                </span>
                <span className="d-block text-gray-600 mb-2">
                  {contactData.contactcity}, {contactData.contactcountry}
                </span>
                <span className="d-block text-gray-600">
                  {contactData.contactzipcode}
                </span>
              </p>
            </li>
            <li className="label mb-3">
              <Link to="#" className="text-primary d-flex align-items-center">
                <i className="material-icons fs-18 me-2">
                  label_important_outline
                </i>
                {contactData.tagstring}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoBody;
