import React from "react";
import { Link } from "react-router-dom";

const ContactListHeader = ({ reloadData = () => {} }) => {
  return (
    <div className="filter-container mb-3">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div className="d-flex justify-content-sm-end align-items-center">
            {/* <Link
              className="btn border-0 rounded-0 shadow-none me-1"
              to="#"
              onClick={() => moduleTour()}
            >
              <i className="material-icons">tour</i> Help
            </Link> */}

            <button
              type="button"
              className="btn btn-outline-info rounded-0 shadow-none me-3"
              onClick={reloadData}
            >
              <i className="material-icons">refresh</i>
            </button>

            <button
              type="button"
              className="btn btn-info rounded-0 shadow-none me-3"
              data-bs-toggle="modal"
              data-bs-target="#contactFilter"
              id="module-filter"
            >
              <i className="material-icons">filter_alt</i>
            </button>

            <div className="dropdown" id="module-mass-action">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end rounded-0 border-0"
                style={{}}
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    //</li>onClick={() => sendMail()}
                  >
                    Send Mail
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    //onClick={() => saveTags()}
                    data-bs-toggle="modal"
                    data-bs-target="#addTagModal"
                  >
                    Add Label
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addToListModal"
                  >
                    Add to List
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactListHeader;
