/* eslint-disable */
import { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";
import publishOptions from "data/Prod/PublishStatusOptions.json";

const EventListFilterModal = ({
  setFilterRank,
  setFilterStartDate,
  setFilterEndDate,
  setFilterTitle,
  setFilterLocation,
  setFilterZipCode,
  setFilterCategories,
  setFilterSearchings,
  setFilterInterests,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [title, settitle] = useState("");
  const [location, setlocation] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [publishValue, setpublishValue] = useState(null);
  const [selectedPublished, setselectedPublished] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);
  const [searchingValue, setsearchingValue] = useState(null);
  const [selectedSearchings, setselectedSearchings] = useState([]);
  const [interestValue, setinterestValue] = useState(null);
  const [selectedInterests, setselectedInterests] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (startDate === "" && endDate !== "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (endDate !== "" && endDate < startDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "CAT_GROUP";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all searching
  const getAllSearchingList = async () => {
    try {
      const parentSlug = "CAT_SEARCHING";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setsearchingList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all interest
  const getAllInterestList = async () => {
    try {
      const parentSlug = "CAT_INTEREST";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setinterestList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select publish
  const publishSelectionHandler = (val) => {
    if (val) {
      setpublishValue(val);
      setselectedPublished(val.value);
    } else {
      setpublishValue(null);
      setselectedPublished("");
    }
  };

  //select categroy
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    setselectedCategories(val.map((item) => item.value));
  };

  //select searchings
  const searchingSelectionHandler = (val) => {
    setsearchingValue(val);
    setselectedSearchings(val.map((item) => item.value));
  };

  //select interest
  const interestSelectionHandler = (val) => {
    setinterestValue(val);
    setselectedInterests(val.map((item) => item.value));
  };

  const applyFilterHandler = () => {
    if (validationHandler()) {
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
      setFilterTitle(title);
      setFilterLocation(location);
      setFilterZipCode(zipcode);
      setFilterCategories(selectedCategories);
      setFilterSearchings(selectedSearchings);
      setFilterInterests(selectedInterests);
      setFilterRank(selectedPublished);

      resetHandler();

      const filterModal = document.querySelector("#eventfilterModal");

      const modal = bootstrap.Modal.getInstance(filterModal);
      modal.hide();
    }
  };

  const resetHandler = () => {
    setstartDate("");
    setendDate("");
    settitle("");
    setlocation("");
    setzipcode("");
    setpublishValue(null);
    setselectedPublished("");
    setcategoryValue(null);
    setselectedCategories([]);
    setsearchingValue(null);
    setselectedSearchings([]);
    setinterestValue(null);
    setselectedInterests([]);

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  useEffect(() => {
    getAllCategoryList();
    getAllSearchingList();
    getAllInterestList();
  }, []);

  return (
    <div
      className="modal fade"
      id="eventfilterModal"
      aria-labelledby="eventfilterModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="eventfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  Status
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Status")}
                  options={publishOptions}
                  value={publishValue}
                  onChange={(val) => {
                    publishSelectionHandler(val);
                  }}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  Query / Name
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Name"
                  className="form-control text-green rounded-0"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="location" className="fw-bold mb-2">
                  Location
                </label>
                <input
                  type="text"
                  id="flocation"
                  name="location"
                  placeholder="Location"
                  className="form-control text-green rounded-0"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="zipcode" className="fw-bold mb-2">
                  Zipcode
                </label>
                <input
                  type="text"
                  id="fzipcode"
                  name="zipcode"
                  placeholder="Zipcode"
                  className="form-control text-green rounded-0"
                  value={zipcode}
                  onChange={(e) => setzipcode(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="category" className="fw-bold mb-2">
                  Category
                </label>
                <Select
                  isMulti
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Category")}
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => {
                    categorySelectionHandler(val);
                  }}
                />{" "}
              </div>

              <div className="form-row mb-3">
                <label htmlFor="searching" className="fw-bold mb-2">
                  Searching
                </label>
                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Searching")}
                  className="w-100"
                  isMulti
                  options={searchingList}
                  value={searchingValue}
                  onChange={(val) => searchingSelectionHandler(val)}
                />{" "}
              </div>

              <div className="form-row mb-3">
                <label htmlFor="interest" className="fw-bold mb-2">
                  Interest
                </label>
                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Interest")}
                  className="w-100"
                  isMulti
                  options={interestList}
                  value={interestValue}
                  onChange={(val) => interestSelectionHandler(val)}
                />
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    From Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    To Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                  />
                </div>
              </div>

              {validation.endDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
              {validation.startDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter start date!</span>
                  </p>
                </div>
              )}
              {validation.endDateSmallerWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>End date should not be greater than start date!</span>
                  </p>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={applyFilterHandler}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventListFilterModal;
