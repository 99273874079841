/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { postData } from "Utils/Gateway";

import PublicHeader from "Components/Common/Header/PublicHeader/PublicHeader";

const LoginBody = () => {
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  //function for login
  const loginHandler = async (e) => {
    e.preventDefault();
    switch (true) {
      case userEmail === "" || userPassword === "":
        userEmail === "" ? setEmailWarning(true) : setEmailWarning(false);
        userPassword === ""
          ? setPasswordWarning(true)
          : setPasswordWarning(false);
        break;
      default:
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEmail.match(mailformat)) {
          setNotValidEMail(false);
          setIsLoading(true);

          try {
            let userData = {
              email: userEmail,
              password: userPassword,
            };

            let requestURL = url.API_BASE_URL + url.API_LOGIN;

            console.log("request url -->", requestURL);

            const response = await postData(requestURL, userData);

            console.log("response -->", response);

            setIsLoading(false);

            if (response.status) {
              setLoginFailed(false);
              seterrorMessage("");
              // assigning the loggedin user's id,token,all data to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data));

              window.location.reload();
            } else {
              setLoginFailed(true);
              seterrorMessage(response.message);
            }
          } catch (error) {
            console.error(error.message);
          }
        } else {
          setNotValidEMail(true);
        }
    }
  };

  return (
    <>
      <PublicHeader />

      {/* <!-- ========== Start login Section ========== --> */}
      <section className="login_area">
        <div className="container">
          <div className="login_bxArea">
            <div className="signupleft_pnl">
              <div className="">
                <div className="login_hdng ">
                  <h2>Log In</h2>
                  <h5>Please enter details to proceed</h5>
                </div>
                <form onSubmit={loginHandler}>
                  <div className="login_fillOuter">
                    <div className="input_fill">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_fill ">
                      <label>Password</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="******"
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              loginHandler(e);
                            }
                          }}
                        />
                        <Link to="#" className="copy_link" onClick={toggleText}>
                          <span className="material-icons-outlined">
                            visibility
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="trms_check login_checkouter">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>
                        Agree with the <Link to="#">T&amp;C</Link>
                      </p>
                    </label>
                  </div>
                  <div className="login_submit">
                    <button
                      type="button"
                      className="basic_btn ylw_btn"
                      onClick={loginHandler}
                      disabled={isLoading ? true : false}
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                      }}
                    >
                      Login
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="signupright_pnl">
              <div className="signup_pic">
                <figure>
                  {" "}
                  <img
                    src="/images/login-pic.jpg"
                    alt=""
                    style={{ maxWidth: "90%" }}
                  />
                </figure>
                <div className="login_caption">
                  <h2>
                    <span>Discover Indra </span> ATM Ecosystem
                  </h2>
                  <p>
                    More than 25% of the world's total airspace is managed with
                    Indra's Automation Systems
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ========== End login Section ========== --> */}
    </>
  );
};

export default LoginBody;
