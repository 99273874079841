import React from "react";
import { Link, useParams } from "react-router-dom";

const EventDetailsCommonHeader = ({ moduleName = "" }) => {
  const { id } = useParams();

  return (
    <section className="event-subpages bg-green">
      <div className="container-fluid">
        <div className="event-subpages-links">
          <ul className="nav text-center">
            <li className="flex-fill">
              <Link
                to={`/admin/event/update/${id}`}
                className={moduleName === "details" ? "active" : ""}
              >
                Event Info
              </Link>
            </li>
            <li className="flex-fill">
              <Link
                to={`/admin/event/members/list/${id}`}
                className={moduleName === "members" ? "active" : ""}
              >
                Members
              </Link>
            </li>
            <li className="flex-fill">
              <Link
                to={`/admin/event/addmedia/${id}`}
                className={moduleName === "gallery" ? "active" : ""}
              >
                Gallery
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default EventDetailsCommonHeader;
