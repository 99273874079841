/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import languageOptions from "data/Prod/LanguageOptions.json";
import { reactSelectStyle } from "Config/Config";

const SaveSlideModal = ({
  selectedSlideId,
  setSelectedSlideId,
  afterModalClose = () => {},
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [title, setTitle] = useState("");
  const [titleSecondary, setTitleSecondary] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [linkText1, setLinkText1] = useState("");
  const [linkUrl1, setLinkUrl1] = useState("");
  const [linkText2, setLinkText2] = useState("");
  const [linkUrl2, setLinkUrl2] = useState("");
  const [languageValue, setlanguageValue] = useState(null);
  const [language, setLanguage] = useState("");
  const [code, setCode] = useState("");
  const [order, setOrder] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [bannerFileId, setBannerFileId] = useState(null);
  const [bannerPreview, setBannerPreview] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  //upload banner
  const bannerUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setIsUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setBannerFileId(response.data._id);
        setBannerPreview(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("bannerimage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select language
  const languageSelectionHandler = (val) => {
    if (val) {
      setlanguageValue(val);
      setLanguage(val.value);
    } else {
      setlanguageValue(null);
      setLanguage("");
    }
  };

  //get slide details
  const getSlideDetails = async () => {
    try {
      const requestURL =
        url.API_BASE_URL +
        url.API_GET_SLIDE_DETAILS +
        `/${selectedSlideId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTitle(response.data.title);
        setTitleSecondary(response.data.titlesecondary);
        setSubtitle(response.data.subtitle);
        setVideoUrl(response.data.videourl);
        setLinkText1(response.data.linktextone);
        setLinkUrl1(response.data.linkurlone);
        setLinkText2(response.data.linktexttwo);
        setLinkUrl2(response.data.linkurltwo);
        setCode(response.data.code);
        setOrder(response.data.order);
        setBannerFileId(response.data.bannerfileid);
        setBannerPreview(response.data.bannerpreview);

        for (let language of languageOptions) {
          if (language.value === response.data.language) {
            languageSelectionHandler(language);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for save slide
  const saveSlideHandler = async () => {
    try {
      setIsSaving(true);

      const slideData = {
        title,
        slug: title.toLowerCase().replace(/ /g, "-"),
        titlesecondary: titleSecondary,
        subtitle,
        videourl: videoUrl,
        linktextone: linkText1,
        linkurlone: linkUrl1,
        linktexttwo: linkText2,
        linkurltwo: linkUrl2,
        language,
        code,
        order,
        bannerfileid: bannerFileId,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (selectedSlideId) {
        requestURL +=
          url.API_UPDATE_SLIDE + `/${selectedSlideId}?token=${token}`;

        response = await putData(requestURL, slideData);
      } else {
        requestURL += url.API_CREATE_SLIDE + `?token=${token}`;

        response = await postData(requestURL, slideData);
      }

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        // close the modal
        let currentModal = document.querySelector("#modal_saveslide");
        let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
        bootstrapModal.hide();
        afterModalClose();
        resetHandler();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetHandler = () => {
    setTitle("");
    setTitleSecondary("");
    setSubtitle("");
    setVideoUrl("");
    setLinkText1("");
    setLinkUrl1("");
    setLinkText2("");
    setLinkUrl2("");
    setlanguageValue(null);
    setLanguage("");
    setCode("");
    setOrder("");
    setBannerFileId(null);
    setBannerPreview("");
  };

  useEffect(() => {
    if (selectedSlideId) {
      getSlideDetails();
    }
  }, [selectedSlideId]);

  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="modal_saveslide"
        aria-hidden="true"
        style={{ display: "none" }}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>
            <div className="modal-body edit_modalbody">
              <div className="srvay_feedhdng">
                <h2>Save Slides</h2>
              </div>

              <div className="edit_fillOuter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Title *</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Title"
                        id="slideTitle"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Title Secondary</label>
                      <input
                        type="text"
                        name="titlesec"
                        className="form-control"
                        placeholder="Title"
                        id="slideTitleSec"
                        value={titleSecondary}
                        onChange={(e) => {
                          setTitleSecondary(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Subtitle</label>
                      <input
                        type="text"
                        name="subtitle"
                        className="form-control"
                        placeholder="Sub-title"
                        id="slideSubtitle"
                        value={subtitle}
                        onChange={(e) => {
                          setSubtitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>VideoUrl</label>
                      <input
                        type="text"
                        name="video"
                        className="form-control"
                        placeholder="Url for Video Banner"
                        id="videourl"
                        value={videoUrl}
                        onChange={(e) => {
                          setVideoUrl(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Link Text 1</label>
                      <input
                        type="text"
                        name="linktext1"
                        className="form-control"
                        placeholder="Action button text"
                        id="linktext1"
                        value={linkText1}
                        onChange={(e) => {
                          setLinkText1(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Link URL 1</label>
                      <input
                        type="text"
                        name="link1"
                        className="form-control"
                        placeholder="Action button link"
                        id="link1"
                        value={linkUrl1}
                        onChange={(e) => {
                          setLinkUrl1(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Link Text 2</label>
                      <input
                        type="text"
                        name="linktext2"
                        className="form-control"
                        placeholder="Action button text"
                        id="linktext2"
                        value={linkText2}
                        onChange={(e) => {
                          setLinkText2(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Link URL 2</label>
                      <input
                        type="text"
                        name="link2"
                        className="form-control"
                        placeholder="Action button link"
                        id="link2"
                        value={linkUrl2}
                        onChange={(e) => {
                          setLinkUrl2(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Language</label>
                      <Select
                        isClearable
                        styles={reactSelectStyle("6px")}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder="Select Language"
                        options={languageOptions}
                        value={languageValue}
                        onChange={(val) => {
                          languageSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input_fill white_fill">
                      <label>Code</label>
                      <input
                        type="text"
                        name="code"
                        className="form-control"
                        placeholder="Enter code"
                        id="slideCode"
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input_fill white_fill">
                      <label>Order</label>
                      <input
                        type="number"
                        name="order"
                        className="form-control"
                        placeholder="Order"
                        id="slideOrder"
                        value={order}
                        onChange={(e) => {
                          setOrder(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Banner</label>

                      {bannerPreview === "" ? null : bannerPreview
                          .split(".")
                          .pop() === "jpg" ||
                        bannerPreview.split(".").pop() === "png" ||
                        bannerPreview.split(".").pop() === "jpeg" ||
                        bannerPreview.split(".").pop() === "gif" ? (
                        <figure className="preview">
                          <img
                            id="bannerpreview"
                            src={url.SERVER_URL + bannerPreview}
                            alt=""
                          />{" "}
                        </figure>
                      ) : bannerPreview.split(".").pop() === "mp4" ||
                        bannerPreview.split(".").pop() === "mov" ||
                        bannerPreview.split(".").pop() === "wmv" ? (
                        <video controls className="img-fluid mb-2" width={200}>
                          <source src={url.SERVER_URL + bannerPreview} />
                        </video>
                      ) : null}

                      <input
                        type="file"
                        id="bannerimage"
                        name="banner"
                        className="form-control"
                        accept="image/*,video/*"
                        onChange={(e) => {
                          bannerUploadHandler(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="submit_survay">
                      <button
                        type="button"
                        className="basic_btn ylw_btn"
                        onClick={saveSlideHandler}
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                      >
                        Save
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveSlideModal;
