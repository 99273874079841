/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData, postData } from "Utils/Gateway";

//import membership options from json
import membershipStatusOption from "data/Prod/MembershipStatus.json";

import { reactSelectStyle } from "Config/Config";

const SaveGroupMemberModal = ({
  selectedMemberId,
  setSelectedMemberId,
  afterCloseModalHandler = () => {},
}) => {
  const { t } = useTranslation(); //for translation
  const { id } = useParams();

  const token = localStorage.getItem("token");

  const [moduleRoleList, setModuleRoleList] = useState([]);

  const [userInput, setUserInput] = useState("");
  const [userListOptions, setuserListOptions] = useState([]);

  const [userValue, setUserValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [memberShipStatusValue, setMemberShipStatusValue] = useState(null);
  const [memberShipData, setmemberShipData] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    userWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (userValue === null) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      // Indicate that the validation has failed
      isValid = false;
    }

    // Return the validity flag
    return isValid;
  };

  //function for get all tags
  const getAllModuleRoles = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLE +
        `?token=${token}&slug=MOD_GROUP`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setModuleRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user input
  const userInputHandler = (val) => {
    setUserInput(val);
    seterrorMessage("");
  };

  //function for get user details
  const getUserList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USER_CONTACT_LIST_OPTIONS +
        `?token=${token}&userstring=${userInput}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setuserListOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    seterrorMessage("");
    setValidation((prevState) => ({ ...prevState, userWarning: false }));
    if (val) {
      setUserValue(val);
      setUserId(val._id);
      setUserEmail(val.email);
    } else {
      setUserValue(null);
      setUserId(null);
      setUserEmail("");
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    if (val) {
      setRoleValue(val);
      setRoleData(val.value);
      seterrorMessage("");
    } else {
      setRoleValue(null);
      setRoleData(null);
    }
  };

  //function for select memebership status
  const memberShipStatusSelectionHandler = (val) => {
    if (val) {
      setMemberShipStatusValue(val);
      setmemberShipData(val.value);
      seterrorMessage("");
    } else {
      setMemberShipStatusValue(null);
      setmemberShipData(null);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_MEMBER_DETAILS +
        `/${selectedMemberId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        userSelectionHandler(response.data.userdata);

        roleSelectionHandler(response.data.roledata);

        for (let memberStatus of membershipStatusOption) {
          if (memberStatus.value === response.data.moderatorstatus) {
            memberShipStatusSelectionHandler(memberStatus);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add event member
  const saveMemberHandler = async () => {
    if (validationHandler()) {
      try {
        setIsAdding(true);

        let apiData = {
          user: userId,
          group: id ?? null,
          role: roleData,
          useremail: userEmail,
          moderatorstatus: memberShipData,
        };

        console.log("memberdata---->", apiData);

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedMemberId) {
          requestURL +=
            url.API_UPDATE_GROUP_MEMBER + `/${selectedMemberId}?token=${token}`;

          response = await putData(requestURL, apiData);
        } else {
          requestURL += url.API_ADD_NEW_GROUP_MEMBER + `?token=${token}`;
          response = await postData(requestURL, apiData);
        }

        setIsAdding(false);

        console.log(response);

        if (response.status) {
          resetHandler();

          //hide member modal
          let bootstrapModal = document.querySelector("#saveGroupMemberModal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          //call member list api function
          afterCloseModalHandler();
        } else {
          seterrorMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for reset
  const resetHandler = () => {
    setUserValue(null);
    setuserListOptions([]);
    setMemberShipStatusValue(null);
    setmemberShipData(null);
    setRoleData(null);
    setRoleValue(null);
    setUserInput("");
    setUserId(null);
    setUserEmail("");
    seterrorMessage("");
    for (let memberStatus of membershipStatusOption) {
      if (memberStatus.value === "1") {
        memberShipStatusSelectionHandler(memberStatus);
      }
    }

    setSelectedMemberId(null);
  };

  useEffect(() => {
    if (selectedMemberId) {
      getMemberDetails();
    }
  }, [selectedMemberId]);

  useEffect(() => {
    if (userInput !== "") {
      getUserList();
    }
  }, [userInput]);

  useEffect(() => {
    getAllModuleRoles();
    for (let memberStatus of membershipStatusOption) {
      if (memberStatus.value === "1") {
        memberShipStatusSelectionHandler(memberStatus);
      }
    }
  }, []);

  return (
    <div
      className="modal fade"
      id="saveGroupMemberModal"
      tabIndex="-1"
      aria-labelledby="saveGroupMemberModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green w-100 mb-0" id="addMemberModalLabel">
              Member Details
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body px-4 py-5">
            <div className="form-row mb-4">
              <label htmlFor="memberName" className="fw-bold mb-2">
                {t("Contact")} <i>({t("search by name, email")})</i>
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{ IndicatorSeparator: () => null }}
                isClearable
                className="w-100"
                placeholder={t("Search by name, email")}
                options={userListOptions}
                value={userValue}
                onChange={(val) => {
                  userSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  userInputHandler(val);
                }}
              />

              {validation.userWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please select user!</span>
                  </p>
                </div>
              )}
            </div>

            <div className="form-row mb-4">
              <label htmlFor="memberRole" className="fw-bold mb-2">
                Add Role
              </label>

              <Select
                styles={reactSelectStyle("6px")}
                components={{ IndicatorSeparator: () => null }}
                isClearable
                className="w-100"
                placeholder="Select Role"
                options={moduleRoleList}
                value={roleValue}
                onChange={(val) => {
                  roleSelectionHandler(val);
                }}
              />
            </div>

            <div className="form-row mb-4">
              <label htmlFor="status" className="fw-bold mb-2">
                Membership Status
              </label>

              <Select
                styles={reactSelectStyle("6px")}
                components={{ IndicatorSeparator: () => null }}
                options={membershipStatusOption}
                value={memberShipStatusValue}
                onChange={(val) => {
                  memberShipStatusSelectionHandler(val);
                }}
              />
            </div>

            <div className="action-container d-flex align-items-center justify-content-end">
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-gray rounded-0 me-3"
                onClick={resetHandler}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-yellow rounded-0"
                onClick={saveMemberHandler}
                disabled={isAdding ? true : false}
                style={{
                  cursor: isAdding ? "not-allowed" : "pointer",
                }}
              >
                {t("Save")}
                {isAdding && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveGroupMemberModal;
