/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Creatable from "react-select/creatable";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { GlobalProvider } from "Context/GlobalContext";
import { reactSelectStyle } from "Config/Config";

const AddTagModal = ({
  moduleName = "",
  selectedIds = [],
  afterTagModalClose = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  const { settagListReload } = useContext(GlobalProvider);

  const actionArray = [
    {
      label: t("Add"),
      value: "1",
    },
    {
      label: t("Remove"),
      value: "2",
    },
  ];

  const [tagList, setTagList] = useState([]);

  const [tagValue, setTagValue] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [actionValue, setActionValue] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  //validation
  const validationHandler = () => {
    let isValid = true;

    if (selectedIds.length === 0) {
      setShowAlert(true);
      setAlertMessage(t("Please select items for tags"));
      setMessageType("error");
      isValid = false;
    }

    if (selectedTags.length === 0) {
      setShowAlert(true);
      setAlertMessage(t("Please select or create tag"));
      setMessageType("error");
      isValid = false;
    }

    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function tag selection
  const tagSelectionHandler = async (val) => {
    setTagValue(val);

    setSelectedTags(
      val.map((item) => {
        return {
          label: item.label,
          value: item.value,
          __isNew__: item.__isNew__ ?? false,
        };
      })
    );
  };

  //function for tag update
  const tagUpdateHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      let tagData = {
        selectedids: selectedIds,
        selectedtags: selectedTags,
      };

      console.log(tagData);

      try {
        let requestURL = url.API_BASE_URL;

        if (moduleName === "appuser") {
          requestURL += url.API_ADD_TAG_TO_USER;
        }

        if (moduleName === "contact") {
          requestURL += url.API_ADD_TAG_TO_CONTACT;
        }

        if (moduleName === "conversation") {
          requestURL += url.API_ADD_TAG_MAIL;
        }

        if (moduleName === "faq") {
          requestURL += url.API_ADD_REMOVE_TAG_FAQ;
        }

        requestURL += `?token=${token}&action=${actionValue.value}`;

        console.log(requestURL);

        const response = await putData(requestURL, tagData);

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          settagListReload(true);

          // close the modal
          let currentModal = document.querySelector("#addTagModal");
          let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
          bootstrapModal.hide();

          setShowAlert(true);
          setAlertMessage(response.message);
          setMessageType("success");
          afterTagModalClose();
        }
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    }

    setShowAlert(true);
  };

  useEffect(() => {
    getAllTags();
    setActionValue(actionArray[0]);
  }, []);

  return (
    <div
      className="modal fade"
      id="addTagModal"
      aria-labelledby="addTagModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green w-100 mb-0" id="addToTagModalLabel">
              Labels
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body px-4 py-5">
            <div className="form-row mb-3">
              <label htmlFor="selectTag" className="fw-bold mb-2">
                Select Tags
              </label>
              <Creatable
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isMulti
                placeholder={t("Enter/Select Tags")}
                options={tagList}
                value={tagValue}
                onChange={(val) => tagSelectionHandler(val)}
              />
            </div>

            <div className="form-row">
              <label htmlFor="action" className="fw-bold mb-2">
                Action
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isClearable
                placeholder={t("Select Action")}
                options={actionArray}
                value={actionValue}
                onChange={(val) => setActionValue(val)}
              />
            </div>
          </div>
          <div className="modal-footer border-0 p-4 pt-0">
            <button
              type="button"
              className="btn btn-yellow w-100 d-flex align-items-center justify-content-center rounded-0 m-0"
              onClick={tagUpdateHandler}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {isSaving ? (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i className="material-icons me-2">check_circle</i>
              )}
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagModal;
