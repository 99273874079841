/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData, deleteData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

const NoteSection = ({
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [noteDetails, setNoteDetails] = useState("");

  const [noteList, setNoteList] = useState([]);
  const [noteIdForUpdate, setNoteIdForUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // function for validation
  const validationHandler = () => {
    let isValid = true;

    if (noteDetails === "") {
      setAlertMessage(t("Please enter your note"));
      setMessageType("error");
      setShowAlert(true);
      isValid = false;
    }

    return isValid;
  };

  //function for get notes
  const getAllNotes = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_NOTE +
        `?token=${token}&filternotetype=1&contactid=${id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setNoteList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for update note
  const updateNoteStatus = (note) => {
    setNoteIdForUpdate(note._id);
    setNoteDetails(note.details);
  };

  //function for save note
  const saveNoteHandler = async () => {
    if (validationHandler()) {
      try {
        setIsLoading(true);

        let noteData = {
          notetype: "1",
          title: "",
          details: noteDetails,
          isprivate: false,
          contactid: id,
        };

        let requestURL =
          url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

        const response = await postData(requestURL, noteData);

        setIsLoading(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          resetHandler();
          getAllNotes();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    }

    setShowAlert(true);
  };

  //function for reset note form
  const resetHandler = () => {
    setNoteDetails("");
    setNoteIdForUpdate(null);
  };

  //function for update note
  const updateNoteHandler = async () => {
    try {
      setIsLoading(true);

      let noteData = {
        details: noteDetails,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_NOTE +
        `/${noteIdForUpdate}` +
        `?token=${token}`;

      const response = await putData(requestURL, noteData);

      if (response.status) {
        setMessageType("success");
        resetHandler();
        getAllNotes();
      } else {
        setMessageType("error");
      }

      setIsLoading(false);
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for delete note
  const deleteNoteHandler = async (noteId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_DELETE_NOTE +
        `/${noteId}` +
        `?token=${token}`;

      const response = await deleteData(requestURL);

      if (response.status) {
        setMessageType("success");
        getAllNotes();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (id) {
      getAllNotes();
    }
  }, [id]);

  return (
    <div className="notes-container p-4 bg-gray-100 h-100">
      <h5 className="mb-4 fw-bold">Notes</h5>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="position-relative bg-white border mb-2"
      >
        <textarea
          id=""
          cols="30"
          rows="5"
          placeholder={t("Add your notes")}
          className="form-control border-0 bg-transparent shadow-none"
          value={noteDetails}
          onChange={(e) => {
            setNoteDetails(e.target.value);
          }}
        ></textarea>

        <div className="position-absolute bottom-0 end-0 d-flex align-items-center gap-1">
          {noteIdForUpdate ? (
            <button
              type="button"
              className="resetnote bg-transparent text-danger translate-middle border-0 p-0 mr-30"
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
              disabled={isLoading ? true : false}
              onClick={resetHandler}
            >
              <i class="material-icons d-flex">cancel</i>
            </button>
          ) : null}
          <button
            type="button"
            className="bg-transparent text-primary  translate-middle border-0 p-0"
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            disabled={isLoading ? true : false}
            onClick={noteIdForUpdate ? updateNoteHandler : saveNoteHandler}
          >
            {isLoading ? (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <i className="material-icons d-flex">send</i>
            )}
          </button>
        </div>
      </form>

      {/* <ul className="notes mt-2">
        <li className="position-relative p-3 mb-3 bg-white">
          <h6 className="mb-2">Extra skills preferable</h6>
          <p className="text-gray">Each stage important</p>
          <div className="dropdown position-absolute">
            <Link
              to="#"
              className="dropdown-toggle text-gray"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end border-0 mt-3 shadow-3"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <Link className="dropdown-item" to="#">
                  <i className="material-icons">edit</i> Edit
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <i className="material-icons">delete</i> Delete
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul> */}

      {noteList.length === 0 ? (
        <div className="no-notes-found">
          <img
            src="/images/note-placeholder.png"
            alt="No Notes Found"
            className="d-block mx-auto img-fluid"
          />
        </div>
      ) : (
        <ul className="notes mt-2">
          {noteList.map((note, index) => {
            return (
              <li className="position-relative p-3 mb-3 bg-white" key={index}>
                <h6 className="mb-2">{note.notedate}</h6>
                <p className="text-gray">{note.details}</p>
                <div className="dropdown position-absolute">
                  <Link
                    to="#"
                    className="dropdown-toggle text-gray"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="material-icons">more_vert</i>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end border-0 mt-3 shadow-3"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          updateNoteStatus(note);
                        }}
                      >
                        <i className="material-icons">edit</i>Edit
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          deleteNoteHandler(note._id);
                        }}
                      >
                        <i className="material-icons">delete</i> Delete
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NoteSection;
