/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import MailTemplateListHeader from "./Header/MailTemplateListHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const MailTemplateBody = () => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Mail Template") }];

  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const getAllMailTemplates = async () => {
    setRowSelection({});
    setSelectedTemplateIds([]);
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setTemplateList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for massive delete
  const deleteTemplates = async () => {
    if (selectedTemplateIds.length > 0) {
      try {
        const templateListToBeUpdate = templateList.filter((item) =>
          selectedTemplateIds.includes(item._id)
        );

        let categoryData = {
          templatelist: templateListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_TEMPLATE +
          `?token=${token}`;

        const response = await putData(requestURL, categoryData);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllMailTemplates();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one template");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllMailTemplates();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "name",
      header: t("Name"),
      size: 200,
    },
    {
      accessorKey: "slug",
      header: t("Slug"),
    },

    {
      accessorKey: "subject",
      header: t("Subject"),
      size: 300,
    },
    {
      accessorKey: "isglobal",
      header: t("Is Global"),
    },
    {
      accessorKey: "templatedate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTemplateIds(selectedIdsArray);
    } else {
      setSelectedTemplateIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />

            <MailTemplateListHeader deleteTemplates={deleteTemplates} />
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={templateList} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                }}
                enableRowActions // show the action column
                enableColumnOrdering={true}
                renderRowActionMenuItems={({ row }) => {
                  const menuItems = [
                    <MenuItem key="edit">
                      <Link
                        className="dropdown-item"
                        to={`/admin/communicationtemplate/update/${row.id}`}
                      >
                        {t("Edit Template")}
                      </Link>
                    </MenuItem>,
                  ];

                  return menuItems;
                }} // action columns menu items
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by name / slug / date"),
                }}
              />
            </div>
          </div>
        </div>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </>
  );
};

export default MailTemplateBody;
