import React from "react";
import { Link } from "react-router-dom";

const SaveModuleHeader = () => {
  return (
    <section className="event-subpages bg-green">
      <div className="container-fluid">
        <div className="event-subpages-links">
          <ul className="nav text-center">
            <li>
              <Link
                to="/admin/module/manage"
                className="d-flex align-items-center justify-content-center"
              >
                {" "}
                Back to Modules{" "}
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center active"
              >
                {" "}
                Save Module{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SaveModuleHeader;
