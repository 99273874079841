/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData, uploadSingleFile } from "Utils/Gateway";

import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const AppUserExperienceBody = () => {
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App user"), link: "/admin/contact/allusers" },
    { title: t("Experience") },
  ];

  const params = useParams();
  const token = localStorage.getItem("token");

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const experienceTemplate = {
    company: "",
    position: "",
    peoplecount: "",
    ctc: "",
    responsibilities: "",
    start: "",
    end: "",
    stillworking: false,
    linkedinurl: "",
    companywebsite: "",
    location: "",
  };

  const [experienceBlock, setExperienceBlock] = useState([]);
  const [deletedExperienceBlock, setDeletedExperienceBlock] = useState([]);

  //** Function for addign action link section */
  const addExperienceBlock = () => {
    setExperienceBlock([...experienceBlock, experienceTemplate]);
  };

  //** Function for delete action link block */
  const onDeleteExperienceBlock = (index, _id) => {
    setDeletedExperienceBlock([...deletedExperienceBlock, _id]);
    const filteredExperienceBlock = [...experienceBlock];
    filteredExperienceBlock.splice(index, 1);
    setExperienceBlock(filteredExperienceBlock);
  };

  const changeExperienceInputs = (index, field, value) => {
    setExperienceBlock((prevBlocks) =>
      prevBlocks.map((block, i) =>
        i === index ? { ...block, [field]: value } : block
      )
    );
  };

  //** Function for mapping form repeater data */
  const updateExperienceBlock = (experience) => {
    const experienceArr = experience.map((experienceData) => {
      return {
        _id: experienceData?._id,
        company: experienceData?.company,
        position: experienceData?.position,
        peoplecount: experienceData?.peoplecount,
        ctc: experienceData?.ctc,
        responsibilities: experienceData?.responsibilities,
        start: experienceData?.start,
        end: experienceData?.end,
        stillworking: experienceData?.stillworking ?? false,
        linkedinurl: experienceData?.linkedinurl,
        companywebsite: experienceData?.companywebsite,
        location: experienceData?.location,
      };
    });
    setExperienceBlock(experienceArr);
  };

  //function for get user details
  const getUserDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        const data = response.data;

        if (data) {
          if (data.userexperiences && data.userexperiences.length > 0) {
            updateExperienceBlock(data.userexperiences);
          }
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    try {
      setIsSaving(true);
      let userData = {
        experiences: experienceBlock,
        deletedexperiences: deletedExperienceBlock,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestURL =
          requestURL +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}`;

        response = await putData(requestURL, userData);
      }

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");

        setTimeout(() => {
          getUserDetails();
        }, 1200);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
    }
  }, [params.id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <AppUserDetailsLayout
      moduleName="experience"
      breadcrumbText={breadcrumbText}
    >
      <div className="profile_graybx_Outer">
        {experienceBlock.map((block, index) => {
          return (
            <div className="profile_graybx_item" key={index}>
              <div className="profile_fillOuter wd_260">
                {/* <div className="profile_fill_row">
                  <label>Company</label>
                  <div className="input_fill mt-1">
                    <Select />
                  </div>
                </div> */}

                <div className="profile_fill_row">
                  <label>Company</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      value={block.company}
                      onChange={(e) =>
                        changeExperienceInputs(index, "company", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>Position</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Position"
                      value={block.position}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "position",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>People in your charge</label>
                  <div className="input_fill">
                    <input
                      type="number"
                      className="form-control"
                      value={block.peoplecount}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "peoplecount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>Billing at your expense</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      value={block.ctc}
                      onChange={(e) =>
                        changeExperienceInputs(index, "ctc", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>About</label>
                  <div className="input_fill description_fill">
                    <textarea
                      className="form-control"
                      placeholder=""
                      value={block.responsibilities}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "responsibilities",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>From Date</label>
                  <div className="input_fill">
                    <input
                      type="date"
                      className="form-control"
                      value={block.start}
                      onChange={(e) =>
                        changeExperienceInputs(index, "start", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>To Date</label>
                  <div className="input_fill">
                    <input
                      type="date"
                      className="form-control"
                      value={block.end}
                      onChange={(e) =>
                        changeExperienceInputs(index, "end", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>Still Working?</label>
                  <div className="profile_chkList">
                    <ul className="profile_chkList working_list">
                      <li className="">
                        <label className="radio-check">
                          <input
                            type="checkbox"
                            checked={block.stillworking}
                            onChange={(e) =>
                              changeExperienceInputs(
                                index,
                                "stillworking",
                                e.target.checked
                              )
                            }
                          />
                          <span></span>
                          {/* <p>Still Working?</p> */}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>Location</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      value={block.location}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "location",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>LinkedIn URL</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      value={block.linkedinurl}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "linkedinurl",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="profile_fill_row">
                  <label>Company Website</label>
                  <div className="input_fill">
                    <input
                      type="text"
                      className="form-control"
                      value={block.companywebsite}
                      onChange={(e) =>
                        changeExperienceInputs(
                          index,
                          "companywebsite",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="profile_delete_btn">
                <Link
                  to="#"
                  onClick={() => onDeleteExperienceBlock(index, block._id)}
                >
                  <span className="material-icons-outlined">delete</span>
                </Link>
              </div>
            </div>
          );
        })}

        <div className="profile_graybx_item">
          <div className="profile_leftBtn">
            <Link
              to="#"
              className="basic_btn grn_btn"
              onClick={addExperienceBlock}
            >
              <i className="material-icons-outlined">add</i>
              <span>Add New</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="prfile_btnOuter justify-content-end">
        <div className="profile_rightBtn">
          <button className="basic_btn cancel_btn" onClick={getUserDetails}>
            {t("Cancel")}
          </button>
          <button
            className="basic_btn ylw_btn"
            onClick={saveUserHandler}
            disabled={isSaving ? true : false}
            style={{
              cursor: isSaving ? "not-allowed" : "pointer",
            }}
          >
            {t("Save")}
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </AppUserDetailsLayout>
  );
};

export default AppUserExperienceBody;
