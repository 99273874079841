/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const SaveTaskPopup = ({
  selectedTaskId = null,
  setSelectedTaskId = () => {},
  afterPopupClose = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
  lessonTitle = "",
}) => {
  const { id: courseId, lid: lessonId } = useParams();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [duration, setduration] = useState("");
  const [score, setScore] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    hoursWarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (duration === "") {
      setValidation((prevState) => ({
        ...prevState,
        hoursWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  const editor = useRef(null);

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 350,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      console.log("task details", response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
        setduration(response.data.hours ?? 0);
        setScore(response.data.score ?? 0);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //save task
  const saveTaskHandler = async () => {
    if (validationHandler()) {
      const todayValue = new Date().toISOString().split("T")[0];

      try {
        setIsSaving(true);

        let taskData = {
          lessonid: lessonId,
          course: courseId,
          title,
          description: details,
          startdate: todayValue,
          enddate: todayValue,
          hours: duration,
          score,
          priority: "2",
          completedpercent: "0",
          taskpercent: 0,
          assigneduser: null,
          followers: [],
          rank: 1,
          uploads: [],
        };

        // console.log(taskData);

        let response = {};

        if (selectedTaskId) {
          let requestURL =
            url.API_BASE_URL +
            url.API_UPDATE_COURSE_LESSON_TASK +
            `/${selectedTaskId}?token=${token}`;

          response = await putData(requestURL, taskData);
        } else {
          let requestURL =
            url.API_BASE_URL +
            url.API_ADD_NEW_COURSE_LESSON_TASK +
            `?token=${token}`;

          response = await postData(requestURL, taskData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          resetHandler();

          popupToggle();
          afterPopupClose();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up all required fields"));
    }
    setShowAlert(true);
  };

  //reset all states
  const resetHandler = () => {
    settitle("");
    setdetails("");
    setduration("");
    setScore("");
    setSelectedTaskId(null);
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
    }
  }, [selectedTaskId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link
                to="#"
                onClick={() => {
                  resetHandler();
                  popupToggle();
                }}
              >
                <i className="material-icons-round">close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>{t("Save Task to Lesson")}</h3>
              <Link to="#">{lessonTitle}</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="course_form_details_innr ps-3 pe-2">
        <div className="course_detailsbx_item">
          <div className="profile_fillOuter">
            {/* <!-- ========== Start title Section ========== --> */}
            <div className="profile_fill_row course_fill_row">
              <label>{t("Title")}</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="selected_lessontitle"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
            {/* <!-- ========== End title Section ========== --> */}

            <div className="profile_fill_row course_fill_row">
              <label>{t("Task Details")}</label>
              <div className="input_fill description_fill">
                <JoditEditor
                  ref={editor}
                  value={details}
                  config={detailsEditorConfig}
                  onBlur={(newContent) => {
                    // console.log("New content in on blur----->", newContent);
                    setdetails(newContent);
                  }}
                  onChange={(newContent, event) => {
                    // setMailDescription(newContent);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Score")}</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  className="form-control"
                  value={score}
                  onChange={(e) => {
                    setScore(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Duration")}</label>
              <div className="input_fill mb-0">
                <input
                  type="number"
                  className="form-control"
                  value={duration}
                  onChange={(e) => {
                    setduration(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label></label>
              <div className="input_fill mb-0">
                <div className="profile_rightBtn">
                  <button
                    className="basic_btn ylw_btn"
                    onClick={saveTaskHandler}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Submit")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTaskPopup;
