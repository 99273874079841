/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

const ContactTaskModal = ({
  taskId = null,
  setTaskId = () => {},
  reloadTask = () => {},
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const userObj = {
    label: `${userInfo.name ?? userInfo.email} ${userInfo.surname ?? ""}`,
    value: userInfo._id,
  };

  const assignedToOptions = [userObj];

  // Individual states for each input
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [project, setProject] = useState(null);
  const [projectValue, setprojectValue] = useState(null);
  const [assignedToValue, setassignedToValue] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteing, setIsDeleteing] = useState(false);

  const [validation, setvalidation] = useState({
    titleWarning: false,
    detailsWarning: false,
  });

  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setvalidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (details === "") {
      setvalidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for select project
  const projectSelectionHandler = (val) => {
    if (val) {
      setprojectValue(val);
      setProject(val.value);
    } else {
      setprojectValue(null);
      setProject(null);
    }
  };

  //function for select assigned to
  const assignedToSelectionHandler = (val) => {
    if (val) {
      setassignedToValue(val);
      setAssignedTo(val.value);
    } else {
      setassignedToValue(null);
      setAssignedTo(null);
    }
  };

  //function for get task details
  const getTaskDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${taskId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setTitle(response.data.title);
        setDetails(response.data.description);
        assignedToSelectionHandler(response.data.assigneduserdata);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for save task
  const saveTaskHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      const taskData = {
        title: title,
        description: details,
        project: project,
        assigneduser: assignedTo,
        contact: id,
      };

      try {
        let requestURL = url.API_BASE_URL;
        let response = {};

        if (taskId) {
          requestURL =
            url.API_BASE_URL +
            url.API_UPDATE_TASK +
            `/${taskId}?token=${token}`;

          response = await putData(requestURL, taskData);
        } else {
          requestURL += url.API_ADD_TASK + `?token=${token}`;

          response = await postData(requestURL, taskData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          resetHandler();
          reloadTask();
          // close the modal of addLabel
          let bootstrapModal = document.querySelector("#saveTaskModal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for delete task
  const deleteTaskHandler = async () => {
    setIsDeleteing(true);

    const taskData = {
      status: "0",
    };

    try {
      let requestURL = url.API_BASE_URL;
      let response = {};

      if (taskId) {
        requestURL =
          url.API_BASE_URL + url.API_UPDATE_TASK + `/${taskId}?token=${token}`;

        response = await putData(requestURL, taskData);
      }

      console.log(response);

      setIsDeleteing(false);

      if (response.status) {
        resetHandler();
        reloadTask();
        // close the modal of addLabel
        let bootstrapModal = document.querySelector("#saveTaskModal");
        let modal = bootstrap.Modal.getInstance(bootstrapModal);
        modal.hide();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setTaskId(null);
    setprojectValue(null);
    setProject(null);
    setTitle("");
    setDetails("");
    setvalidation({
      titleWarning: false,
      detailsWarning: false,
    });
    assignedToSelectionHandler(userObj);
  };

  useEffect(() => {
    if (taskId) {
      getTaskDetails();
    }
  }, [taskId]);

  useEffect(() => {
    assignedToSelectionHandler(userObj);
  }, []);

  return (
    <div
      className="modal fade"
      id="saveTaskModal"
      aria-labelledby="saveTaskModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green mb-0" id="saveTaskModalLabel">
              Task
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="form-row mb-3">
              <label htmlFor="title" className="text-primary fw-bold mb-2">
                Title
              </label>
              <input
                type="text"
                placeholder="Task Title"
                className="form-control text-green rounded-0"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setvalidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                }}
              />

              {validation.titleWarning && (
                <p className="text-danger mt-2">* {t("Title is required")}</p>
              )}
            </div>

            <div className="form-row mb-3">
              <label
                htmlFor="taskDetails"
                className="text-primary fw-bold mb-2"
              >
                Details
              </label>
              <textarea
                cols="30"
                rows="5"
                className="form-control text-green rounded-0"
                value={details}
                onChange={(e) => {
                  setDetails(e.target.value);
                  setvalidation((prevState) => ({
                    ...prevState,
                    detailsWarning: false,
                  }));
                }}
              ></textarea>

              {validation.detailsWarning && (
                <p className="text-danger mt-2">* {t("Details is required")}</p>
              )}
            </div>

            <div className="row gx-3 mb-3">
              <div className="col-sm-6">
                <div className="form-row">
                  <label
                    htmlFor="project"
                    className="text-primary fw-bold mb-2"
                  >
                    Project
                  </label>
                  <Select isClearable placeholder={t("Select Project")} />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-row">
                  <label
                    htmlFor="assignTo"
                    className="text-primary fw-bold mb-2"
                  >
                    Assigned to
                  </label>
                  <Select
                    isClearable
                    placeholder={t("Select User")}
                    options={assignedToOptions}
                    value={assignedToValue}
                    onChange={(val) => {
                      assignedToSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 p-4 pt-0">
            <button
              type="button"
              className="btn btn-yellow rounded-0 m-0 ms-auto"
              onClick={saveTaskHandler}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>

            {taskId ? (
              <button
                className="btn btn-transparent allowdelete"
                onClick={deleteTaskHandler}
                disabled={isDeleteing ? true : false}
                style={{
                  cursor: isDeleteing ? "not-allowed" : "pointer",
                }}
              >
                {isDeleteing ? (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <i className="material-icons">delete </i>
                )}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTaskModal;
