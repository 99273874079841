import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserImprovementsBody = () => {
  return (
    <AppUserDetailsLayout moduleName="improvements">
      <div class="profile_graybx_Outer">
        <div class="profile_graybx_item">
          <div class="profile_fillOuter wd_260">
            <div class="profile_fill_row">
              <label>Improvement arear</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Search for interest">
                    <option>Please select</option>
                    <option>Improvement 1</option>
                    <option>Improvement 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Description</label>
              <div class="input_fill description_fill">
                <textarea
                  class="form-control"
                  placeholder="Enter Type here.."
                ></textarea>
              </div>
            </div>
          </div>
          <div class="profile_delete_btn">
            <a href="javascipt:void(0);">
              <span class="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
      <div class="prfile_btnOuter">
        <div class="profile_leftBtn">
          <a href="javascript:void(0);" class="basic_btn grn_btn">
            <i class="material-icons-outlined">add</i>
            <span>Add New</span>
          </a>
        </div>
        <div class="profile_rightBtn">
          <button class="basic_btn cancel_btn">Cancel</button>
          <button class="basic_btn ylw_btn">Submit</button>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default UserImprovementsBody;
