/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ModuleActivityPointHeader from "./Header/ModuleActivityPointHeader";
import SaveModuleActivityPointModal from "./Modal/SaveModuleActivityPointModal";

const ModuleActivityPointBody = () => {
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Activity Points") },
    { title: t("Manage") },
  ];

  const [rowSelection, setRowSelection] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const moduleData = [];

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#save_module_points"
          aria-controls="save_module_points"
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: t("Name/slug"),
      size: 200,
      Cell: ({ row }) => (
        <div>
          <p>{row.original.name}</p>
          <p>{row.original.slug}</p>
        </div>
      ),
    },
    {
      accessorKey: "parent",
      header: t("Parent"),
    },
    {
      accessorKey: "featured",
      header: t("Featured"),
      Cell: ({ row }) => (
        <div>
          <span class="material-icons-outlined">check_circle_outline</span>
        </div>
      ),
    },
    {
      accessorKey: "privilege",
      header: t("Privilege"),
      Cell: ({ row }) => (
        <div>
          <span class="material-icons-outlined">check_circle_outline</span>
        </div>
      ),
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="text"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.rank}
          readOnly
        />
      ),
    },
    {
      accessorKey: "inecosystem",
      header: t("In ecosystem"),
      Cell: ({ row }) => (
        <div>
          <span class="material-icons-outlined">check_circle_outline</span>
        </div>
      ),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedUserIds(selectedIdsArray);
    } else {
      setSelectedUserIds([]);
    }
  }, [rowSelection]);

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />
            <ModuleActivityPointHeader />

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={moduleData} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row.id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                }}
                // enableColumnOrdering={true}
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Title"),
                }}
              />
            </div>
          </div>
        </div>

        <SaveModuleActivityPointModal />
      </section>
    </>
  );
};

export default ModuleActivityPointBody;
