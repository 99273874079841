/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

const SaveChapterPopup = ({
  courseTitle = "",
  selectedChapterId = null,
  setSelectedChapterId = () => {},
  afterPopupClose = () => {},
}) => {
  const { id: courseId } = useParams();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const chapterPopupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [isSaving, setisSaving] = useState(false);

  const getChapterDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_CHAPTER_DETAILS +
        `/${selectedChapterId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const saveChapterHandler = async () => {
    try {
      setisSaving(true);

      let chapterData = {
        course: courseId,
        title,
        description: details,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (selectedChapterId) {
        requestURL +=
          url.API_UPDATE_COURSE_CHAPTER +
          `/${selectedChapterId}?token=${token}`;

        response = await putData(requestURL, chapterData);
      } else {
        requestURL += url.API_ADD_NEW_COURSE_CHAPTER + `?token=${token}`;

        response = await postData(requestURL, chapterData);
      }

      console.log(response);

      setisSaving(false);

      if (response.status) {
        resetHandler();
        afterPopupClose();
        chapterPopupToggle();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    settitle("");
    setdetails("");
    setSelectedChapterId(null);
  };

  useEffect(() => {
    if (selectedChapterId) {
      getChapterDetails();
    }
  }, [selectedChapterId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link
                to="#"
                onClick={() => {
                  chapterPopupToggle();
                  resetHandler();
                }}
              >
                <i className="material-icons-round">close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>{t("Save chapters to course")}</h3>
              {courseTitle === "" ? null : <Link to="#">{courseTitle}</Link>}
            </div>
          </div>
        </div>
      </div>

      <div className="course_form_details_innr ps-3 pe-2">
        <div className="course_detailsbx_item">
          <div className="profile_fillOuter">
            <div className="profile_fill_row course_fill_row">
              <label>{t("Title")} *</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="selected_title"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Description")}</label>
              <div className="input_fill description_fill mb-0">
                <textarea
                  className="form-control"
                  name="description"
                  id="selected_content"
                  value={details}
                  onChange={(e) => setdetails(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label></label>
              <div className="input_fill mt-0">
                <div className="profile_rightBtn mt-0">
                  <button
                    className="basic_btn ylw_btn"
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                    onClick={saveChapterHandler}
                  >
                    {t("Save")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveChapterPopup;
