/* eslint-disable */
import { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";
import languageOptions from "data/Prod/LanguageOptions.json";
import publishOptions from "data/Prod/PublishStatusOptions.json";

const CourseListFilterModal = ({
  setfilterRank,
  setFilterStartDate,
  setFilterEndDate,
  setFilterTitle,
  setFilterLanguage,
  setFilterSkills,
  setFilterCategories,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [categoryList, setcategoryList] = useState([]);
  const [skillList, setskillList] = useState([]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [title, settitle] = useState("");
  const [publishValue, setpublishValue] = useState(null);
  const [selectedPublished, setselectedPublished] = useState("");
  const [languageValue, setlanguageValue] = useState(null);
  const [selectedLanguage, setselectedLanguage] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);
  const [skillValue, setskillValue] = useState(null);
  const [selectedSkills, setselectedSkills] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (startDate === "" && endDate !== "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (endDate !== "" && endDate < startDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "COURSE";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all skills
  const getAllSkillList = async () => {
    try {
      const parentSlug = "SKILL";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setskillList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select publish
  const publishSelectionHandler = (val) => {
    if (val) {
      setpublishValue(val);
      setselectedPublished(val.value);
    } else {
      setpublishValue(null);
      setselectedPublished("");
    }
  };

  //function for select language
  const languaSelectionHandler = (val) => {
    if (val) {
      setlanguageValue(val);
      setselectedLanguage(val.value);
    } else {
      setlanguageValue(null);
      setselectedLanguage("");
    }
  };

  //function for select category
  const categorySelectionHandler = (val) => {
    if (val) {
      setcategoryValue(val);
      setselectedCategories(val.map((item) => item.value));
    } else {
      setcategoryValue(null);
      setselectedCategories([]);
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    if (val) {
      setskillValue(val);
      setselectedSkills(val.map((item) => item.value));
    } else {
      setskillValue(null);
      setselectedSkills([]);
    }
  };

  const applyFilterHandler = () => {
    if (validationHandler()) {
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
      setFilterTitle(title);
      setFilterLanguage(selectedLanguage);
      setFilterSkills(selectedSkills);
      setFilterCategories(selectedCategories);

      setfilterRank(selectedPublished);

      resetHandler();

      const filterModal = document.querySelector("#courselistfilterModal");

      const modal = bootstrap.Modal.getInstance(filterModal);
      modal.hide();
    }
  };

  const resetHandler = () => {
    setstartDate("");
    setendDate("");
    settitle("");
    setpublishValue(null);
    setselectedPublished("");
    setlanguageValue(null);
    setselectedLanguage("");
    setcategoryValue(null);
    setselectedCategories([]);
    setskillValue(null);
    setselectedSkills([]);

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  useEffect(() => {
    getAllCategoryList();
    getAllSkillList();
  }, []);

  return (
    <div
      className="modal fade"
      id="courselistfilterModal"
      aria-labelledby="filterModalLabel"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green w-100 mb-2" id="filterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4 py-1 mt-2">
            <div className="form-row mb-3">
              <label htmlFor="language" className="fw-bold mb-2">
                {t("Status")}
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Status")}
                options={publishOptions}
                value={publishValue}
                onChange={(val) => {
                  publishSelectionHandler(val);
                }}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="name" className="fw-bold mb-2">
                {t("Query / Title")}
              </label>
              <input
                type="text"
                id="fname"
                name="query"
                placeholder={t("Enter Query / Title")}
                className="form-control text-green rounded-0"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="language" className="fw-bold mb-2">
                {t("Language")}
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Language")}
                options={languageOptions}
                value={languageValue}
                onChange={(val) => {
                  languaSelectionHandler(val);
                }}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="category" className="fw-bold mb-2">
                {t("Category")}
              </label>
              <Select
                isMulti
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Category")}
                options={categoryList}
                value={categoryValue}
                onChange={(val) => {
                  categorySelectionHandler(val);
                }}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="searching" className="fw-bold mb-2">
                {t("Skills")}
              </label>
              <Select
                isMulti
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Skills")}
                options={skillList}
                value={skillValue}
                onChange={(val) => {
                  skillSelectionHandler(val);
                }}
              />
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="startDate" className="fw-bold mb-2">
                  {t("From Date")}
                </label>
                <input
                  type="date"
                  name="fromdate"
                  id="startDate"
                  className="form-control text-green rounded-0"
                  value={startDate}
                  onChange={(e) => {
                    setstartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>

              <div className="col-6">
                <label htmlFor="endDate" className="fw-bold mb-2">
                  {t("To Date")}
                </label>
                <input
                  type="date"
                  name="todate"
                  id="endDate"
                  className="form-control text-green rounded-0"
                  value={endDate}
                  onChange={(e) => {
                    setendDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>
            </div>

            {validation.endDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
            {validation.startDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>Please enter start date!</span>
                </p>
              </div>
            )}
            {validation.endDateSmallerWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>End date should not be greater than start date!</span>
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={applyFilterHandler}
            >
              {t("Filter")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseListFilterModal;
