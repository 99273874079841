/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

const ContactTimeLineBody = ({ timeLinesData = [] }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  /* function to search */
  const filterSearchTimeline = () => {
    const lowerSearchText = searchText.toLowerCase();

    return timeLinesData.filter((item) => {
      // Combine searchable fields in an array and check matches
      const searchableFields = [
        item.type,
        item.year,
        item.person,
        item.timetext,
        item.description,
        item.date,
        item.month,
      ];

      return searchableFields.some((field) =>
        field?.toLowerCase().includes(lowerSearchText)
      );
    });
  };

  return (
    <div className="col-lg-6 mb-4 mb-lg-0">
      <div className="notifications-container">
        <input
          type="text"
          className="search form-control"
          placeholder="search by timeline"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <ul className="notifications">
          {timeLinesData.map((timeLineInfo, index) => {
            return (
              <li
                className={
                  filterSearchTimeline().includes(timeLineInfo)
                    ? "notification px-4 py-3 bg-gray-100 mb-2"
                    : "d-none"
                }
                key={index}
              >
                <span className="badge bg-info rounded-0 fw-normal">
                  {timeLineInfo.type.toUpperCase()}
                </span>
                <div className="d-flex my-2">
                  <Link to="#" className="text-gray-600 me-2">
                    {timeLineInfo.person}
                  </Link>
                  <span className="date text-gray-600">
                    {timeLineInfo.timetext} {timeLineInfo.year}
                  </span>
                </div>
                <h5 className="fw-bold">{timeLineInfo.description}</h5>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ContactTimeLineBody;
