/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ModuleActivityHeader from "./Header/ModuleActivityHeader";
import SaveModuleActivityModal from "./Modal/SaveModuleActivityModal";

const ModuleActivityBody = () => {
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Module Activity") },
    { title: t("Manage") },
  ];

  const activityData = [
    {
      _id: "56511136464",
      modulename: "Forum",
      activityname: "Forum Champion",
      slug: "FORUM_CHAMPION",
    },
    {
      _id: "56511136464",
      modulename: "Forum",
      activityname: "Comments on forum",
      slug: "COMMENTS_ON_FORUM",
    },
  ];

  const [rowSelection, setRowSelection] = useState({});
  const [selectedSlideIds, setSelectedSildeIds] = useState([]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#modal_savequestion"
          aria-controls="modal_savequestion"
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "modulename",
      header: t("Module"),
      size: 400,
    },
    {
      accessorKey: "activityname",
      header: t("Activity"),
      size: 400,
    },
    {
      accessorKey: "slug",
      header: t("Slug"),
      size: 400,
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedSildeIds(selectedIdsArray);
    } else {
      setSelectedSildeIds([]);
    }
  }, [rowSelection]);

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />

            <ModuleActivityHeader />

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={activityData} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row.id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                  columnOrder: columnOrder,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                  columnOrder: columnOrder,
                }}
                enableColumnOrdering={true}
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Title"),
                }}
              />
            </div>
          </div>
        </div>

        <SaveModuleActivityModal />
      </section>
    </>
  );
};

export default ModuleActivityBody;
