import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const AppUserSurveyBody = () => {
  return (
    <AppUserDetailsLayout moduleName="surveys">
      <div class="table_area">
        <div class="tablesrch_top">
          <div class="table_srch_wrap">
            <button type="submit" class="tble_srchbtn">
              <span class="material-icons-outlined">search</span>
            </button>
            <input
              type="text"
              value=""
              class="form-control"
              placeholder="Search by survey name"
            />
          </div>
          <div class="table_action">
            <ul>
              <li class="dropdown">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  class="basic_btn ylw_btn "
                >
                  Actions
                  <span class="material-icons-outlined">arrow_drop_down</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="javascript:void(0);">Add New </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Delete Selected</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Update Rank</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_innr_scroll">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="head_chk_outer">
                    <div class="profile_chkList table_chk">
                      <label class="radio-check">
                        <input type="checkbox" />
                        <span></span>
                      </label>
                    </div>
                    <div class="chk_drop dropdown">
                      <a href="javascript:void(0);" data-bs-toggle="dropdown">
                        {" "}
                        <span class="material-icons-outlined">
                          arrow_drop_down
                        </span>
                      </a>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Date</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Survey</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Module</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Component</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Question</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Rating</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
                <th>
                  <div class="tble_head">
                    <span>Action</span>
                    <a href="javascript:void(0);">
                      <i class="material-icons-outlined">unfold_more</i>
                    </a>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">Pending</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          class="ylw_assist"
                          data-bs-toggle="modal"
                          data-bs-target="#survey_detailsmdl"
                        >
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="ylw_txt">Pending</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  5 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  3 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  4 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  2 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  3 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="profile_chkList table_chk">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="date_text">
                    <h5>02/08/2022</h5>
                    <h6 class="grn_text">Provided</h6>
                  </div>
                </td>
                <td>Course Feedback</td>
                <td>Course</td>
                <td>Ui/Ux Designer Beginners</td>
                <td>12</td>
                <td>
                  2 <span>Stars</span>
                </td>
                <td>
                  <div class="action_btn">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" class="grn_chk">
                          <i class="material-icons-outlined">check_circle</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="red_cancel">
                          <i class="material-icons-outlined">cancel</i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="ylw_assist">
                          <i class="material-icons-outlined">assistant</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_list">
          <ul>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_back_ios</i>
              </a>
            </li>
            <li class="active">
              <a href="javascript:void(0);">1</a>
            </li>
            <li>
              <a href="javascript:void(0);">2</a>
            </li>
            <li>
              <a href="javascript:void(0);">3</a>
            </li>
            <li>
              <a href="javascript:void(0);">..</a>
            </li>
            <li>
              <a href="javascript:void(0);">10</a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i class="material-icons-outlined">arrow_forward_ios</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default AppUserSurveyBody;
