import React from "react";
import { Link } from "react-router-dom";

const CategoryHeader = ({
  updateRankHandler = () => {},
  deleteCategories = () => {},
  setFilterIsRoot = () => {},
  reloadData = () => {},
}) => {
  return (
    <div className="filter-container mb-3">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div
            className="d-flex justify-content-sm-end"
            style={{ float: "right" }}
          >
            <button
              type="button"
              className="btn btn-outline-info rounded-0 shadow-none me-3"
              onClick={reloadData}
            >
              <i className="material-icons">refresh</i>
            </button>
            <Link
              to="/admin/category/add"
              className="btn btn-info d-flex align-items-center rounded-0 shadow-none me-3  d-none d-sm-block "
            >
              <i className="material-icons me-2">add</i> Add New
            </Link>

            <div className="dropdown">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                <li>
                  <Link className="dropdown-item" to="/admin/category/add">
                    Add New
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={deleteCategories}
                  >
                    Delete Selected
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={updateRankHandler}
                  >
                    Update selected rank
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      setFilterIsRoot(true);
                    }}
                  >
                    Show Root Categories
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;
