/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const AssignCourseModal = ({
  afterModalClose = () => {},
  selectedFaqId = null,
  setSelectedFaqId = () => {},
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [courseLoading, setCourseLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [faqCourses, setFaqCourses] = useState([]);

  const [courseName, setCourseName] = useState("");

  const [checkedCourses, setCheckedCourses] = useState({}); // To store checked state

  const [isSaving, setisSaving] = useState(false);

  const [validation, setValidation] = useState({
    courseError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (faqCourses.length === 0) {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get course list
  const getAllCourseList = async () => {
    try {
      setCourseLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_RECORDS +
        `?token=${token}&modulefrom=backend`;

      const response = await getData(requestURL);

      setCourseLoading(false);

      console.log(response);

      if (response.status) {
        setCourseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Filtered list based on search input if length > 2
  const filteredCourses =
    courseName.length > 2
      ? courseList.filter((course) =>
          course.title.toLowerCase().includes(courseName.toLowerCase())
        )
      : courseList;

  //function for check expert
  const courseCheckHandler = (courseId, isChecked) => {
    setCheckedCourses((prevState) => ({
      ...prevState,
      [courseId]: isChecked, // Update checked state for the specific expert
    }));

    if (isChecked) {
      // Add to faqExperts if checked, if not already there
      if (!faqCourses.includes(courseId)) {
        setFaqCourses((prevState) => [...prevState, courseId]);
      }
    } else {
      // Remove from faqExperts if unchecked
      setFaqCourses((prevState) => prevState.filter((id) => id !== courseId));
    }
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const data = response.data;

        if (data.courseids && data.courseids.length > 0) {
          for (let courseId of data.courseids) {
            courseCheckHandler(courseId, true);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save faq function
  const saveFaqHandler = async (e) => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let apiData = {
          courseids: faqCourses,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedFaqId) {
          requestURL += url.API_UPDATE_FAQ + `/${selectedFaqId}?token=${token}`;

          response = await putData(requestURL, apiData);
        }

        setisSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal
          let currentModal = document.querySelector("#assignCoursesModal");
          let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
          bootstrapModal.hide();

          resetHandler();
          afterModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setCourseList([]);
    setFaqCourses([]);
    setCourseName("");
    setCheckedCourses({});
    setSelectedFaqId(null);

    setValidation({
      courseError: false,
    });
  };

  useEffect(() => {
    if (selectedFaqId) {
      getAllCourseList();
      getFaqDetails();
    }
  }, [selectedFaqId]);

  return (
    <div
      className="modal fade"
      id="assignCoursesModal"
      aria-labelledby="assignCoursesModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="modal-title w-100" id="assignCoursesModalLabel">
              Assign Courses
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 m-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="search position-relative mb-4">
                <button className="d-block position-absolute top-50 text-primary material-icons bg-transparent ms-3 translate-middle-y">
                  search
                </button>
                <input
                  type="text"
                  className="form-control bg-gray-100 rounded-0 shadow-none ps-5"
                  placeholder="Search course by name"
                  id="expertSearch"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
                {validation.courseError && (
                  <div className="text-danger mt-2">
                    Please select at least one course.
                  </div>
                )}
              </div>

              <div className="form-row mb-4">
                <label htmlFor="question" className="fw-bold mb-2">
                  Courses
                </label>

                <ul
                  className="courses"
                  id="appcourses"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {courseLoading
                    ? [1, 2, 3].map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="px-3 py-3 bg-gray-100 text-green d-flex align-items-center gap-3 placeholder-glow"
                          >
                            <span className="placeholder col-6"></span>
                          </li>
                        );
                      })
                    : filteredCourses.map((course, index) => {
                        return (
                          <li
                            key={index}
                            className="px-3 py-3 bg-gray-100 text-green d-flex align-items-center gap-3"
                          >
                            <input
                              type="checkbox"
                              className="w-auto"
                              id={`expert_checkbox_${course._id}`}
                              checked={!!checkedCourses[course._id]} // Checked state
                              value={course._id}
                              onChange={(e) =>
                                courseCheckHandler(course._id, e.target.checked)
                              }
                            />
                            <span className="d-block">{course.title}</span>
                          </li>
                        );
                      })}
                </ul>
              </div>
              <div className="form-action">
                <button
                  type="button"
                  className="btn btn-yellow rounded-0 m-0"
                  onClick={saveFaqHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Add Course
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignCourseModal;
