/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const AppUserListFilterModal = ({
  setfilterName = () => {},
  setfilterSurname = () => {},
  setfilterEmail = () => {},
  setfilterPhone = () => {},
  setfilterCity = () => {},
  setfilterRole = () => {},
  roleList = [],
}) => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const [roleValue, setroleValue] = useState(null);
  const [roleId, setroleId] = useState(null);
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [position, setposition] = useState("");
  const [city, setcity] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [company, setcompany] = useState("");
  const [tags, setTags] = useState([]);
  const [subscribeMailValue, setsubscribeMailValue] = useState(null);
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("");

  //function for select role
  const roleSelectionHandler = (val) => {
    if (val) {
      setroleValue(val);
      setroleId(val.value);
    } else {
      setroleValue(null);
      setroleId(null);
    }
  };

  //function for search
  const searchHandler = () => {
    if (name !== "") setfilterName(name);
    if (surname !== "") setfilterSurname(surname);
    if (email !== "") setfilterEmail(email);
    if (phone !== "") setfilterPhone(phone);
    if (city !== "") setfilterCity(city);
    if (roleId !== null) setfilterRole(roleId);

    // close the modal
    let filterModal = document.querySelector("#appUserFilter");
    let bootstrapModal = bootstrap.Modal.getInstance(filterModal);
    bootstrapModal.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    setname("");
    setsurname("");
    setemail("");
    setphone("");
    setcity("");
    setroleId(null);
    setroleValue(null);
  };

  return (
    <div
      className="modal fade"
      id="appUserFilter"
      aria-labelledby="appUserFilter"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4">
            <h2 className="text-green w-100" id="appUserFilter">
              Search By Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                className="form-row mb-3"
                data-select2-id="select2-data-14-jttb"
              >
                <label htmlFor="status" className="fw-bold mb-2">
                  Status
                </label>
                <Select />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  Role
                </label>
                <Select
                  isClearable
                  placeholder={t("Select Role")}
                  options={roleList}
                  value={roleValue}
                  onChange={(val) => roleSelectionHandler(val)}
                />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="surname" className="fw-bold mb-2">
                  Surname
                </label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Surname"
                  value={surname}
                  onChange={(e) => setsurname(e.target.value)}
                />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="email" className="fw-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="tags" className="fw-bold mb-2">
                  Labels
                </label>
                <Select />
              </div>
              <div className="form-row mb-3">
                <label htmlFor="skills" className="fw-bold mb-2">
                  Skills
                </label>
                <Select />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="searching" className="fw-bold mb-2">
                  Searching
                </label>
                <Select />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="interest" className="fw-bold mb-2">
                  Interest
                </label>
                <Select />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="phone" className="fw-bold mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  id="filterphone"
                  name="phone"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="location" className="fw-bold mb-2">
                  City/State
                </label>
                <input
                  type="text"
                  id="filterlocation"
                  name="state"
                  className="form-control text-green rounded-0"
                  placeholder="Enter City/State"
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                />
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    End Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserListFilterModal;
