import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserInterestBody = () => {
  return (
    <AppUserDetailsLayout moduleName="interest">
      <div class="profile_graybx_Outer">
        <div class="profile_graybx_item">
          <div class="interest_hdng">
            <h4>My interest and search preference</h4>
          </div>
          <div class="profile_fillOuter wd_260">
            <div class="profile_fill_row">
              <label>Look for</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Search for interest">
                    <option>Please select</option>
                    <option>Project Works</option>
                    <option>Project Works</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>The detail I'm looking for is...</label>
              <div class="input_fill">
                <input
                  type="text"
                  value=""
                  class="form-control"
                  placeholder="Enter text"
                />
              </div>
            </div>
            <div class="profile_fill_row">
              <label>what i offer</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Search for interest">
                    <option>Please select</option>
                    <option>Project 1</option>
                    <option>Project 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>The detail of what I offer</label>
              <div class="input_fill">
                <input
                  type="text"
                  value=""
                  class="form-control"
                  placeholder="Enter text"
                />
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Company type </label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>Project 1</option>
                    <option>Project 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Days of the week I can meet </label>
              <div class="input_fill">
                <ul class="profile_chkList">
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Mon</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Tue</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Wed</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Thu</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Fri</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Sat</p>
                    </label>
                  </li>
                  <li class="">
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Sun</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Preferred time to meet </label>
              <div class="input_fill">
                <ul class="profile_chkList">
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>07:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>08:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>09:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>10:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>11:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>12:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>13:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>14:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>15:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>16:00 </p>
                    </label>
                  </li>

                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>17:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>18:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>19:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>20:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>21:00 </p>
                    </label>
                  </li>
                  <li>
                    <label class="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>22:00 </p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Sectors of you interested </label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>interested 1</option>
                    <option>interested 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Sectors do you offer products</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>products 1</option>
                    <option>products 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Interested in ngo companies</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>companies 1</option>
                    <option>companies 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Ecosystems i collaborate</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>Ecosystems 1</option>
                    <option>Ecosystems 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>What quest are we forgetting? </label>
              <div class="input_fill">
                <input
                  type="text"
                  value=""
                  class="form-control"
                  placeholder="Enter text"
                />
              </div>
            </div>
            <div class="profile_fill_row">
              <label>What sector are we forgetting? </label>
              <div class="input_fill">
                <input
                  type="text"
                  value=""
                  class="form-control"
                  placeholder="Enter text"
                />
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Survey</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>Survey 1</option>
                    <option>Survey 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Current customers</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>customers 1</option>
                    <option>customers 2</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile_fill_row">
              <label>Desired customers</label>
              <div class="input_fill">
                <div class="model_selectBx">
                  <select class="select2" title="Select">
                    <option>Please select</option>
                    <option>Desired customers 1</option>
                    <option>Desired customers 2</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="prfile_btnOuter">
        <div class="profile_leftBtn"></div>
        <div class="profile_rightBtn">
          <button class="basic_btn cancel_btn">Cancel</button>
          <button class="basic_btn ylw_btn">Submit</button>
        </div>
      </div>
    </AppUserDetailsLayout>
  );
};

export default UserInterestBody;
