import React from "react";
import { Link } from "react-router-dom";

const CrmCommonHeader = ({ moduleName = "" }) => {
  return (
    <section className="course_tablinks_outer learning_coursetablinks">
      <div className="container-fluid">
        <div className="tab_links">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "appUsers" ? "active" : ""
                }`}
                to="/admin/contact/allusers"
              >
                App Users
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "contacts" ? "active" : ""
                }`}
                to="/admin/contact/manage"
              >
                Contacts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "conversation" ? "active" : ""
                }`}
                to="/admin/messages/inbox"
              >
                Conversation
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${moduleName === "list" ? "active" : ""}`}
                to="/admin/list/manage"
              >
                List
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${moduleName === "label" ? "active" : ""}`}
                to="/admin/tags/manage"
              >
                Label
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CrmCommonHeader;
