/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import fileTypeOptions from "data/Prod/FileTypeOptions.json";

const SaveLessonPopup = ({
  courseTitle = "",
  selectedFileType,
  setSelectedFileType,
  setuploadedContentFileId,
  setuploadedContentFilePath,
  uploadedContents = [],
  setuploadedContents = () => {},
  selectedLessonId,
  setselectedLessonId,
  afterPopupClose,
}) => {
  const { id: courseId } = useParams();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const [chapterList, setchapterList] = useState([]);

  const [chapterValue, setchapterValue] = useState(null);
  const [selectedChapterId, setselectedChapterId] = useState(null);

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");

  const [selectedFileExtension, setSelectedFileExtension] = useState("");
  const [contentUploading, setcontentUploading] = useState(false);

  const [videoUrl, setvideoUrl] = useState("");
  const [duration, setduration] = useState("");
  const [passingScore, setpassingScore] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [deleteContentIds, setDeleteContentIds] = useState([]);

  const editor = useRef(null);

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 350,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);

  //get chapter list
  const getChapterList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_CHAPTER_LIST_OPTIONS +
        `?token=${token}&courseid=${courseId}`;

      const response = await getData(requestURL);

      if (response.data) {
        setchapterList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select chapter
  const chapterSelectionHandler = (val) => {
    if (val) {
      setchapterValue(val);
      setselectedChapterId(val.value);
    } else {
      setchapterValue(null);
      setselectedChapterId(null);
    }
  };

  // file type selection
  const fileTypeSelectionHandler = (val) => {
    setSelectedFileExtension(val.value);
  };

  // content upload function
  const contentUploadHandler = async (e) => {
    const file = e.target.files[0];

    setcontentUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setcontentUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetContentFile();
        setuploadedContentFileId(response.data._id);
        setuploadedContentFilePath(response.data.path);

        const editContentModal = new bootstrap.Modal(
          document.getElementById("modalSaveLessonContent")
        );
        editContentModal.show();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //reset content file
  const resetContentFile = () => {
    const file = document.getElementById("selected_lessonupload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for change value in content block
  const changeContentBlock = (index, field, value) => {
    const updatedFormValues = uploadedContents.map((block, i) =>
      index === i ? { ...block, [field]: value } : block
    );
    setuploadedContents(updatedFormValues);
  };

  //delete content block
  const onDeleteContentBlock = (index, id) => {
    const filteredBlock = [...uploadedContents];
    filteredBlock.splice(index, 1);
    setuploadedContents(filteredBlock);

    setDeleteContentIds([...deleteContentIds, id]);
  };

  //get lesson details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${selectedLessonId}?token=${token}&filtercourse=${courseId}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data.chapteridvalue) {
          chapterSelectionHandler(response.data.chapteridvalue);
        }

        settitle(response.data.title);
        setdetails(response.data.description);

        setuploadedContents(response.data.uploads);

        setvideoUrl(response.data.videourl);
        setduration(response.data.duration);
        setpassingScore(response.data.passingscore);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save lesson
  const saveLessonHandler = async () => {
    try {
      setIsSaving(true);

      let lessonData = {
        course: courseId,
        chapterid: selectedChapterId,
        title,
        description: details,
        passingscore: passingScore,
        videourl: videoUrl,
        duration,
        members: [],
        uploadedcontent: uploadedContents,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (selectedLessonId) {
        lessonData.deletedcontentids = deleteContentIds;

        requestURL +=
          url.API_UPDATE_COURSE_LESSON + `/${selectedLessonId}?token=${token}`;

        response = await putData(requestURL, lessonData);
      } else {
        requestURL += url.API_ADD_NEW_COURSE_LESSON + `?token=${token}`;

        response = await postData(requestURL, lessonData);
      }

      console.log(response);

      setIsSaving(false);

      if (response.status) {
        afterPopupClose();
        popupToggle();
        resetHandler();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setchapterValue(null);
    setselectedChapterId(null);
    settitle("");
    setdetails("");
    setSelectedFileExtension("");
    setcontentUploading(false);
    setvideoUrl("");
    setduration("");
    setpassingScore("");

    //props reset
    setSelectedFileType("");
    setuploadedContentFileId(null);
    setuploadedContentFilePath("");
    setuploadedContents([]);

    setDeleteContentIds([]);

    setselectedLessonId(null);
  };

  useEffect(() => {
    if (selectedFileExtension !== "") {
      let fileType = "";
      switch (selectedFileExtension) {
        case "video":
          fileType = "video/*";
          break;
        case "pdf":
          fileType = "application/pdf";
          break;
        case "doc":
          fileType =
            "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case "ppt":
          fileType =
            "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation";
          break;
        default:
          fileType = "application/pdf";
      }
      setSelectedFileType(fileType); // Update the accept attribute
    }
  }, [selectedFileExtension]);

  useEffect(() => {
    if (selectedLessonId) {
      getLessonDetails();
    }
  }, [selectedLessonId]);

  useEffect(() => {
    if (courseId) {
      getChapterList();
    }
  }, [courseId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link
                to="#"
                onClick={() => {
                  resetHandler();
                  popupToggle();
                }}
              >
                <i className="material-icons-round">close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>{t("Save lesson to")}</h3>
              <Link to="#">{courseTitle}</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="course_form_details_innr ps-3 pe-2">
        <div className="course_detailsbx_item">
          <div className="profile_fillOuter">
            {/* <!-- ========== Start chapter Section ========== --> */}
            <div className="profile_fill_row course_fill_row">
              <label>{t("Chapter / Code")}</label>
              <div className="input_fill">
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isClearable
                  placeholder={t("Select Chapter")}
                  options={chapterList}
                  value={chapterValue}
                  onChange={(val) => {
                    chapterSelectionHandler(val);
                  }}
                />
              </div>
            </div>
            {/* <!-- ========== End chapter Section ========== --> */}

            {/* <!-- ========== Start title Section ========== --> */}
            <div className="profile_fill_row course_fill_row">
              <label>{t("Name")}</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="selected_lessontitle"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
            {/* <!-- ========== End title Section ========== --> */}

            <div className="profile_fill_row course_fill_row">
              <label>{t("Text content")}</label>
              <div className="input_fill description_fill">
                <JoditEditor
                  ref={editor}
                  value={details}
                  config={detailsEditorConfig}
                  onBlur={(newContent) => {
                    // console.log("New content in on blur----->", newContent);
                    setdetails(newContent);
                  }}
                  onChange={(newContent, event) => {
                    // setMailDescription(newContent);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label className="course_attach_content_label">
                {t("Attach Content")}
              </label>

              <div className="d-flex align-items-center gap-3 w-100">
                <div className="select_type input_fill w-50 mr-2">
                  <label className="fw-lighter text-black">
                    {t("Select Upload File Type")}
                  </label>
                  <Select
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select file type")}
                    options={fileTypeOptions}
                    onChange={(val) => {
                      fileTypeSelectionHandler(val);
                    }}
                  />
                </div>

                {selectedFileType === "" ? null : (
                  <div className="input_fill w-50" style={{ display: "block" }}>
                    <label className="text-danger fw-lighter">
                      {t("Upload file")}{" "}
                      <span id="showSelectedType">{selectedFileExtension}</span>{" "}
                      **{" "}
                    </label>

                    <div className="upload_fileinnr form-control">
                      <input
                        accept={selectedFileType}
                        type="file"
                        className="file_btn"
                        id="selected_lessonupload"
                        onChange={contentUploadHandler}
                      />
                      <span>No file chosen</span>
                      {contentUploading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="material-icons-outlined">file_upload</i>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* file upload sec  */}
            <div className="profile_fill_row course_fill_row ">
              <div className="upload_file_outer col-12 mb-4">
                {uploadedContents.map((item, index) => {
                  console.log(item);
                  return (
                    <div
                      className="video_upload_inr my-2"
                      style={{ width: "100%" }}
                      id="videoblock_5882"
                      key={index}
                    >
                      <div className="video_upload" width="180" height="140">
                        {item.filetype === "video/*" ? (
                          <video
                            controls=""
                            src={url.SERVER_URL + item.filepath}
                            className="shblock video_upload appendvideopath uploadContentContainer"
                            width="180"
                            height="140"
                          ></video>
                        ) : (
                          <div className="shblock pdf_upload">
                            <img
                              alt=""
                              className="wh-100"
                              height={140}
                              src={
                                item.filetype === "application/pdf"
                                  ? "/images/pdf-thumb.png"
                                  : item.filetype ===
                                    "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  ? "/images/doc-thumb.png"
                                  : item.filetype ===
                                    "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                  ? "/images/ppt-thumb.png"
                                  : item.filetype ===
                                    "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                  ? "/images/ppt-thumb"
                                  : ""
                              }
                            />
                          </div>
                        )}

                        <div className="input_fill white_fill">
                          <label className="text-warning">{t("Order")}</label>
                          <input
                            type="number"
                            name="order"
                            className="form-control"
                            placeholder="Order"
                            value={item.order}
                            onChange={(e) => {
                              changeContentBlock(
                                index,
                                "order",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div style={{ width: "80%" }}>
                        <div className="input_fill mb-1">
                          <label className="text-warning">{t("Name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="title"
                            value={item.title}
                            onChange={(e) => {
                              changeContentBlock(
                                index,
                                "title",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div className="input_fill mb-1">
                          <label className="text-warning">{t("Label")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="title"
                            value={item.label}
                            onChange={(e) => {
                              changeContentBlock(
                                index,
                                "label",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div className="input_fill description_fill mt-3">
                          <label className="text-warning">{t("Details")}</label>
                          <textarea
                            className="form-control appendvideodetails"
                            name="details"
                            value={item.description}
                            onChange={(e) => {
                              changeContentBlock(
                                index,
                                "description",
                                e.target.value
                              );
                            }}
                          ></textarea>
                        </div>
                      </div>

                      <div className="delete_vdo removeblock">
                        <Link
                          to="#"
                          onClick={() => {
                            onDeleteContentBlock(index, item._id);
                          }}
                        >
                          <span className="material-icons-outlined">
                            delete
                          </span>
                        </Link>
                      </div>

                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Video Url")}</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  className="form-control"
                  value={videoUrl}
                  onChange={(e) => {
                    setvideoUrl(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Duration")}</label>
              <div className="input_fill mb-0">
                <input
                  type="number"
                  className="form-control"
                  value={duration}
                  onChange={(e) => {
                    setduration(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>{t("Passing Score")}(%)</label>
              <div className="input_fill mb-0">
                <input
                  type="text"
                  className="form-control"
                  value={passingScore}
                  onChange={(e) => {
                    setpassingScore(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label></label>
              <div className="input_fill mb-0">
                <div className="profile_rightBtn">
                  <button
                    className="basic_btn ylw_btn"
                    onClick={saveLessonHandler}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Submit")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveLessonPopup;
