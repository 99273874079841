/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

import ContactTimeLineBody from "./TabComponents/ContactTimeLineBody";
import ContactInfoBody from "./TabComponents/ContactInfoBody";
import NoteSection from "./TabComponents/NoteSection";

const ContactTimelineTab = ({
  contactData = {},
  contactPersonId = null,
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [timeLinesData, settimeLinesData] = useState([]);

  //function for get contact timeline
  const getContactTimeLine = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CONTACT_TIMELINE +
        `/${id}?token=${token}&personid=${personId}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settimeLinesData(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (contactPersonId) {
      getContactTimeLine(contactPersonId);
    }
  }, [contactPersonId]);

  return (
    <div className="timeline-container">
      <div className="row gx-2">
        <ContactInfoBody contactData={contactData} />

        <ContactTimeLineBody timeLinesData={timeLinesData} />

        <div className="col-lg-3">
          <NoteSection
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactTimelineTab;
