import React from "react";
import GlobalContext from "Context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import Authmiddleware from "Routes/Authmiddleware";

const App = () => {
  return (
    <Router>
      <GlobalContext>
        <Authmiddleware />
      </GlobalContext>
    </Router>
  );
};

export default App;
