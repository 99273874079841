import React from "react";

const ContactTabHeader = () => {
  return (
    <ul
      className="nav nav-tabs border-0 d-flex mb-3"
      id="leaderDetailsTab"
      role="tablist"
    >
      <li className="nav-item flex-fill me-2" role="presentation">
        <button
          className="nav-link d-block border-0 w-100 rounded-0 p-3 active"
          id="timeline-tab"
          data-bs-toggle="tab"
          data-bs-target="#timeline-tab-pane"
          type="button"
          role="tab"
          aria-controls="timeline-tab-pane"
          aria-selected="true"
        >
          Timeline
        </button>
      </li>
      <li className="nav-item flex-fill me-2" role="presentation">
        <button
          className="nav-link d-block border-0 w-100 rounded-0 p-3"
          id="task-tab"
          data-bs-toggle="tab"
          data-bs-target="#task-tab-pane"
          type="button"
          role="tab"
          aria-controls="task-tab-pane"
          aria-selected="false"
        >
          Task
        </button>
      </li>
      <li className="nav-item flex-fill" role="presentation">
        <button
          className="nav-link d-block border-0 w-100 rounded-0 p-3"
          id="mail-tab"
          data-bs-toggle="tab"
          data-bs-target="#mail-tab-pane"
          type="button"
          role="tab"
          aria-controls="mail-tab-pane"
          aria-selected="false"
        >
          Mail
        </button>
      </li>
    </ul>
  );
};

export default ContactTabHeader;
