/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import CourseTeamHeader from "./Header/CourseTeamHeader";
import SaveMemberModal from "./Modal/SaveMemberModal";

const MemberListBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [title, settitle] = useState("");
  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [isLoading, setIsLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: response.data.title },
          {
            title: t("Team"),
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for get all member
  const getAllCourseMembers = async () => {
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_MEMBERS +
        `?token=${token}&courseid=${courseId}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMemberList(response.data);
        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
      getAllCourseMembers();
    }
  }, [courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => {
          if (row.original.role === "Moderator") {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="modal"
                data-bs-target="#saveCourseMemberModal"
                aria-controls="saveCourseMemberModal"
                onClick={() => {
                  setSelectedMemberId(row.original._id);
                }}
              >
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: t("Member"),
        size: 300,
        Cell: ({ row }) => (
          <div className="member-profile d-flex align-items-center">
            <div className="img-container me-3">
              <Link to="#">
                <img
                  src={
                    row.original.image == ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.image
                  }
                  className="img-fluid rounded-circle"
                  width="40"
                  height="40"
                />
              </Link>
            </div>
            <div className="info">
              <h6 className="name fw-bold d-flex align-items-center mb-1">
                <Link to="" className="text-primary">
                  {row.original.name}
                </Link>
              </h6>
              <Link to="#" className="email text-gray-600">
                {row.original.email}
              </Link>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "role",
        header: t("Role"),
        size: 200,
      },
      {
        accessorKey: "moderatorstatus",
        header: t("Status"),
        size: 250,
        Cell: ({ row }) => (
          <div className="d-flex gap-3">
            {row.original.moderatorstatus == "" ? (
              <p>-</p>
            ) : (
              <>
                <div className="moderator d-flex align-items-center">
                  <i
                    className={`material-icons ${
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    } me-2`}
                  >
                    check_circle
                  </i>
                  <span
                    className={
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    }
                  >
                    {t("Moderator")}
                  </span>
                </div>

                <div className="member d-flex align-items-center text-gray-600">
                  <i
                    className={`material-icons ${
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    } me-2`}
                  >
                    check_circle
                  </i>
                  <span
                    className={
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    }
                  >
                    {t("Member")}
                  </span>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        accessorKey: "requestdate",
        header: t("Date"),
        size: 200,
      },
    ],
    [memberList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {}, [rowSelection]);

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="members" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <CourseTeamHeader />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={memberList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 400,
                    size: 100, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Member Name"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <SaveMemberModal
        selectedMemberId={selectedMemberId}
        setSelectedMemberId={setSelectedMemberId}
        afterCloseModalHandler={getAllCourseMembers}
      />

      {/* --------- alert popup ------------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CourseCommonLayout>
  );
};

export default MemberListBody;
