/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

const PublicHeader = () => {
  return (
    <header className="header_outer">
      <div className="hdr_top">
        <div className="container">
          <div className="top_outer">
            <div className="top_menu"></div>
            <div className="lang_slect_bx">
              <select className="select">
                <option>EN</option>
                <option>ES</option>
              </select>
            </div>
            <div className="hdr_srch"></div>
          </div>
        </div>
      </div>
      <div className="hdr_bttm">
        <div className="container">
          <div className="header_innr">
            <div className="hdr_logo">
              <a className="navbar-brand" href="https://dev.habitatm.es">
                <img src="/images/logo.svg" alt="" />
              </a>
            </div>
            <div className="header_menu">
              <span className="menu_cross">
                <i className="material-icons-outlined"> close </i>
              </span>
              <div className="inner_pnlBody" id="application_menu"></div>
            </div>
            <div className="menu_bar">
              <Link to="#">
                <span className="material-icons-round">menu</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </header>
  );
};

export default PublicHeader;
