/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./Routes";

const Authmiddleware = () => {
  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  let token = null;

  token = localStorage.getItem("token"); //get token from local storage

  return (
    <Switch>
      {/* <!-- ========== Start non authenticated route Section ========== --> */}
      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}
      {/* <!-- ========== End non authenticated route Section ========== --> */}

      {/* <!-- ========== Start authenticated route Section ========== --> */}
      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}
      {/* <!-- ========== End authenticated route Section ========== --> */}

      {/* <!-- ========== Start default redirect to dashboard ========== --> */}
      {token ? <Redirect to="/admin/dashboard" /> : <Redirect to="/" />}

      {/* <!-- ========== End default redirect to dashboard ========== --> */}
    </Switch>
  );
};

export default Authmiddleware;
