/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ModuleRoleHeader from "./Header/ModuleRoleHeader";
import SaveModuleRoleModal from "./Modal/SaveModuleRoleModal";

const ModuleRoleBody = () => {
  const { t, i18n } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Administration") },
    { title: t("Module Roles") },
  ];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [moduleList, setModuleList] = useState([]);
  const [moduleRoleList, setModuleRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [moduleRoleId, setModuleRoleId] = useState(null);

  const [filterModuleRoleName, setFilterModuleRoleName] = useState("");
  const [filterModuleRoleSlug, setFilterModuleRoleSlug] = useState("");
  const [filterModules, setfilterModules] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectModuleRoleIds, setSelectModuleRoleIds] = useState([]);

  //function for get all tags
  const getAllModuleRoles = async () => {
    setRowSelection({});
    setSelectModuleRoleIds([]);

    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_MODULE_ROLE + `?token=${token}`;

      if (filterModuleRoleName !== "") {
        requestURL = requestURL + `&filtername=${filterModuleRoleName}`;
      }

      if (filterModuleRoleSlug !== "") {
        requestURL = requestURL + `&filterslug=${filterModuleRoleSlug}`;
      }

      if (filterModules.length > 0) {
        requestURL = requestURL + `&filtermodules=${filterModules}`;
      }

      // console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setModuleRoleList(response.data);
        // resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllModuleRoles();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#modal_saverole"
          aria-controls="modal_saverole"
          onClick={() => {
            setModuleRoleId(row.original._id);
          }}
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: t("Name"),
      size: 250,
    },
    {
      accessorKey: "modulename",
      header: t("Module"),
      size: 250,
    },
    {
      accessorKey: "slug",
      header: t("Slug"),
      size: 250,
    },
    {
      accessorKey: "createddate",
      header: t("Date"),
      size: 250,
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectModuleRoleIds(selectedIdsArray);
    } else {
      setSelectModuleRoleIds([]);
    }
  }, [rowSelection]);

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />
            <ModuleRoleHeader />

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={moduleRoleList} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                  columnOrder: columnOrder,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                  columnOrder: columnOrder,
                }}
                enableColumnOrdering={true}
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Title"),
                }}
              />
            </div>
          </div>
        </div>

        <SaveModuleRoleModal
          afterModalCLose={getAllModuleRoles}
          moduleRoleId={moduleRoleId}
          setModuleRoleId={setModuleRoleId}
        />
      </section>
    </>
  );
};

export default ModuleRoleBody;
