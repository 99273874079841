/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import { reactSelectStyle } from "Config/Config";

import languageOptions from "data/Prod/LanguageOptions.json";
import fileTypeOptions from "data/Prod/FileTypeOptions.json";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";
import EditUploadContentModal from "Components/Common/Modal/EditUploadContentModal";

const CourseDetailsBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();
  const history = useHistory();

  const smapleFileRef = useRef(null);
  const editor = useRef(null);

  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [selectedFileType, setSelectedFileType] = useState("");

  const [contentUploading, setcontentUploading] = useState(false);
  const [uploadedContentFileId, setuploadedContentFileId] = useState(null);
  const [uploadedContentFilePath, setuploadedContentFilePath] = useState("");

  const [categoryList, setcategoryList] = useState([]);
  const [skillList, setskillList] = useState([]);

  const [title, settitle] = useState("");
  const [code, setcode] = useState("");
  const [languageValue, setlanguageValue] = useState(null);
  const [selectedLanguage, setselectedLanguage] = useState("");
  const [summary, setsummary] = useState("");
  const [courseDetails, setCourseDetails] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);
  const [skillValue, setskillValue] = useState(null);
  const [selectedSkills, setselectedSkills] = useState([]);
  const [uploadedContents, setuploadedContents] = useState([]);
  const [authorsBio, setAuthorsBio] = useState("");
  const [joiningMessage, setJoiningMessage] = useState("");
  const [thanksMessage, setThanksMessage] = useState("");
  const [learnContent, setlearnContent] = useState("");
  const [preRequisites, setPreRequisites] = useState("");

  const [imageUploading, setImageUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id

  const [isSaving, setIsSaving] = useState(false);

  const [deleteContentIds, setDeleteContentIds] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    codeWarning: false,
    titlewarning: false,
    categoryWarning: false,
    skillsWarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;
    if (code == "") {
      setValidation((prevState) => ({ ...prevState, codeWarning: true }));
      isValid = false;
    }
    if (title == "") {
      setValidation((prevState) => ({ ...prevState, titlewarning: true }));
      isValid = false;
    }
    if (categoryValue == null) {
      setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
      isValid = false;
    }
    if (skillValue == null) {
      setValidation((prevState) => ({ ...prevState, skillsWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "COURSE";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all skills
  const getAllSkillList = async () => {
    try {
      const parentSlug = "SKILL";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setskillList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setCourseDetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const learnEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setlearnContent(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const prerequisitesEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setPreRequisites(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);
  const learnEditorConfig = useMemo(() => learnEditorCustomConfig, []);
  const prerequisitesEditorConfig = useMemo(
    () => prerequisitesEditorCustomConfig,
    []
  );

  // file type selection
  const fileTypeSelectionHandler = (val) => {
    let fileType = "";
    switch (val.value) {
      case "video":
        fileType = "video/*";
        break;
      case "pdf":
        fileType = "application/pdf";
        break;
      case "doc":
        fileType =
          "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "ppt":
        fileType =
          "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      default:
        fileType = "application/pdf";
    }
    setSelectedFileType(fileType); // Update the accept attribute
  };

  // content upload function
  const contentUploadHandler = async (e) => {
    const file = e.target.files[0];

    setcontentUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setcontentUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetContentFile();
        setuploadedContentFileId(response.data._id);
        setuploadedContentFilePath(response.data.path);

        const editContentModal = new bootstrap.Modal(
          document.getElementById("modalSavevideo")
        );
        editContentModal.show();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //reset content file
  const resetContentFile = () => {
    const file = document.getElementById("sampleFileContent");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for change value in content block
  const changeContentBlock = (index, field, value) => {
    const updatedFormValues = uploadedContents.map((block, i) =>
      index === i ? { ...block, [field]: value } : block
    );
    setuploadedContents(updatedFormValues);
  };

  //delete content block
  const onDeleteContentBlock = (index, id) => {
    const filteredBlock = [...uploadedContents];
    filteredBlock.splice(index, 1);
    setuploadedContents(filteredBlock);
    setDeleteContentIds([...deleteContentIds, id]);
  };

  //function for select language
  const languaSelectionHandler = (val) => {
    if (val) {
      setlanguageValue(val);
      setselectedLanguage(val.value);
    } else {
      setlanguageValue(null);
      setselectedLanguage("");
    }
  };

  //upload course banner
  const courseBannerUploadHandler = async (e) => {
    const file = e.target.files[0];

    setImageUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setImageUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setUploadedImageId(response.data._id);
        setUploadedPreviewImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("courselogo_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select category
  const categorySelectionHandler = (val) => {
    if (val) {
      setcategoryValue(val);
      setselectedCategories(val.map((item) => item.value));
    } else {
      setcategoryValue(null);
      setselectedCategories([]);
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    if (val) {
      setskillValue(val);
      setselectedSkills(val.map((item) => item.value));
    } else {
      setskillValue(null);
      setselectedSkills([]);
    }
  };

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: t("Save") },
          { title: response.data.title },
        ]);

        setcode(response.data.code);
        settitle(response.data.title);
        setsummary(response.data.summary);
        setCourseDetails(response.data.description);
        setAuthorsBio(response.data.authorsbio);
        setJoiningMessage(response.data.joiningmessage);
        setThanksMessage(response.data.thanksmessage);
        //set language
        for (let languageData of languageOptions) {
          if (languageData.value === response.data.language) {
            languaSelectionHandler(languageData);
          }
        }

        categorySelectionHandler(response.data.categoryvalues);
        skillSelectionHandler(response.data.skillvalues);

        setuploadedContents(response.data.uploads);

        setlearnContent(response.data.learningcontent);
        setPreRequisites(response.data.prerequisites);

        setUploadedImageId(response.data?.courselogoid);
        setUploadedPreviewImage(response.data?.courselogopath);

        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for save course
  const saveCourseHandler = async () => {
    const todayValue = new Date().toISOString().split("T")[0];

    if (validationHandler()) {
      try {
        setIsSaving(true);

        let courseData = {
          code,
          parentid: null,
          companyid: null,
          title,
          summary: summary,
          description: courseDetails,
          language: selectedLanguage,
          categories: selectedCategories,
          skills: selectedSkills,
          uploadedcontent: uploadedContents,
          authorsbio: authorsBio,
          learningcontent: learnContent,
          prerequisites: preRequisites,
          joiningmessage: joiningMessage,
          thanksmessage: thanksMessage,
          logoid: uploadedImageId,
          autoapproval: false,
          createddate: todayValue,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (courseId) {
          courseData.deletedcontentids = deleteContentIds;

          requestURL += url.API_UPDATE_COURSE + `/${courseId}?token=${token}`;
          response = await putData(requestURL, courseData);
        } else {
          requestURL += url.API_ADD_NEW_COURSE + `?token=${token}`;
          response = await postData(requestURL, courseData);
        }

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            history.push("/admin/course/manage");
          }, 1500);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up the required fields"));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
    } else {
      setBreadCrumbText([
        { title: t("Course"), link: "/admin/course/manage" },
        { title: t("Save") },
        { title: "" },
      ]);
    }
  }, [courseId]);

  useEffect(() => {
    languaSelectionHandler(languageOptions[0]);
    getAllCategoryList();
    getAllSkillList();
  }, []);

  useEffect(() => {
    if (selectedFileType !== "") {
      smapleFileRef.current.click(); // Trigger the file input click
    }
  }, [selectedFileType]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              {courseId ? (
                <CourseDetailsTabLinks pageName="coursedetails" />
              ) : (
                <div class="course_tab_links">
                  <div class="tab_links">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <Link class="nav-link active show" to="#">
                          <i class="material-icons-outlined">description</i>
                          <span>Add Course Details</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <div className="course_form_details_innr">
                <div className="course_detailsbx_item">
                  <div className="profile_fillOuter">
                    {/* <!-- ========== Start code and language Section ========== --> */}
                    <div className="double_form_outer">
                      <div className="row">
                        {/* <!-- ========== Start code Section ========== --> */}
                        <div className="col-lg-6">
                          <div className="profile_fill_row course_fill_row">
                            <label>{t("Code")}</label>
                            <div className="input_fill">
                              <input
                                type="text"
                                className="form-control"
                                value={code}
                                onChange={(e) => {
                                  setcode(e.target.value);
                                  setValidation((prevState) => ({
                                    ...prevState,
                                    codeWarning: false,
                                  }));
                                }}
                              />
                              {validation.codeWarning && (
                                <div className="error-message mt-1">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-icons-outlined">
                                      warning
                                    </span>
                                    <span>{t("Please enter code")}!</span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <!-- ========== End code Section ========== --> */}

                        {/* <!-- ========== Start language Section ========== --> */}
                        <div className="col-lg-6">
                          <div className="profile_fill_row course_fill_row">
                            <label>Language</label>
                            <div className="input_fill">
                              <Select
                                placeholder={t("Select Language")}
                                styles={reactSelectStyle("6px")}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                options={languageOptions}
                                value={languageValue}
                                onChange={(val) => {
                                  languaSelectionHandler(val);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <!-- ========== End language Section ========== --> */}
                      </div>
                    </div>
                    {/* <!-- ========== End code and language Section ========== --> */}

                    {/* <!-- ========== Start title Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>{t("Title")}</label>
                      <div className="input_fill">
                        <input
                          type="text"
                          className="form-control"
                          value={title}
                          onChange={(e) => {
                            settitle(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              titlewarning: false,
                            }));
                          }}
                        />
                        {validation.titlewarning && (
                          <div className="error-message mt-3">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-icons-outlined">
                                warning
                              </span>
                              <span>{t("Please enter title")}!</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!-- ========== End title Section ========== --> */}

                    {/* <!-- ========== Start summary Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>{t("Summary")}</label>
                      <div className="input_fill description_fill">
                        <textarea
                          className="form-control"
                          value={summary}
                          onChange={(e) => setsummary(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {/* <!-- ========== End summary Section ========== --> */}

                    {/* <!-- ========== Start details Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Details</label>
                      <div className="input_fill description_fill">
                        <JoditEditor
                          ref={editor}
                          value={courseDetails}
                          config={detailsEditorConfig}
                          onBlur={(newContent) => {
                            // console.log("New content in on blur----->", newContent);
                            setCourseDetails(newContent);
                          }}
                          onChange={(newContent, event) => {
                            // setMailDescription(newContent);
                          }}
                        />
                      </div>
                    </div>
                    {/* <!-- ========== End details Section ========== --> */}

                    {/* <!-- ========== Start course category Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Category</label>
                      <div className="input_fill">
                        <div className="model_selectBx">
                          <Select
                            styles={reactSelectStyle("6px")}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            isMulti
                            placeholder={t("Select Categories")}
                            options={categoryList}
                            value={categoryValue}
                            onChange={(val) => {
                              categorySelectionHandler(val);
                              setValidation((prevState) => ({
                                ...prevState,
                                categoryWarning: false,
                              }));
                            }}
                          />
                          {validation.categoryWarning && (
                            <div className="error-message mt-1">
                              <p className="d-flex align-items-center gap-1 text-danger">
                                <span className="material-icons-outlined">
                                  warning
                                </span>
                                <span>{t("Please enter category")}!</span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- ========== End course category Section ========== --> */}

                    {/* <!-- ========== Start course skill Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Skill</label>
                      <div className="input_fill">
                        <div className="model_selectBx">
                          <Select
                            styles={reactSelectStyle("6px")}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            isMulti
                            placeholder={t("Select Skills")}
                            options={skillList}
                            value={skillValue}
                            onChange={(val) => {
                              skillSelectionHandler(val);
                              setValidation((prevState) => ({
                                ...prevState,
                                skillsWarning: false,
                              }));
                            }}
                          />
                          {validation.skillsWarning && (
                            <div className="error-message mt-1">
                              <p className="d-flex align-items-center gap-1 text-danger">
                                <span className="material-icons-outlined">
                                  warning
                                </span>
                                <span>{t("Please enter skill")}!</span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- ========== End course skill Section ========== --> */}
                  </div>
                </div>

                {/* <!-- ========== Start prerequisites Section ========== --> */}
                <div className="course_detailsbx_item">
                  <div className="course_details_hdng">
                    <h4>{t("What for Intended Learners")}</h4>
                  </div>
                  <div className="profile_fillOuter">
                    <div className="text_summery_bx">
                      <label>
                        {t("What will students learn in your course")}?
                      </label>
                      <div className="input_fill description_fill">
                        <JoditEditor
                          ref={editor}
                          value={learnContent}
                          config={learnEditorConfig}
                          onBlur={(newContent) => {
                            // console.log("New content in on blur----->", newContent);
                            setlearnContent(newContent);
                          }}
                          onChange={(newContent, event) => {}}
                        />
                      </div>
                    </div>
                    <div className="text_summery_bx">
                      <label>
                        {t("What are the prerequisites for taking your course")}
                        ?
                      </label>
                      <div className="input_fill description_fill">
                        <JoditEditor
                          ref={editor}
                          value={preRequisites}
                          config={prerequisitesEditorConfig}
                          onBlur={(newContent) => {
                            setPreRequisites(newContent);
                          }}
                          onChange={(newContent, event) => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End prerequisites Section ========== --> */}

                {/* <!-- ========== Start sample content Section ========== --> */}
                <div className="course_detailsbx_item">
                  <div className="profile_fillOuter">
                    <div className="profile_fill_row course_fill_row">
                      <label className="course_attach_content_label">
                        {t("Attach Content")}
                      </label>
                      <div className="input_fill">
                        <div className="fs-6 mb-2 d-flex align-items-center gap-2">
                          <p>{t("Select Upload File Type")}</p>
                          {contentUploading ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : null}
                        </div>

                        <Select
                          placeholder={t("No file type selected")}
                          styles={reactSelectStyle("6px")}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={fileTypeOptions}
                          onChange={(val) => {
                            fileTypeSelectionHandler(val);
                          }}
                        />

                        <input
                          id="sampleFileContent"
                          ref={smapleFileRef}
                          type="file"
                          className="d-none"
                          accept={selectedFileType}
                          onChange={contentUploadHandler}
                        />
                      </div>
                    </div>

                    {/* content mapping here  */}
                    <div className="profile_fill_row course_fill_row ">
                      <div className="upload_file_outer col-12 mb-4">
                        {uploadedContents.map((item, index) => {
                          return (
                            <div
                              className="video_upload_inr my-2"
                              style={{ width: "100%" }}
                              id="videoblock_5882"
                              key={index}
                            >
                              <div
                                className="video_upload"
                                width="180"
                                height="140"
                              >
                                {item.filetype === "video/*" ? (
                                  <video
                                    controls=""
                                    src="http://dev.admin.habitatm.es/uploads/documents/1735015686-sample_vid01.mp4"
                                    className="shblock video_upload appendvideopath uploadContentContainer"
                                    width="180"
                                    height="140"
                                  ></video>
                                ) : (
                                  <div className="shblock pdf_upload">
                                    <img
                                      alt=""
                                      className="wh-100"
                                      height={140}
                                      src={
                                        item.filetype === "application/pdf"
                                          ? "/images/pdf-thumb.png"
                                          : item.filetype ===
                                            "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                          ? "/images/doc-thumb.png"
                                          : item.filetype ===
                                            "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                          ? "/images/ppt-thumb.png"
                                          : item.filetype ===
                                            "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                          ? "/images/ppt-thumb"
                                          : ""
                                      }
                                    />
                                  </div>
                                )}

                                <div className="input_fill white_fill">
                                  <label className="text-warning">
                                    {t("Order")}
                                  </label>
                                  <input
                                    type="number"
                                    name="order"
                                    className="form-control"
                                    placeholder="Order"
                                    value={item.order}
                                    onChange={(e) => {
                                      changeContentBlock(
                                        index,
                                        "order",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div style={{ width: "80%" }}>
                                <div className="input_fill mb-1">
                                  <label className="text-warning">
                                    {t("Name")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="title"
                                    value={item.title}
                                    onChange={(e) => {
                                      changeContentBlock(
                                        index,
                                        "title",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>

                                <div className="input_fill description_fill mt-3">
                                  <label className="text-warning">
                                    {t("Details")}
                                  </label>
                                  <textarea
                                    className="form-control appendvideodetails"
                                    name="details"
                                    value={item.description}
                                    onChange={(e) => {
                                      changeContentBlock(
                                        index,
                                        "description",
                                        e.target.value
                                      );
                                    }}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="delete_vdo removeblock">
                                <Link
                                  to="#"
                                  onClick={() => {
                                    onDeleteContentBlock(index, item._id);
                                  }}
                                >
                                  <span className="material-icons-outlined">
                                    delete
                                  </span>
                                </Link>
                              </div>

                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End sample content Section ========== --> */}

                <div className="course_detailsbx_item">
                  <div className="course_details_hdng">
                    <h4>Additional Info</h4>
                  </div>
                  <div className="profile_fillOuter">
                    {/* <!-- ========== Start author bio Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Authors bio</label>
                      <div className="input_fill description_fill">
                        <textarea
                          className="form-control"
                          value={authorsBio}
                          onChange={(e) => setAuthorsBio(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {/* <!-- ========== End author bio Section ========== --> */}

                    {/* <!-- ========== Start joining message Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Joining message</label>
                      <div className="input_fill description_fill">
                        <textarea
                          className="form-control"
                          value={joiningMessage}
                          onChange={(e) => setJoiningMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {/* <!-- ========== End joining message Section ========== --> */}

                    {/* <!-- ========== Start thanks message Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label>Thanks message</label>
                      <div className="input_fill description_fill">
                        <textarea
                          className="form-control"
                          value={thanksMessage}
                          onChange={(e) => setThanksMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {/* <!-- ========== End thanks message Section ========== --> */}

                    {/* <!-- ========== Start banner Section ========== --> */}
                    <div className="profile_fill_row course_fill_row">
                      <label
                        htmlFor="featuredImage"
                        className="d-block fw-bold text-primary mb-3"
                      >
                        Banner Image
                      </label>

                      <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                        <label
                          htmlFor="logoUpload"
                          className="text-nowrap me-4 text-primary"
                        >
                          Upload Banner
                        </label>

                        <div className="fields-container d-flex flex-fill">
                          <input
                            type="file"
                            id="courselogo_upload"
                            className="form-control border-0 rounded-0 shadow-none"
                            placeholder="JPG, PNG, GIF"
                            name="coursebanner"
                            onChange={courseBannerUploadHandler}
                          />
                        </div>
                      </div>

                      {imageUploading ? (
                        <div className="ms-2 spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : null}

                      {uploadedPreviewImage === "" ? null : (
                        <div className="upload-thumbnail-preview d-flex ms-3 gap-2">
                          <div className="preview position-relative">
                            <figure>
                              <img
                                src={url.SERVER_URL + uploadedPreviewImage}
                                id="courselogo_preview"
                                className="photopreview"
                                height={100}
                              />
                            </figure>

                            <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute top-0 start-0">
                              <Link
                                to="#"
                                className="text-white"
                                onClick={() => {
                                  setUploadedPreviewImage("");
                                  setUploadedImageId(null);
                                }}
                              >
                                <i className="d-block material-icons icon-sm">
                                  cancel
                                </i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <!-- ========== End banner Section ========== --> */}

                    <div className="profile_rightBtn">
                      <button
                        className="basic_btn ylw_btn"
                        onClick={saveCourseHandler}
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                      >
                        {t("Submit")}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                      <Link
                        to="/admin/course/manage"
                        className="basic_btn cancel_btn"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modals  */}
        <EditUploadContentModal
          moduleName="course"
          selectedFileType={selectedFileType}
          uploadedContentFileId={uploadedContentFileId}
          uploadedContentFilePath={uploadedContentFilePath}
          uploadedContents={uploadedContents}
          setuploadedContents={setuploadedContents}
          onModalClose={() => {
            setuploadedContentFileId(null);
            setuploadedContentFilePath("");
          }}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </CourseCommonLayout>
  );
};

export default CourseDetailsBody;
