/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import Loading from "Components/Common/Loading/Loading";
import ViewSubsriberPopup from "Components/Course/Common/Popup/ViewSubsriberPopup";
import UpdateCertificateModal from "./Modal/UpdateCertificateModal";

const CertificationBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [title, settitle] = useState("");
  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [isLoading, setisLoading] = useState(false);
  const [subscriberList, setSubscriberList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedSubscriberIds, setselectedSubscriberIds] = useState([]);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);
  const [selectedSubscriberUserId, setselectedSubscriberUserId] =
    useState(null);

  const [isUpdating, setisUpdating] = useState(false);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: response.data.title },
          {
            title: t("Subscribers"),
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for get all member
  const getAllCourseSubscribers = async () => {
    setisLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_SUBSCRIBERS +
        `?token=${token}&courseid=${courseId}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setisLoading(false);

      if (response.status) {
        setSubscriberList(response.data);
        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
      getAllCourseSubscribers();
    }
  }, [courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("emailpopup_open");
              setSelectedSubscriberId(row.original._id);
            }}
          >
            <span className="d-block material-icons-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: t("Name"),
        size: 300,
        Cell: ({ row }) => (
          <div className="subscriber-profile d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#" className="">
                <img
                  height={35}
                  src={
                    row.original.image == ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.image
                  }
                  className="rounded-circle object-cover object-center thumbimg"
                />
              </Link>
            </div>

            <p className="name">
              <Link to="#" className="text-blue">
                {row.original.name}
              </Link>

              {row.original.moderatorstatus === "0" ? (
                <span
                  className="material-icons-outlined text-warning ms-2"
                  data-bs-toggle="tooltip"
                  title="Pending joining approval"
                >
                  error_outline
                </span>
              ) : null}
            </p>
          </div>
        ),
      },
      {
        accessorKey: "email",
        header: t("Email"),
        size: 200,
      },
      {
        accessorKey: "requestdate",
        header: t("Joined Date"),
        size: 200,
      },
      {
        accessorKey: "coursecertifictedate",
        header: t("Certification Date"),
        size: 200,
      },
      {
        accessorKey: "completionpercentage",
        header: "% " + t("Completed"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <p>{row.original.completionpercentage} %</p>
              <div
                className="progress"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "90px", height: "8px" }}
              >
                <div
                  className={`${
                    row.original.coursestatus === "Completed"
                      ? "bg-success"
                      : "bg-primary"
                  }`}
                  style={{ width: `${row.original.completionpercentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "moderatorstatus",
        header: t("Status"),
        size: 250,
        Cell: ({ row }) => {
          if (row.original.iscertified) {
            return (
              <p className="d-flex align-items-center fw-medium text-success">
                <i className="material-icons me-2">check_circle_outline</i>
                <span>Certified</span>
              </p>
            );
          } else {
            return (
              <div className="d-flex align-items-center gap-2">
                <p className="d-flex align-items-center fw-medium in-progress">
                  <i className="material-icons me-2">access_time</i>
                  <span>In Progress</span>
                </p>
                <button
                  className="btn btn-secondary rounded-0 d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_savequestion"
                  onClick={() => {
                    setSelectedSubscriberId(row.original._id);
                    setselectedSubscriberUserId(row.original.userid);
                  }}
                >
                  {t("Approve")}
                </button>
              </div>
            );
          }
        },
      },
    ],
    [subscriberList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {}, [rowSelection]);

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="certifications" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={subscriberList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 400,
                    size: 100, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Member Name"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {isUpdating && <Loading />}

      {/* --------- alert popup ------------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}

      {/* popup for view subscriber */}
      <ViewSubsriberPopup selectedSubscriberId={selectedSubscriberId} />

      <UpdateCertificateModal
        selectedSubscriberId={selectedSubscriberId}
        selectedSubscriberUserId={selectedSubscriberUserId}
        closeModalHandler={() => {
          setselectedSubscriberUserId(null);
          setSelectedSubscriberId(null);
        }}
        afterModalClose={getAllCourseSubscribers}
      />
    </CourseCommonLayout>
  );
};

export default CertificationBody;
