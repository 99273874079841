import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import CrmCommonHeader from "./CrmCommonHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const CrmCommonLayout = ({
  children,
  breadcrumbText = [],
  moduleName = "",
  displayName = "",
}) => {
  return (
    <>
      <AuthHeader />

      <CrmCommonHeader moduleName={moduleName} />

      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            displayName={displayName}
          />
          {children}
        </div>
      </section>
    </>
  );
};

export default CrmCommonLayout;
