import React from "react";
import { Link } from "react-router-dom";

const FaqListHeader = ({
  refreshHandler = () => {},
  updateBulkHandler = () => {},
  deleteMassiveHandler = () => {},
}) => {
  return (
    <div className="filter-container mb-3">
      <div className="d-flex justify-content-sm-end align-items-center gap-2">
        <button
          type="button"
          className="btn btn-secondary rounded-0 shadow-none"
          onClick={refreshHandler}
        >
          <i className="material-icons">refresh</i>
        </button>

        <button
          type="button"
          className="btn btn-info rounded-0 shadow-none"
          data-bs-toggle="modal"
          data-bs-target="#filterFaqModal"
        >
          <i className="material-icons">filter_alt</i>
        </button>

        <div className="dropdown">
          <button
            className="btn btn-info rounded-0 shadow-none dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
            <li>
              <Link
                to="#"
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#addTagModal"
              >
                Add/Remove Label
              </Link>
            </li>

            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => {
                  updateBulkHandler("approve");
                }}
              >
                Approve to publish
              </Link>
            </li>

            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => {
                  updateBulkHandler("rank");
                }}
              >
                Update Ranking
              </Link>
            </li>

            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={deleteMassiveHandler}
              >
                Delete Selected
              </Link>
            </li>

            <li>
              <Link
                to="#"
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#uploadCsvModal"
              >
                Upload XLS/CSV
              </Link>
            </li>
          </ul>
        </div>

        <button
          type="button"
          className="btn btn-secondary rounded-0 shadow-none"
          data-bs-toggle="modal"
          data-bs-target="#saveFaqModal"
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default FaqListHeader;
