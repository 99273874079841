/* eslint-disable */
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import userStatusOptions from "data/Prod/UserStatusOption.json";
import { reactSelectStyle } from "Config/Config";

const UserPasswordBody = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App user"), link: "/admin/contact/allusers" },
    { title: t("Change Password") },
  ];

  const params = useParams();

  const [roles, setRoles] = useState([]);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [statusValue, setstatusValue] = useState(null);
  const [status, setstatus] = useState("");
  const [roleValue, setroleValue] = useState(null);
  const [roleId, setroleId] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_ROLES +
        `?token=${token}&filterfeatured=true`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setRoles(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const validationHandler = () => {
    let isValid = true;

    if (email !== "") {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!email.match(mailformat)) {
        setMessageType("error");
        setAlertMessage(t("Please enter valid email address"));

        isValid = false;
      }
    }

    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        setMessageType("error");
        setAlertMessage(t("Password and Confirm Password must be same"));

        isValid = false;
      }
    }

    return isValid;
  };

  //function for get user details
  const getUserProfileDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        const data = response.data;

        if (data) {
          setemail(data.email || "");

          const [statusData] = userStatusOptions.filter(
            (item) => item.value === data.status
          );

          statusSelectionHandler(statusData || null);
          roleSelectionHandler(data.userrolevalue || null);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for select status
  const statusSelectionHandler = (val) => {
    if (val) {
      setstatusValue(val);
      setstatus(val.value);
    } else {
      setstatusValue(null);
      setstatus("");
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    if (val) {
      setroleValue(val);
      setroleId(val.value);
    } else {
      setroleValue(null);
      setroleId(null);
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);
        let userData = {
          email,
          status,
          role: roleId,
        };

        if (
          password !== "" &&
          confirmPassword !== "" &&
          password === confirmPassword
        ) {
          userData.password = password;
        }

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestURL =
            requestURL +
            url.API_UPDATE_APP_USER +
            `/${params.id}` +
            `?token=${token}`;

          response = await putData(requestURL, userData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getAllRoles();
      getUserProfileDetails();
    }
  }, [params.id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <AppUserDetailsLayout moduleName="password" breadcrumbText={breadcrumbText}>
      <div className="interest_hdng">
        <h4>{t("Change Password")}</h4>
        <div className="mail_text">
          <label>{t("Email")} : </label>
          <Link to="#" className="ms-2">
            {email}
          </Link>
        </div>
      </div>

      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="profile_fillOuter wd_260">
            <div className="profile_fill_row">
              <label>{t("Email")}</label>
              <div className="input_fill">
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("Email")}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="profile_fill_row">
              <label>New Password</label>
              <div className="input_fill">
                <input
                  type="password"
                  placeholder="Enter new passowrd"
                  className="form-control"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Confirm Password</label>
              <div className="input_fill">
                <input
                  type="password"
                  placeholder="Re-enter new password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Status</label>
              <div className="input_fill mt-1">
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Status")}
                  options={userStatusOptions}
                  value={statusValue}
                  onChange={(val) => statusSelectionHandler(val)}
                />
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Role</label>
              <div className="input_fill mt-1">
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isClearable
                  options={roles}
                  value={roleValue}
                  onChange={(val) => roleSelectionHandler(val)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prfile_btnOuter">
        <div className="profile_leftBtn"></div>
        <div className="profile_rightBtn">
          <button
            className="basic_btn ylw_btn"
            onClick={saveUserHandler}
            disabled={isSaving ? true : false}
            style={{
              cursor: isSaving ? "not-allowed" : "pointer",
            }}
          >
            {t("Save")}
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </AppUserDetailsLayout>
  );
};

export default UserPasswordBody;
