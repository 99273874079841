/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import ChapterHeader from "./Header/ChapterHeader";
import SaveChapterPopup from "./Popup/SaveChapterPopup";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const CourseChapterBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);
  const [title, settitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [courseChapters, setcourseChapters] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedChapterIds, setselectedChapterIds] = useState([]);
  const [selectedChapterId, setselectedChapterId] = useState(null);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: response.data.title },
          {
            title: t("Chapters"),
          },
          {
            title: "",
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //get course list
  const getAllCoursesChapters = async () => {
    setRowSelection({});
    setselectedChapterIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_CHAPTERS +
        `?token=${token}&courseid=${courseId}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setcourseChapters(response.data);
      } else {
        setAlertMessage("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for delete course
  const deleteMassiveChapterHandler = async () => {
    if (selectedChapterIds.length > 0) {
      const courseChapterListToBeDelete = courseChapters.filter((item) =>
        selectedChapterIds.includes(item._id)
      );

      try {
        let courseData = {
          chapterlist: courseChapterListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_COURSE_CHAPTERS +
          `?token=${token}`;

        const response = await putData(requestURL, courseData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllCoursesChapters();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one chapter");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
      getAllCoursesChapters();
    } else {
      setBreadCrumbText([
        { title: t("Course"), link: "/admin/course/manage" },
        { title: t("Save") },
        { title: "" },
      ]);
    }
  }, [courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              setselectedChapterId(row.original._id);
              document.body.classList.toggle("emailpopup_open");
            }}
          >
            <i className="material-icons horz_icon">more_horiz</i>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Title"),
        size: 800,
      },
      {
        accessorKey: "lessoncount",
        header: t("Lessons"),
        size: 800,
      },
      {
        accessorKey: "createddate",
        header: t("Date"),
        size: 200,
      },
    ],
    []
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setselectedChapterIds(selectedIdsArray);
    } else {
      setselectedChapterIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="chapters" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <ChapterHeader
                deleteMassiveChapterHandler={deleteMassiveChapterHandler}
              />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={courseChapters} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 400,
                    size: 100, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Chapter Name"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <SaveChapterPopup
          courseTitle={title}
          selectedChapterId={selectedChapterId}
          setSelectedChapterId={setselectedChapterId}
          afterPopupClose={getAllCoursesChapters}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </CourseCommonLayout>
  );
};

export default CourseChapterBody;
