/* eslint-disable */
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserPrivacyBody = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const params = useParams();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App user"), link: "/admin/contact/allusers" },
    { title: t("Privacy") },
  ];

  const [shareLookingFor, setshareLookingFor] = useState("");
  const [shareAccountValue, setShareAccountValue] = useState("");
  const [shareContactValue, setshareContactValue] = useState("");
  const [shareMailValue, setshareMailValue] = useState("");
  const [sharePhoneValue, setsharePhoneValue] = useState("");
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get user details
  const getUserProfileDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        const data = response.data;

        if (data) {
          if (data.sharelookingfor) {
            setshareLookingFor(data.sharelookingfor);
          }
          if (data.shareaccount) {
            setShareAccountValue(data.shareaccount);
          }
          if (data.sharecontacts) {
            setshareContactValue(data.sharecontacts);
          }
          if (data.sharemail) {
            setshareMailValue(data.sharemail);
          }
          if (data.sharephone) {
            setsharePhoneValue(data.sharephone);
          }
          if (data.subscribecommercialmail) {
            setSubscribeMailStatus(data.subscribecommercialmail);
          }
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for change radio button value
  const radioChangeHandler = (e) => {
    if (e.target.name === "lookingFor1") {
      setshareLookingFor("1");
    }
    if (e.target.name === "lookingFor2") {
      setshareLookingFor("2");
    }

    if (e.target.name === "sharemail1") {
      setshareMailValue("1");
    }

    if (e.target.name === "sharemail2") {
      setshareMailValue("2");
    }

    if (e.target.name === "sharePhone1") {
      setsharePhoneValue("1");
    }

    if (e.target.name === "sharePhone2") {
      setsharePhoneValue("2");
    }

    if (e.target.name === "shareContact1") {
      setshareContactValue("1");
    }

    if (e.target.name === "shareContact2") {
      setshareContactValue("2");
    }

    if (e.target.name === "subscribeMail1") {
      setSubscribeMailStatus("1");
    }

    if (e.target.name === "subscribeMail2") {
      setSubscribeMailStatus("0");
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    try {
      setIsSaving(true);
      let userData = {
        sharelookingfor: shareLookingFor,
        sharecontacts: shareContactValue,
        sharephone: sharePhoneValue,
        sharemail: shareMailValue,
        subscribecommercialmail: subscribeMailStatus,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestURL =
          requestURL +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}`;

        response = await putData(requestURL, userData);
      }

      setIsSaving(false);
      console.log(response);

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Privacy updated Successfully"));
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getUserProfileDetails();
    }
  }, [params.id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <AppUserDetailsLayout moduleName="privacy" breadcrumbText={breadcrumbText}>
      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="interest_hdng">
            <h4>Privacy</h4>
          </div>
          <div className="profile_fillOuter wd_360">
            <div className="profile_fill_row">
              <label>Who can see what you are looking for</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="lookingFor1"
                        checked={shareLookingFor === "1" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>My Contacts</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="lookingFor2"
                        checked={shareLookingFor === "2" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>Everyone</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Who can see your mail</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="sharemail1"
                        checked={shareMailValue === "1" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>My Contacts</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="sharemail2"
                        checked={shareMailValue === "2" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>Everyone</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Who can see your phone</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="sharePhone1"
                        checked={sharePhoneValue === "1" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>My Contacts</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="sharePhone2"
                        checked={sharePhoneValue === "2" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>Everyone</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Who can see your contacts</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="shareContact1"
                        checked={shareContactValue === "1" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>My Contacts</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="shareContact2"
                        checked={shareContactValue === "2" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>Everyone</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="profile_fill_row">
              <label>Subscribe to commercial emails</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="subscribeMail1"
                        checked={subscribeMailStatus === "1" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>Yes</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input
                        type="radio"
                        name="subscribeMail2"
                        checked={subscribeMailStatus === "0" ? true : false}
                        onChange={radioChangeHandler}
                      />
                      <span></span>
                      <p>No</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="interest_hdng my-5">
            <h4>When you should receive notification</h4>
          </div>
          <div className="profile_fillOuter wd_360 mt-4">
            <div className="profile_fill_row">
              <label>On connect request</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>Yes</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>No</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="profile_fill_row">
              <label>On new email in inbox</label>
              <div className="input_fill mt-3">
                <ul className="profile_chkList">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>Yes</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>No</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="prfile_btnOuter">
        <div className="profile_leftBtn"></div>
        <div
          className="profile_rightBtn"
          onClick={saveUserHandler}
          disabled={isSaving ? true : false}
          style={{
            cursor: isSaving ? "not-allowed" : "pointer",
          }}
        >
          <button className="basic_btn ylw_btn">
            {t("Save Settings")}
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </AppUserDetailsLayout>
  );
};

export default UserPrivacyBody;
