/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { GlobalProvider } from "Context/GlobalContext";

import { getAllTagsByUser } from "Helper/CommonHelper/CommonHelper";

const ConversationFilterModal = ({
  resetFilterState = false,
  setresetFilterState = () => {},
  setfilterMailType = () => {},
  setfilterPersonQuery = () => {},
  setfilterSubject = () => {},
  setfilterTags = () => {},
  setfilterStartDate = () => {},
  setfilterEndDate = () => {},
}) => {
  const { tagListReload, settagListReload } = useContext(GlobalProvider);

  const { t } = useTranslation();

  const mailTypeOptions = [
    {
      value: "0",
      label: "All",
    },
    {
      value: "1",
      label: "Read",
    },
    {
      value: "2",
      label: "Unread",
    },
  ];

  const [tagList, settagList] = useState([]);

  const [mailTypeValue, setmailTypeValue] = useState(null);
  const [mailType, setmailType] = useState("");
  const [mailPersonQuery, setmailPersonQuery] = useState("");
  const [mailSubject, setmailSubject] = useState("");
  const [mailTagValue, setmailTagValue] = useState(null);
  const [mailTags, setmailTags] = useState([]);
  const [mailStartDate, setmailStartDate] = useState("");
  const [mailEndDate, setmailEndDate] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (mailStartDate !== "" && mailEndDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  const getTags = async () => {
    const tagListResult = await getAllTagsByUser();
    settagList(tagListResult);
  };

  //function for select mail type
  const mailTypeSelectionHandler = (val) => {
    setmailTypeValue(val);
    setmailType(val.value);
  };

  //function for select tags
  const mailTagSelectionHandler = (val) => {
    setmailTagValue(val);
    setmailTags(val.map((item) => item.value));
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      setfilterMailType(mailType);
      setfilterPersonQuery(mailPersonQuery);
      setfilterSubject(mailSubject);
      setfilterTags(mailTags);
      setfilterStartDate(mailStartDate);
      setfilterEndDate(mailEndDate);

      const bootstrapModal = document.querySelector("#filterConversationModal");
      const modal = bootstrap.Modal.getInstance(bootstrapModal);
      modal.hide();
    }
  };

  //function for reset filter
  const resetFilter = () => {
    mailTypeSelectionHandler(mailTypeOptions[0]);
    setmailPersonQuery("");
    setmailSubject("");
    setmailTagValue(null);
    setmailTags([]);
    setmailStartDate("");
    setmailEndDate("");
    setValidation({ dateWarning: false });
  };

  useEffect(() => {
    if (resetFilterState) {
      resetFilter();
      setresetFilterState(false);
    }
  }, [resetFilterState]);

  useEffect(() => {
    if (tagListReload) {
      getTags();
      settagListReload(false);
    }
  }, [tagListReload]);

  useEffect(() => {
    getTags();
    mailTypeSelectionHandler(mailTypeOptions[0]);
  }, []);

  return (
    <div
      className="modal fade"
      id="filterConversationModal"
      aria-labelledby="filterConversationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green w-100 mb-2" id="filterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <div className="form-row mb-3">
              <label htmlFor="status" className="fw-bold mb-2">
                Search
              </label>

              <Select
                options={mailTypeOptions}
                value={mailTypeValue}
                onChange={(val) => mailTypeSelectionHandler(val)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="sentto" className="fw-bold mb-2">
                To (name / email)
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control text-green rounded-0"
                value={mailPersonQuery}
                onChange={(e) => setmailPersonQuery(e.target.value)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="subject" className="fw-bold mb-2">
                Query
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control text-green rounded-0"
                value={mailSubject}
                onChange={(e) => setmailSubject(e.target.value)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="tags" className="fw-bold mb-2">
                Labels
              </label>
              <Select
                isMulti
                placeholder={t("Select tags")}
                options={tagList}
                value={mailTagValue}
                onChange={(val) => mailTagSelectionHandler(val)}
              />
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="startDate" className="fw-bold mb-2">
                  From Date
                </label>
                <input
                  type="date"
                  name="fromdate"
                  id="startDate"
                  className="form-control text-green rounded-0"
                  value={mailStartDate}
                  onChange={(e) => {
                    setmailStartDate(e.target.value);
                    setValidation({ dateWarning: false });
                  }}
                />
              </div>
              <div className="col-6">
                <label htmlFor="endDate" className="fw-bold mb-2">
                  To Date
                </label>
                <input
                  type="date"
                  name="todate"
                  id="endDate"
                  className="form-control text-green rounded-0"
                  value={mailEndDate}
                  onChange={(e) => {
                    setmailEndDate(e.target.value);
                    setValidation({ dateWarning: false });
                  }}
                />
              </div>
            </div>
            {validation.dateWarning && (
              <p className="mt-3 d-flex align-items-center gap-1 text-danger">
                <span className="material-icons-outlined">warning</span>
                <span>{t("Please enter end date")}!</span>
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationFilterModal;
