import React from "react";

const Loading = () => {
  return (
    <div id="preloader">
      <div id="status">
        <span>
          <img src="/images/loader-logo.svg" alt="Indra" />
        </span>
        <div className="dots-outer">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
