/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import LessonHeader from "./Header/LessonHeader";
import SaveLessonPopup from "./Popup/SaveLessonPopup";
import EditLessonContentModal from "./Modal/EditLessonContentModal";

const LessonListBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [title, settitle] = useState("");
  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [isLoading, setIsLoading] = useState(false);
  const [lessonList, setlessonList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedLessonIds, setselectedLessonIds] = useState([]);
  const [selectedLessonId, setselectedLessonId] = useState(null);

  //requirements for upload content modal
  const [selectedFileType, setSelectedFileType] = useState("");

  const [uploadedContentFileId, setuploadedContentFileId] = useState(null);
  const [uploadedContentFilePath, setuploadedContentFilePath] = useState("");
  const [uploadedContents, setuploadedContents] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: response.data.title },
          {
            title: t("Lessons"),
          },
          {
            title: "",
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //get course list
  const getAllCourseLessons = async () => {
    setRowSelection({});
    setselectedLessonIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSONS +
        `?token=${token}&courseid=${courseId}`;

      const response = await getData(requestURL);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setlessonList(response.data);
      } else {
        setAlertMessage("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for delete lesson
  const deleteMassiveLessonHandler = async () => {
    if (selectedLessonIds.length > 0) {
      const listToBeDelete = lessonList.filter((item) =>
        selectedLessonIds.includes(item._id)
      );

      try {
        let apiData = {
          lessonlist: listToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_COURSE_LESSONS +
          `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllCourseLessons();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...lessonList];

    const rowIndex = updatedList.findIndex(
      (lesson) => lesson._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].rank = value;
      setlessonList(updatedList);
    }
  };

  //function for update rank
  const updateBulkLessonHandler = async (e) => {
    if (selectedLessonIds.length > 0) {
      try {
        const listToBeUpdate = lessonList.filter((item) =>
          selectedLessonIds.includes(item._id)
        );

        let apiData = {
          lessonlist: listToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_COURSE_LESSONS +
          `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllCourseLessons();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one lesson");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
      getAllCourseLessons();
    }
  }, [courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("emailpopup_open");
              setselectedLessonId(row.original._id);
            }}
          >
            <i className="material-icons horz_icon">more_horiz</i>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Name"),
        size: 400,
      },
      {
        accessorKey: "chaptername",
        header: t("Chapter"),
        size: 400,
      },
      {
        accessorKey: "duration",
        header: t("Duration Min."),
        size: 600,
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        size: 100,
        Cell: ({ row }) => (
          <div>
            <input
              type="number"
              className="form-control rounded-0 shadow-none bg-white"
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />
          </div>
        ),
      },
      {
        accessorKey: "taskcount",
        header: t("Assignments"),
        size: 200,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center w-25">
            <Link
              to={`/admin/course/lessons/tasks/manage/${courseId}/${row.original._id}`}
              className="text-primary d-flex align-items-center"
            >
              <span>{row.original.taskcount}</span>
              <i className="material-icons ms-2 fs-5" id="module-single-task">
                open_in_new
              </i>
            </Link>
          </div>
        ),
      },
    ],
    [lessonList, i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setselectedLessonIds(selectedIdsArray);
    } else {
      setselectedLessonIds([]);
    }
  }, [rowSelection]);

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="lessons" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <LessonHeader
                updateBulkLessonHandler={updateBulkLessonHandler}
                deleteMassiveLessonHandler={deleteMassiveLessonHandler}
              />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={lessonList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Lesson Name"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <SaveLessonPopup
        courseTitle={title}
        selectedFileType={selectedFileType}
        setSelectedFileType={setSelectedFileType}
        setuploadedContentFileId={setuploadedContentFileId}
        setuploadedContentFilePath={setuploadedContentFilePath}
        uploadedContents={uploadedContents}
        setuploadedContents={setuploadedContents}
        selectedLessonId={selectedLessonId}
        setselectedLessonId={setselectedLessonId}
        afterPopupClose={getAllCourseLessons}
      />

      <EditLessonContentModal
        selectedFileType={selectedFileType}
        uploadedContentFileId={uploadedContentFileId}
        uploadedContentFilePath={uploadedContentFilePath}
        uploadedContents={uploadedContents}
        setuploadedContents={setuploadedContents}
        onModalClose={() => {
          setuploadedContentFileId(null);
          setuploadedContentFilePath("");
        }}
      />

      {/* --------- alert popup ------------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CourseCommonLayout>
  );
};

export default LessonListBody;
