import React from "react";
import { Link } from "react-router-dom";

const CourseDetailsBreadcrumb = ({ courseName = "", breadCrumbText = [] }) => {
  return (
    <div className="bgbx_pagination">
      <ol className="breadcrumb mb-0">
        {breadCrumbText.map((item, index) => {
          if (item.title !== "") {
            return (
              <li
                key={index}
                className={`breadcrumb-item ${
                  index === breadCrumbText.length - 1 && "active"
                } `}
              >
                {index === breadCrumbText.length - 1 ? (
                  <span>{item.title}</span>
                ) : (
                  <Link to={item.link ? item.link : "#"} className="text-blue">
                    {item.title}
                  </Link>
                )}
              </li>
            );
          } else {
            return null;
          }
        })}
      </ol>
    </div>
  );
};

export default CourseDetailsBreadcrumb;
