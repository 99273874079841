/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

const SaveListModal = ({
  afterSave = () => {},
  listId = null,
  resetListId,
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [details, setdetails] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //validation
  const validationHandler = () => {
    let isValid = true;
    if (title === "") {
      setShowAlert(true);
      setAlertMessage(t("Please enter title"));
      setMessageType("error");
      isValid = false;
    }

    if (slug === "") {
      setShowAlert(true);
      setAlertMessage(t("Please enter slug"));
      setMessageType("error");
      isValid = false;
    }

    if (details === "") {
      setShowAlert(true);
      setAlertMessage(t("Please enter details"));
      setMessageType("error");
      isValid = false;
    }

    return isValid;
  };

  //get details of list
  const getDetailsOfList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_LIST_DETAILS + `/${listId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setTitle(response.data.title);
        setSlug(response.data.slug);
        setdetails(response.data.details);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //save function
  const saveListHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let listData = {
          title,
          details,
          slug,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (listId) {
          requestURL += url.API_UPDATE_A_LIST + `/${listId}?token=${token}`;
          response = await putData(requestURL, listData);
        } else {
          requestURL += url.API_CREATE_LIST + `?token=${token}`;
          response = await postData(requestURL, listData);
        }

        setIsSaving(false);

        if (response.status) {
          // close the modal
          let currentModal = document.querySelector("#saveListModal");
          let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
          bootstrapModal.hide();

          setMessageType("success");
          resetHandler();
          afterSave();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }

      setShowAlert(true);
    }
  };

  const resetHandler = () => {
    setTitle("");
    setSlug("");
    setdetails("");
    resetListId();
  };

  useEffect(() => {
    if (listId) {
      getDetailsOfList();
    }
  }, [listId]);

  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="saveListModal"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>
            <div className="modal-body edit_modalbody">
              <div className="srvay_feedhdng">
                <h2>Save List</h2>
              </div>

              <div className="edit_fillOuter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Title *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>Slug</label>
                      <input
                        type="text"
                        className="form-control"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-row mb-3">
                    <label
                      htmlFor="description"
                      className="text-primary fw-bold mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      cols="30"
                      rows="5"
                      className="form-control text-green rounded-0"
                      value={details}
                      onChange={(e) => setdetails(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="col-md-12">
                    <div className="submit_survay">
                      <button
                        type="button"
                        className="basic_btn ylw_btn"
                        style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                        disabled={isSaving ? true : false}
                        onClick={saveListHandler}
                      >
                        {t("Save")}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveListModal;
