import React from "react";

const SaveModuleActivityPointModal = () => {
  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="save_module_points"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>
            <div className="modal-body edit_modalbody">
              <div className="srvay_feedhdng">
                <h2>Save Module Action Points</h2>
              </div>

              <form method="post" id="savequestion">
                <div className="edit_fillOuter">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input_fill white_fill">
                        <label>Category *</label>
                        <div className="model_selectBx">
                          <select
                            className="form-control select2 select2-hidden-accessible"
                            name="category"
                            id="formPointCategory"
                            required=""
                            data-select2-id="select2-data-formPointCategory"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option
                              value="PtoP"
                              data-select2-id="select2-data-2-4wwx"
                            >
                              {" "}
                              Valuation by Participation (PtoP){" "}
                            </option>
                            <option value="PtoE">
                              {" "}
                              Valuation by Experience (PtoE)
                            </option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id="select2-data-1-0wf9"
                            style={{ width: "auto" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabindex="0"
                                aria-disabled="false"
                                aria-labelledby="select2-formPointCategory-container"
                                aria-controls="select2-formPointCategory-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-formPointCategory-container"
                                  role="textbox"
                                  aria-readonly="true"
                                ></span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span
                              className="dropdown-wrapper"
                              aria-hidden="true"
                            ></span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input_fill white_fill">
                        <label>Module *</label>
                        <div className="model_selectBx">
                          <select
                            className="select2 select2-hidden-accessible"
                            name="module"
                            id="formPointModule"
                            required=""
                            data-select2-id="select2-data-formPointModule"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option
                              value="MOD_CHALLANGES"
                              data-select2-id="select2-data-4-5h26"
                            >
                              Challenges
                            </option>
                            <option value="MOD_ACTIVITY">Forum</option>
                            <option value="MOD_EVENT">Event</option>
                            <option value="MOD_USER">Usuario</option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id="select2-data-3-92ls"
                            style={{ width: "auto" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabindex="0"
                                aria-disabled="false"
                                aria-labelledby="select2-formPointModule-container"
                                aria-controls="select2-formPointModule-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-formPointModule-container"
                                  role="textbox"
                                  aria-readonly="true"
                                ></span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span
                              className="dropdown-wrapper"
                              aria-hidden="true"
                            ></span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input_fill white_fill">
                        <label>Module Component</label>
                        <select
                          className="form-control select2-hidden-accessible"
                          name="component"
                          id="formPointComponent"
                          tabindex="-1"
                          aria-hidden="true"
                          data-select2-id="select2-data-formPointComponent"
                        ></select>
                        <span
                          className="select2 select2-container select2-container--default"
                          dir="ltr"
                          data-select2-id="select2-data-9-b7rb"
                          style={{ width: "auto" }}
                        >
                          <span className="selection">
                            <span
                              className="select2-selection select2-selection--single"
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tabindex="0"
                              aria-disabled="false"
                              aria-labelledby="select2-formPointComponent-container"
                              aria-controls="select2-formPointComponent-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-formPointComponent-container"
                                role="textbox"
                                aria-readonly="true"
                              ></span>
                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation"></b>
                              </span>
                            </span>
                          </span>
                          <span
                            className="dropdown-wrapper"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input_fill white_fill">
                        <label>Action *</label>
                        <select
                          className="select2 select2-hidden-accessible"
                          name="action"
                          id="formModuleAction"
                          required=""
                          tabindex="-1"
                          aria-hidden="true"
                          data-select2-id="select2-data-formModuleAction"
                        ></select>
                        <span
                          className="select2 select2-container select2-container--default"
                          dir="ltr"
                          data-select2-id="select2-data-8-tdxq"
                          style={{ width: "auto" }}
                        >
                          <span className="selection">
                            <span
                              className="select2-selection select2-selection--single"
                              role="combobox"
                              aria-haspopup="true"
                              aria-expanded="false"
                              tabindex="0"
                              aria-disabled="false"
                              aria-labelledby="select2-formModuleAction-container"
                              aria-controls="select2-formModuleAction-container"
                            >
                              <span
                                className="select2-selection__rendered"
                                id="select2-formModuleAction-container"
                                role="textbox"
                                aria-readonly="true"
                              ></span>
                              <span
                                className="select2-selection__arrow"
                                role="presentation"
                              >
                                <b role="presentation"></b>
                              </span>
                            </span>
                          </span>
                          <span
                            className="dropdown-wrapper"
                            aria-hidden="true"
                          ></span>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input_fill white_fill">
                        <label>Points</label>
                        <input
                          type="number"
                          name="point"
                          className="form-control"
                          placeholder="Points"
                          id="formActionPoint"
                          required=""
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit_survay">
                        <input type="hidden" name="epid" id="formPointId" />
                        <input
                          type="hidden"
                          name="componentlabel"
                          id="formComponentLabel"
                        />
                        <button type="submit" className="basic_btn ylw_btn">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModuleActivityPointModal;
