/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import EventDetailsCommonHeader from "../Common/EventDetailsCommonHeader";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

const EventInfoBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: eventId } = useParams();
  const history = useHistory();

  const editor = useRef(null);

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Event"), link: "/admin/event/manage" },
    { title: "" },
  ]);

  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [moduleRoleList, setModuleRoleList] = useState([]);

  const [eventTitle, seteventTitle] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [eventSummary, seteventSummary] = useState("");
  const [eventDetails, seteventDetails] = useState("");
  const [location, setlocation] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [virtualUrl, setvirtualUrl] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [searchingValue, setsearchingValue] = useState(null);
  const [searchingData, setsearchingData] = useState([]);
  const [interestValue, setinterestValue] = useState(null);
  const [interestData, setinterestData] = useState([]);
  const [groupValue, setgroupValue] = useState(null);
  const [selectedGroups, setselectedGroups] = useState([]);
  const [defualtRoleValue, setdefualtRoleValue] = useState(null);
  const [selectedRole, setselectedRole] = useState(null);
  const [eventWebsite, seteventWebsite] = useState("");
  const [autoAccept, setautoAccept] = useState(false);
  const [isPrivate, setisPrivate] = useState(false);

  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const [removedGroups, setRemovedGroups] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    zipcodeWarning: false,
    startDateWarning: false,
    endDateWarning: false,
    dateDiffWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    timeDiffWarning: false,
    categoryWarning: false,
  });

  const descriptionEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  seteventDetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const descriptionEditorConfig = useMemo(
    () => descriptionEditorCustomConfig,
    []
  );

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (eventTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (zipcode == "") {
      setValidation((prevState) => ({ ...prevState, zipcodeWarning: true }));
      isValid = false;
    }

    if (startDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      isValid = false;
    }

    if (endDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        endDateWarning: true,
      }));
      isValid = false;
    }

    if (startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateDiffWarning: true,
        }));
        isValid = false;
      }
    }

    if (!categoryValue) {
      setValidation((prevState) => ({
        ...prevState,
        categoryWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "CAT_EVENT";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all searching
  const getAllSearchingList = async () => {
    try {
      const parentSlug = "CAT_SEARCHING";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setsearchingList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all interest
  const getAllInterestList = async () => {
    try {
      const parentSlug = "CAT_INTEREST";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setinterestList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllModuleRoles = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLE +
        `?token=${token}&slug=MOD_EVENT`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setModuleRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all groups
  const getAllGroupList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_GROUPS +
        `?token=${token}&modulefrom=backend`;

      const response = await getData(requestURL);

      if (response.status) {
        setGroupList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select categroy
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    setcategoryData(val.map((item) => item.value));
  };

  //select searchings
  const searchingSelectionHandler = (val) => {
    setsearchingValue(val);
    setsearchingData(val.map((item) => item.value));
  };

  //select interest
  const interestSelectionHandler = (val) => {
    setinterestValue(val);
    setinterestData(val.map((item) => item.value));
  };

  //select groups
  const groupSelectionHandler = (val) => {
    const newSelectedGroupValues = val.map((item) => item.value);

    // Find the removed items by comparing previous selectedGroups with the new selected values
    const removed = selectedGroups.filter(
      (item) => !newSelectedGroupValues.includes(item)
    );

    // Update removed items state
    if (removed.length > 0) {
      setRemovedGroups((prevRemoved) => [...prevRemoved, ...removed]);
    }

    setgroupValue(val);
    setselectedGroups(newSelectedGroupValues);
  };

  //role select
  const roleSelectionHandler = (val) => {
    if (val) {
      setdefualtRoleValue(val);
      setselectedRole(val.value);
    } else {
      setdefualtRoleValue(null);
      setselectedRole(null);
    }
  };

  //upload banner
  const bannerUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setIsUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setUploadedImageId(response.data._id);
        setUploadedPreviewImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("logo_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${eventId}?token=${token}`;

      console.log("url of event details------>", requestURL);

      const response = await getData(requestURL);

      console.log("response of event details------>", response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Event"), link: "/admin/event/manage" },
          { title: response.data.title },
        ]);

        //assign title
        response.data?.title && seteventTitle(response.data?.title);

        //assign image
        response.data?.eventlogoid &&
          setUploadedImageId(response.data?.eventlogoid);

        setUploadedPreviewImage(response.data?.eventlogoimage);

        //assing summary
        seteventSummary(response.data?.summary);

        //assign details
        seteventDetails(response.data?.description);

        //assign meet url
        setvirtualUrl(response.data?.meeturl);

        //assign zipcode
        setzipcode(response.data?.zipcode);

        //assign city
        setcityName(response.data?.city);

        //assign country
        setcountry(response.data?.country);

        //assign location
        setlocation(response.data?.address);

        //assign start date
        setstartDate(response.data?.fromdate);

        //assign end date
        setendDate(response.data?.todate);

        //assign website
        seteventWebsite(response.data?.website);

        //assign category
        if (response.data?.categories && response.data?.categories.length > 0) {
          categorySelectionHandler(response.data?.categories);
        }

        //assign searchings
        if (response.data?.searchings && response.data?.searchings.length > 0) {
          searchingSelectionHandler(response.data?.searchings);
        }

        //assign interests
        if (response.data?.interests && response.data?.interests.length > 0) {
          interestSelectionHandler(response.data?.interests);
        }

        //assing auto accept
        setautoAccept(response.data?.autoapproval);

        //assign private
        setisPrivate(response?.data?.isprivate);

        //assing groups
        if (response.data?.grouplist && response.data?.grouplist.length > 0) {
          groupSelectionHandler(response.data.grouplist);
        }

        //assing default role
        if (response.data.defaultrolevalue) {
          roleSelectionHandler(response.data.defaultrolevalue);
        }
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save event
  const saveUpdateEventHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      seterrorMessage("");

      try {
        let eventData = {
          fromdate: startDate,
          todate: endDate,
          title: eventTitle,
          slug: eventTitle.toUpperCase().replace(/ /g, "_"),
          summary: eventSummary,
          description: eventDetails,
          address: location,
          city: cityName,
          zipcode: zipcode,
          country: country,
          website: eventWebsite,
          meeturl: virtualUrl,
          logoid: uploadedImageId,
          categories: categoryData,
          searchings: searchingData,
          interests: interestData,
          autoapproval: autoAccept,
          isprivate: isPrivate,
          groupids: selectedGroups,
          memberdefaultrole: selectedRole,
          deletedgroups: removedGroups,
        };

        let requestURL = url.API_BASE_URL;

        console.log(eventData);

        let response = {};

        if (eventId) {
          requestURL =
            requestURL +
            url.API_UPDATE_EVENT +
            `/${eventId}` +
            `?token=${token}`;
          response = await putData(requestURL, eventData);
        } else {
          requestURL = requestURL + url.API_CREATE_EVENT + `?token=${token}`;
          response = await postData(requestURL, eventData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            history.push(`/admin/event/update/${response.data._id}`);
          }, 1200);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up required fields"));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllCategoryList();
    getAllSearchingList();
    getAllInterestList();
    getAllGroupList();
    getAllModuleRoles();
  }, []);

  useEffect(() => {
    if (eventId) {
      getEventDetails();
    } else {
      setBreadCrumbText([
        { title: t("Events"), link: "/admin/event/manage" },
        { title: "" },
      ]);
    }
  }, [eventId]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {eventId ? <EventDetailsCommonHeader moduleName="details" /> : null}

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="event_info_form_wrapper" className="p-4 bg-gray-100">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* name  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="title"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 shadow-none"
                  placeholder=""
                  value={eventTitle}
                  onChange={(e) => {
                    seteventTitle(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      titlewarning: false,
                    }));
                  }}
                />
                {validation.titleWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter title")}!</span>
                    </p>
                  </div>
                )}
              </div>

              {/* date  */}
              <div className="row col-12 mb-4 mx-0 px-0">
                <div className="col-sm-6 ps-0 pe-2">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Type
                  </label>
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-success mb-3"
                  >
                    {" "}
                    Networking{" "}
                  </label>{" "}
                </div>

                <div className="col-sm-3 px-0 pe-1">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="event_fromdate"
                    className="form-control rounded-0 shadow-none"
                    value={startDate}
                    onChange={(e) => {
                      setstartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        startDateWarning: false,
                        dateDiffWarning: false,
                      }));
                    }}
                  />
                </div>

                <div className="col-sm-3 px-0">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    End Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="event_todate"
                    className="form-control rounded-0 shadow-none"
                    value={endDate}
                    onChange={(e) => {
                      setendDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        endDateWarning: false,
                        dateDiffWarning: false,
                      }));
                    }}
                  />
                </div>
              </div>

              {/* summary  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="summary"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Summary
                </label>
                <textarea
                  id="Event_summary"
                  name="Event[summary]"
                  className="form-control rounded-0 shadow-none"
                  value={eventSummary}
                  onChange={(e) => seteventSummary(e.target.value)}
                ></textarea>
              </div>

              {/* details  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="groupSummary"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Details
                </label>

                <JoditEditor
                  ref={editor}
                  value={eventDetails}
                  config={descriptionEditorConfig}
                  onBlur={(newContent) => {
                    seteventDetails(newContent);
                  }}
                  onChange={(newContent, event) => {}}
                />
              </div>

              {/* address, cuty,zipce  */}
              <div className="row col-12 mb-4 mx-0 px-0">
                <div className="col-sm-4 ps-0 pe-2">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="Event_address"
                    name="Event[address]"
                    className="form-control rounded-0 shadow-none"
                    placeholder="address"
                    value={location}
                    onChange={(e) => setlocation(e.target.value)}
                  />
                </div>

                <div className="col-sm-3 ps-0 pe-2">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    City/State
                  </label>
                  <input
                    type="text"
                    id="Event_city"
                    name="Event[city]"
                    className="form-control rounded-0 shadow-none"
                    placeholder="city"
                    value={cityName}
                    onChange={(e) => setcityName(e.target.value)}
                  />
                </div>

                <div className="col-sm-3 pe-0">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    id="Event_country"
                    name="Event[country]"
                    className="form-control rounded-0 shadow-none"
                    placeholder="country"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                  />
                </div>

                <div className="col-sm-2 pe-0">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Zipcode
                  </label>
                  <input
                    type="text"
                    id="Event_zipcode"
                    name="Event[zipcode]"
                    className="form-control rounded-0 shadow-none"
                    placeholder=""
                    value={zipcode}
                    onChange={(e) => setzipcode(e.target.value)}
                  />
                </div>
              </div>

              {/* virtual meet url  */}
              <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                <label
                  htmlFor="vmUrl"
                  className="text-nowrap me-4 text-primary"
                >
                  Virtual Meet URL
                </label>
                <div className="fields-container d-flex flex-fill">
                  <input
                    type="text"
                    id="Event_refurl"
                    name="Event[refurl]"
                    className="form-control border-0 rounded-0 shadow-none"
                    placeholder="https://meet.google.com/my-meet-code"
                    value={virtualUrl}
                    onChange={(e) => setvirtualUrl(e.target.value)}
                  />
                </div>
              </div>

              {/* catagory  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="catagory"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Category
                </label>

                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => categorySelectionHandler(val)}
                />
              </div>

              {/* searching  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="searching"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Searching
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  options={searchingList}
                  value={searchingValue}
                  onChange={(val) => searchingSelectionHandler(val)}
                />{" "}
              </div>

              {/* Interest  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="interest"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Interest
                </label>

                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="w-100"
                  isMulti
                  options={interestList}
                  value={interestValue}
                  onChange={(val) => interestSelectionHandler(val)}
                />
              </div>

              {/* groups  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="events"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Group
                </label>

                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  options={groupList}
                  value={groupValue}
                  onChange={(val) => groupSelectionHandler(val)}
                />
              </div>

              <div className="row col-12 mb-4 mx-0 px-0">
                <div className="col-sm-3 ps-0 pe-2">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Default member role
                  </label>
                  <Select
                    isClearable
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select Role")}
                    options={moduleRoleList}
                    value={defualtRoleValue}
                    onChange={(val) => {
                      roleSelectionHandler(val);
                    }}
                  />
                </div>

                <div className="col-sm-4 ps-0 pe-2">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Website
                  </label>
                  <input
                    type="text"
                    id="Event_web"
                    name="Event[web]"
                    className="form-control rounded-0 shadow-none"
                    placeholder=""
                    value={eventWebsite}
                    onChange={(e) => seteventWebsite(e.target.value)}
                  />
                </div>

                <div className="col-sm-3 ps-0">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Auto membership approval
                  </label>
                  <input
                    type="checkbox"
                    id="Event_autoaccept"
                    name="Event[autoaccept]"
                    className=""
                    checked={autoAccept}
                    onChange={(e) => setautoAccept(e.target.checked)}
                  />
                </div>
              </div>

              <div className="form-row mb-4 ps-0">
                <div className="form-check d-inline-flex align-items-center me-5">
                  <input
                    type="checkbox"
                    className=""
                    checked={isPrivate}
                    onChange={(e) => setisPrivate(e.target.checked)}
                  />
                  <label className="form-check-label text-primary fw-bold ms-2">
                    {" "}
                    Mark as private event
                  </label>
                </div>
              </div>

              {/* event logo  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="featuredImage"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Featured Image
                </label>

                <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                  <label
                    htmlFor="logoUpload"
                    className="text-nowrap me-4 text-primary"
                  >
                    Upload Event banner
                  </label>

                  <div className="fields-container d-flex flex-fill">
                    <input
                      type="file"
                      id="logo_upload"
                      className="form-control border-0 rounded-0 shadow-none"
                      placeholder="JPG, PNG, GIF"
                      name="logo_upload"
                      onChange={bannerUploadHandler}
                    />
                  </div>
                </div>

                {isUploading ? (
                  <div className="ms-2 spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : null}

                {uploadedPreviewImage === "" ? null : (
                  <div className="upload-thumbnail-preview d-flex gap-2">
                    <div className="preview position-relative">
                      <figure>
                        <img
                          src={url.SERVER_URL + uploadedPreviewImage}
                          id="photopreview"
                          height={100}
                        />
                      </figure>

                      <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                        <Link
                          to="#"
                          className="text-white"
                          onClick={() => {
                            setUploadedPreviewImage("");
                            setUploadedImageId(null);
                          }}
                        >
                          <i className="d-block material-icons icon-sm">
                            cancel
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="action-container d-flex align-items-center justify-content-end">
                <Link
                  to="/admin/event/manage"
                  className="btn btn-gray rounded-0 me-3"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={saveUpdateEventHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default EventInfoBody;
