import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseDetailsTabLinks = ({ pageName = "" }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="course_tab_links">
      <div className="tab_links course_tabs">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "coursedetails" && "active"}`}
              to={`/admin/course/save/${id}`}
            >
              <i className="material-icons-outlined">description</i>
              <span>{t("Course Details")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "chapters" && "active"}`}
              to={`/admin/course/chapters/manage/${id}`}
            >
              <i className="material-icons-outlined">table_view</i>
              <span>{t("Chapters")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "lessons" && "active"}`}
              to={`/admin/course/lessons/manage/${id}`}
            >
              <i className="material-icons-outlined">table_view</i>
              <span>{t("Lessons")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "members" && "active"}`}
              to={`/admin/course/membership/${id}`}
            >
              <i className="material-icons-outlined">group</i>
              <span>{t("Team")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "subsriber" && "active"}`}
              to={`/admin/course/subscribers/list/${id}`}
            >
              <i className="material-icons-outlined">groups</i>
              <span>{t("Subscribers")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pageName === "subscribertasks" && "active"
              }`}
              to={`/admin/course/lessons/subscribertasks/${id}`}
            >
              <i className="material-icons-outlined">task</i>
              <span>{t("Task Submitted")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${pageName === "faq" && "active"}`}
              to={`/admin/course/subscribers/questions/${id}`}
            >
              <i className="material-icons-outlined">help_outline</i>
              <span>{t("Questions Asked")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pageName === "certifications" && "active"
              }`}
              to={`/admin/course/subscribers/certification/${id}`}
            >
              <i className="material-icons-outlined">workspace_premium</i>
              <span>{t("Certification")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseDetailsTabLinks;
