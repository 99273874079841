import React from "react";
import { Link } from "react-router-dom";

const ListMemberHeader = ({
  listTitle = "",
  t,
  allMembers = [],
  removeMemberHandler = () => {},
  reloadData = () => {},
}) => {
  return (
    <div className="filter-container mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <p className="text-primary">
          Members added to List :{" "}
          <span className="fw-bold">
            {" "}
            {listTitle} ({allMembers.length} {t("registered")} )
          </span>
        </p>

        <div className="d-flex align-items-center gap-1">
          <button
            type="button"
            className="btn btn-outline-info rounded-0 shadow-none me-3"
            onClick={reloadData}
          >
            <i className="material-icons">refresh</i>
          </button>
          <div className="dropdown">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={removeMemberHandler}
                >
                  {t("Remove")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListMemberHeader;
