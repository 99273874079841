/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import SaveModuleHeader from "./Header/SaveModuleHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import { reactSelectStyle } from "Config/Config";

const SaveModuleBody = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const { id } = useParams();
  const history = useHistory();

  const [parentModules, setparentModules] = useState([]);

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isShowInPrivilege, setIsShowInPrivilege] = useState(false);
  const [parentValue, setParentValue] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //parent selection
  const parentSelectionHandler = (val) => {
    setValidation((prevState) => ({
      ...prevState,
      parentwarning: false,
    }));
    if (val) {
      setParentValue(val);
      setParentId(val.value);
    } else {
      setParentValue(null);
      setParentId(null);
    }
  };

  //function for get all modules
  const getAllModules = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_MODULES + `?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setparentModules(response.data.filter((item) => item.isroot));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get details
  const getDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_MODULE_DETAILS +
        `/${id}` +
        `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setName(response.data.name);
        setSlug(response.data.slug);
        setIsShowInPrivilege(response.data.isprivilege);
        parentSelectionHandler(response.data.parentdata);
        setIsFeatured(response.data.isfeatured);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveModuleHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let sendingData = {
          name,
          slug,
          isroot: parentId ? false : true,
          parent: parentId ?? null,
          isfeatured: isFeatured,
          isprivilege: isShowInPrivilege,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (id) {
          requestURL =
            requestURL + url.API_UPDATE_MODULE + `/${id}` + `?token=${token}`;

          response = await putData(requestURL, sendingData);
        } else {
          requestURL = requestURL + url.API_CREATE_MODULE + `?token=${token}`;

          response = await postData(requestURL, sendingData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            history.push("/admin/module/manage");
          }, 1000);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setAlertMessage("Please fill all the required fields");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllModules();
  }, []);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />
      <SaveModuleHeader />

      {/* <!-- ========== Start save Section ========== --> */}
      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <div id="event_info_form_wrapper" className="p-4 bg-gray-100">
            <form name="Module" method="post" id="categoryForm">
              <div className="form-row mb-4">
                <label for="type" className="d-block fw-bold text-primary mb-3">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter name")}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      nameWarning: false,
                    }));
                  }}
                />
                {validation.nameWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter label")}!</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="form-row mb-4">
                <label for="type" className="d-block fw-bold text-primary mb-3">
                  {t("Slug")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter slug")}
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      slugWarning: false,
                    }));
                  }}
                />
                {validation.slugWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter slug")}!</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="form-row mb-4">
                <label for="type" className="d-block fw-bold text-primary mb-3">
                  {t("Parent")}
                </label>
                <Select
                  styles={reactSelectStyle("6px", "4px")}
                  components={{ IndicatorSeparator: () => null }}
                  isClearable
                  placeholder={t("Select Parent")}
                  options={parentModules}
                  value={parentValue}
                  onChange={(val) => parentSelectionHandler(val)}
                />
              </div>

              <div className="form-row mb-4">
                <label for="type" className="d-block fw-bold text-primary mb-3">
                  {t("Featured")}
                </label>
                <input
                  type="checkbox"
                  className=""
                  checked={isFeatured}
                  onChange={(e) => setIsFeatured(e.target.checked)}
                />
              </div>

              <div className="form-row mb-4">
                <label for="type" className="d-block fw-bold text-primary mb-3">
                  {t("Show in privilege")}
                </label>
                <input
                  type="checkbox"
                  className=""
                  checked={isShowInPrivilege}
                  onChange={(e) => setIsShowInPrivilege(e.target.checked)}
                />
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="btn btn-yellow rounded-0 m-0 me-1"
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                    onClick={saveModuleHandler}
                  >
                    {t("Save")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>

                  <Link
                    to="/admin/module/manage"
                    className="btn btn-grey rounded-0 m-0"
                  >
                    {t("Cancel")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <!-- ========== End save Section ========== --> */}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </>
  );
};

export default SaveModuleBody;
