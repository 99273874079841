/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as url from "Helper/UrlHelper";

const EditLessonContentModal = ({
  selectedFileType = "",
  uploadedContentFileId = null,
  uploadedContentFilePath = "",
  uploadedContents = [],
  setuploadedContents = () => {},
  onModalClose = () => {},
}) => {
  const { t } = useTranslation();

  const [title, settitle] = useState("");
  const [label, setlabel] = useState("");
  const [order, setorder] = useState("");
  const [description, setdescription] = useState("");

  const addToLessonHandler = () => {
    const contentObj = {
      fileid: uploadedContentFileId,
      filepath: uploadedContentFilePath,
      filetype: selectedFileType,
      title,
      label,
      order,
      description,
    };

    console.log(contentObj);

    setuploadedContents([...uploadedContents, contentObj]);

    onModalClose();

    let currentModal = document.querySelector("#modalSaveLessonContent");
    let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
    bootstrapModal.hide();

    resetState();
  };

  const resetState = () => {
    settitle("");
    setlabel("");
    setorder("");
    setdescription("");
  };

  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="modalSaveLessonContent"
        style={{ display: "none" }}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  onModalClose();
                  resetState();
                }}
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body edit_modalbody">
              <div className="srvay_feedhdng">
                <h2>{t("Add Content")}</h2>
              </div>

              <div className="edit_fillOuter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{t("Uploaded File")}</label>
                      {selectedFileType === "video/*" ? (
                        <div className="video_upload uploadContentContainer">
                          <video
                            width="180"
                            height="140"
                            controls=""
                            src={url.SERVER_URL + uploadedContentFilePath}
                            id="formvideopath"
                          >
                            {t("Your browser does not support the video tag")}.
                          </video>
                        </div>
                      ) : (
                        <div className="pdf_upload uploadContentContainer">
                          <img
                            height={100}
                            src={
                              selectedFileType === "application/pdf"
                                ? "/images/pdf-thumb.png"
                                : selectedFileType ===
                                  "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                ? "/images/doc-thumb.png"
                                : selectedFileType ===
                                  "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                ? "/images/ppt-thumb.png"
                                : selectedFileType ===
                                  "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                ? "/images/ppt-thumb"
                                : ""
                            }
                            className=""
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{"Name"}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="formvideotitle"
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{"Label"}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="formvideotitle"
                        value={label}
                        onChange={(e) => setlabel(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{t("Order")}</label>
                      <input
                        type="number"
                        className="form-control"
                        id="formvideorank"
                        value={order}
                        onChange={(e) => setorder(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill description_fill">
                      <label>{"Details"}</label>
                      <textarea
                        className="form-control "
                        rows={4}
                        value={description}
                        onChange={(e) => setdescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="submit_survay">
                    <button
                      type="button"
                      className="basic_btn ylw_btn"
                      onClick={addToLessonHandler}
                    >
                      {t("Add to Lesson")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLessonContentModal;
