/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import TaskDetailsPopup from "./Popup/TaskDetailsPopup";

const SubmittedTaskBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId } = useParams();

  const [title, settitle] = useState("");
  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, settaskList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setselectedTaskIds] = useState([]);
  const [selectedTaskId, setselectedTaskId] = useState(null);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${courseId}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Course"), link: "/admin/course/manage" },
          { title: response.data.title },
          {
            title: t("Task Assigned to Subscribers"),
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //get all submitted tasks
  const getAllSubmittedTasks = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_TASKS +
        `?token=${token}&filtercourse=${courseId}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        settaskList(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
      getAllSubmittedTasks();
    }
  }, [courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("emailpopup_open");
              setselectedTaskId(row.original._id);
            }}
          >
            <span className="d-block material-icons-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Task"),
      },
      {
        accessorKey: "submittedusername",
        header: t("Subscriber"),
      },
      {
        accessorKey: "lessonname",
        header: t("Lesson"),
      },
      {
        accessorKey: "taskstartdate",
        header: t("Start Date"),
      },
      {
        accessorKey: "tasksubmitdate",
        header: t("Submitted Date"),
      },
      {
        accessorKey: "approvalstatus",
        header: t("Review Status"),
        Cell: ({ row }) => {
          if (row.original.completedpercent === "3") {
            return (
              <p className="d-flex align-items-center fw-medium approved">
                <i className="material-icons me-2 mb-1">check_circle_outline</i>
                <span>Approved</span>
              </p>
            );
          } else {
            return (
              <p className="d-flex align-items-center fw-medium in-progress">
                <i className="material-icons me-2">access_time</i>
                <span>In Progress</span>
              </p>
            );
          }
        },
      },
      {
        accessorKey: "taskreviewdate",
        header: t("Review Date"),
      },
    ],
    [taskList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
  }, [rowSelection]);

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="subscribertasks" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 400,
                    size: 100, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* popup  */}
      <TaskDetailsPopup
        selectedTaskId={selectedTaskId}
        setselectedTaskId={setselectedTaskId}
        afterpopupClose={getAllSubmittedTasks}
        setShowAlert={setShowAlert}
        setMessageType={setMessageType}
        setAlertMessage={setAlertMessage}
      />

      {/* --------- alert popup ------------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CourseCommonLayout>
  );
};

export default SubmittedTaskBody;
