/* eslint-disable */
import { useState, createContext, useEffect } from "react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData } from "Utils/Gateway";

export const GlobalProvider = createContext();

const GlobalContext = (props) => {
  let screenHeight = window.innerHeight;
  const token = localStorage.getItem("token");

  const [tagListReload, settagListReload] = useState(false);

  const contextValue = {
    /* ============== screen height ============================*/
    screenHeight,

    /**============= tag list reload =========================== */
    tagListReload,
    settagListReload,
  };

  return (
    <GlobalProvider.Provider value={contextValue}>
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
