/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import BlogListHeader from "./Header/BlogListHeader";

const BlogListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const breadCrumbText = [
    { title: t("CMS"), link: "/admin/blog/post/list" },
    { title: t("List Post"), link: "#" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedBlogIds, setSelectedBlogIds] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedBlogList = [...blogList];
    const rowIndex = updatedBlogList.findIndex(
      (blog) => blog._id === row.original._id
    );
    if (rowIndex !== -1) {
      updatedBlogList[rowIndex].rank = value;
      setBlogList(updatedBlogList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (action = "") => {
    if (selectedBlogIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = blogList.filter((item) =>
          selectedBlogIds.includes(item._id)
        );

        let apiData = {
          bloglist: listToBeUpdated,
        };

        if (action === "delete") {
          apiData.status = "0";
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_BLOG + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllBlogs();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one blog");
    }
    setShowAlert(true);
  };

  //function for get all blogs
  const getAllBlogs = async () => {
    setRowSelection({});
    setSelectedBlogIds([]);
    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_BLOG + `?token=${token}`;

      console.log("url of all blogs------->", requestURL);

      const response = await getData(requestURL);

      console.log("blog list response------>", response);

      if (response.status) {
        setIsLoading(false);
        setBlogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "name",
      header: t("Name"),
      size: 300,
    },

    {
      accessorKey: "rank",
      header: t("Rank"),
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <input
            type="number"
            className="form-control rounded-0 shadow-none bg-white"
            value={row.original.rank}
            onChange={(e) => rankInputChangeHandler(row, e.target.value)}
          />
        </div>
      ),
    },
    {
      accessorKey: "authorname",
      header: t("Author"),
      size: 200,
      Cell: ({ row }) => (
        <div className="user d-flex align-items-center">
          <div className="profile d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#">
                <img
                  height={35}
                  width={35}
                  src={
                    row.original.authorimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.authorimage
                  }
                  className="img-fluid lead-img rounded-circle"
                />
              </Link>
            </div>

            <p className="name">{row.original.authorname}</p>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "categorystring",
      header: t("Category"),
      size: 150,
      Cell: ({ row }) => {
        return (
          <div className="">
            {row.original.categorylist.map((category, index) => {
              return (
                <p key={index} className="text-primary">
                  {category.name}{" "}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      accessorKey: "publish",
      header: t("Status"),
      Cell: ({ row }) => (
        <div>
          {row.original.publish === "1" ? (
            <div className="status_info complete_txt d-flex align-items-center gap-1">
              <i className="material-icons-outlined fs-5">check_circle</i>
              <span className="">Published</span>
            </div>
          ) : (
            <div className="status_info ylw_txt d-flex align-items-center gap-1">
              <i className="material-icons-outlined fs-5">save</i>
              <span className="">Saved</span>
            </div>
          )}
        </div>
      ),
    },
    {
      accessorKey: "isprivate",
      header: t("Type"),
      Cell: ({ renderedCellValue, row }) => (
        <div>
          {row.original.isprivate ? (
            <span className="text-danger">Private</span>
          ) : (
            <span className="text-success">Public</span>
          )}
        </div>
      ),
    },
    {
      accessorKey: "blogdate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [blogList, i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedBlogIds(selectedIdsArray);
    } else {
      setSelectedBlogIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <BlogListHeader updateBulkHandler={updateBulkHandler} />

          <div className="table-wrapper mb-5">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={blogList} // data from api to be displayed
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{
                rowSelection,
              }} //pass our managed row selection state to the table to use
              initialState={{
                rowSelection,
              }}
              enableRowActions // show the action column
              enableColumnOrdering={true}
              renderRowActionMenuItems={({ row }) => {
                const menuItems = [
                  <MenuItem key="edit">
                    <Link
                      className="dropdown-item"
                      to={`/admin/blog/post/update/${row.id}`}
                    >
                      {t("Edit")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="members">
                    <Link className="dropdown-item" to={`#`}>
                      {t("View Post")}
                    </Link>
                  </MenuItem>,
                ];

                return menuItems;
              }} // action columns menu items
              //   onColumnVisibilityChange={onColumnVisiblityHandler}
              //   onColumnOrderChange={changeColumnOrderHandler}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by name / moderator"),
              }}
            />
          </div>
        </div>
      </section>

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default BlogListBody;
