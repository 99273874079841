/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import CommonLayout from "Components/Common/Layout/CommonLayout";
import ChallengeListHeader from "../Header/ChallengeListHeader";
import ChallengeListFilterModal from "../Modal/ChallengeListFilterModal";

const ChallengeListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterRank, setfilterRank] = useState("");

  const [isRefresh, setIsRefresh] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get course list
  const getAllChallenges = async () => {
    setRowSelection({});
    setSelectedJobIds([]);

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE +
        `?token=${token}&modulefrom=backend`;

      if (filterRank != "") {
        requestURL += "&filterrank=" + filterRank;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle != "") {
        requestURL += `&filtertitle=${filterTitle}`;
      }

      if (filterLocation != "") {
        requestURL += `&filterlocation=${filterLocation}`;
      }

      if (filterSkills.length > 0) {
        requestURL += `&filterskills=${filterSkills.join(",")}`;
      }

      console.log("Job url", requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setJobList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...jobList];

    const rowIndex = updatedList.findIndex(
      (job) => job._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].updatedrank = value;
      setJobList(updatedList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (action = "") => {
    if (selectedJobIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdate = jobList.filter((item) =>
          selectedJobIds.includes(item._id)
        );

        let apiData = {
          challengelist: listToBeUpdate,
        };

        if (action === "delete") {
          apiData.status = "0";
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_CHALLENGE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllChallenges();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one job for execution.");
    }
    setShowAlert(true);
  };

  //reset filter
  const resetFilter = () => {
    setFilterTitle("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterSkills([]);
    setFilterLocation("");
    setfilterRank("");

    setIsRefresh(true);
  };

  useEffect(() => {
    if (
      filterRank !== "" ||
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterTitle !== "" ||
      filterLocation !== "" ||
      filterSkills.length > 0
    ) {
      getAllChallenges();
    }
  }, [
    filterRank,
    filterStartDate,
    filterEndDate,
    filterTitle,
    filterLocation,
    filterSkills,
  ]);

  useEffect(() => {
    if (isRefresh) {
      getAllChallenges();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getAllChallenges();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "title",
      header: t("Name"),
      size: 200,
      Cell: ({ row }) => (
        <div>
          <p className="text-primary mb-3">{row.original.title}</p>

          <div className="d-flex align-items-center mt-1">
            {row.original.rank === 0 ? (
              <p className="d-flex align-items-center fw-medium  reviewing">
                <i className="material-icons me-2">access_time</i>
                <span>Pending Publish</span>
              </p>
            ) : (
              <p className="d-flex align-items-center fw-medium  text-success">
                <i className="material-icons me-2">check_circle_outline</i>
                <span>Published</span>
              </p>
            )}
          </div>
        </div>
      ),
    },
    {
      accessorKey: "jobcode",
      header: t("C Code"),
      size: 150,
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="number"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.updatedrank ?? row.original.rank}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "moderatorname",
      header: t("moderator"),
    },
    {
      accessorKey: "reviewtotal",
      header: t("Status Count"),
      size: 150,
      Cell: ({ row }) => (
        <div className="status">
          <p className="mb-2">
            <Link to="#" className="d-inline-block text-primary me-3">
              Submitted
            </Link>
            <span>{row.original.challengeresponsecount}</span>
          </p>

          <p className="mb-2">
            <Link to="#" className="d-inline-block text-success me-3">
              Accepted
            </Link>
            <span>{row.original.acceptedresponsecount}</span>
          </p>

          <p className="mb-2">
            <Link to="#" className="d-inline-block text-warning me-3">
              Reviewing
            </Link>
            <span>{row.original.pendingresponsecount}</span>
          </p>

          <p>
            <Link to="#" className="d-inline-block text-danger me-3">
              Rejected
            </Link>
            <span>{row.original.rejectedresponsecount}</span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "requiredskillstring",
      header: t("Skills"),
      size: 150,
      Cell: ({ row }) => (
        <div className="">
          {row.original.requiredskilllist.map((skill, index) => {
            return (
              <p key={index} className="text-primary">
                {skill.name}{" "}
              </p>
            );
          })}
        </div>
      ),
    },
    {
      accessorKey: "taskcount",
      header: t("Process"),
      size: 150,
      Cell: ({ row }) => (
        <div className="">
          {row.original.requiredskilllist.map((skill, index) => {
            return (
              <p key={index} className="text-primary">
                {skill.name}{" "}
              </p>
            );
          })}
        </div>
      ),
    },
    {
      accessorKey: "postededdate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [jobList, i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedJobIds(selectedIdsArray);
    } else {
      setSelectedJobIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={[{ title: t("Challenges") }]} />
          <ChallengeListHeader
            reloadList={resetFilter}
            updateBulkHandler={updateBulkHandler}
          />

          <div className="table-wrapper mb-5">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={jobList} // data from api to be displayed
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{
                rowSelection,
              }} //pass our managed row selection state to the table to use
              initialState={{
                rowSelection,
              }}
              enableRowActions // show the action column
              enableColumnOrdering={true}
              renderRowActionMenuItems={({ row }) => {
                const menuItems = [
                  <MenuItem key="edit">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/save/${row.id}`}
                    >
                      {t("Edit")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="process">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/phases/${row.id}`}
                    >
                      {t("Process")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="docs">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/addmedia/${row.id}`}
                    >
                      {t("Docs")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="members">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/members/${row.id}`}
                    >
                      {t("Members")}
                    </Link>
                  </MenuItem>,
                  <MenuItem key="responses">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/responses/report/${row.id}`}
                    >
                      {t("Responses")}
                    </Link>
                  </MenuItem>,
                ];

                return menuItems;
              }} // action columns menu items
              //   onColumnVisibilityChange={onColumnVisiblityHandler}
              //   onColumnOrderChange={changeColumnOrderHandler}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by name / moderator"),
              }}
            />
          </div>
        </div>
      </section>

      <ChallengeListFilterModal
        setfilterRank={setfilterRank}
        setFilterLocation={setFilterLocation}
        setFilterTitle={setFilterTitle}
        setFilterSkills={setFilterSkills}
        setFilterStartDate={setFilterStartDate}
        setFilterEndDate={setFilterEndDate}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeListBody;
