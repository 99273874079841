export const ecosystemSlug = "INDRA";

export const ckEditorConfig = (placeholderText = "") => {
  return {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
    ],
    placeholder: placeholderText,
    shouldNotGroupWhenFull: false,
  };
};

export const joditEditorConfig = () => {
  return {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };
};

export const reactSelectStyle = (
  padding = "0px",
  borderRadius = "0px",
  bgRequired = false,
  showBorder = true
) => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: bgRequired ? "#f4f4f4" : "#fff", // Control background
      border: showBorder ? "1px solid #6F7A7C" : "none",
      borderRadius, // Border radius
      padding,
      boxShadow: "0 !important",
      "&:hover": {}, // Focus shadow effect
    }),
  };
};
