import React from "react";

const SaveModuleActivityModal = () => {
  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="modal_savequestion"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog" data-select2-id="select2-data-11-l5wy">
          <div className="modal-content" data-select2-id="select2-data-10-mcdl">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>
            <div
              className="modal-body edit_modalbody"
              data-select2-id="select2-data-9-6ccs"
            >
              <div className="srvay_feedhdng">
                <h2>Save Module Activity</h2>
              </div>

              <form
                method="post"
                id="savequestion"
                data-select2-id="select2-data-savequestion"
              >
                <div
                  className="edit_fillOuter"
                  data-select2-id="select2-data-8-dp1a"
                >
                  <div className="row" data-select2-id="select2-data-7-if6q">
                    <div
                      className="col-md-12"
                      data-select2-id="select2-data-6-vyyr"
                    >
                      <div
                        className="input_fill white_fill"
                        data-select2-id="select2-data-5-7766"
                      >
                        <label>Module *</label>
                        <div
                          className="model_selectBx"
                          data-select2-id="select2-data-4-ld20"
                        >
                          <select
                            className="select2 select2-hidden-accessible"
                            name="module"
                            id="formModule"
                            required=""
                            data-select2-id="select2-data-formModule"
                            tabindex="-1"
                            aria-hidden="true"
                          >
                            <option
                              value="122"
                              data-select2-id="select2-data-2-pp7w"
                            >
                              Challenges
                            </option>
                            <option
                              value="126"
                              data-select2-id="select2-data-13-86pu"
                            >
                              Forum
                            </option>
                            <option
                              value="16"
                              data-select2-id="select2-data-14-7493"
                            >
                              Event
                            </option>
                            <option
                              value="9"
                              data-select2-id="select2-data-15-dbzp"
                            >
                              Usuario
                            </option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default select2-container--below"
                            dir="ltr"
                            data-select2-id="select2-data-1-uwl6"
                            style={{ width: "auto" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabindex="0"
                                aria-disabled="false"
                                aria-labelledby="select2-formModule-container"
                                aria-controls="select2-formModule-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-formModule-container"
                                  role="textbox"
                                  aria-readonly="true"
                                ></span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span
                              className="dropdown-wrapper"
                              aria-hidden="true"
                            ></span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input_fill white_fill">
                        <label>Activity *</label>
                        <input
                          type="text"
                          name="action"
                          className="form-control"
                          placeholder="Add action name"
                          id="formAction"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input_fill white_fill">
                        <label>Slug *</label>
                        <input
                          type="text"
                          name="slug"
                          className="form-control"
                          placeholder="Enter a slug"
                          id="formSlug"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit_survay">
                        <input type="hidden" name="eaid" id="formActionId" />

                        <button type="submit" className="basic_btn ylw_btn">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModuleActivityModal;
