/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const SaveCategoryBody = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Category"), link: "/admin/category/manage" },
    { title: t("Create Category") },
  ];

  const token = localStorage.getItem("token"); // token

  const [parentbaseCategories, setParentBaseCategories] = useState([]);
  const [levelTwoLoading, setlevelTwoLoading] = useState(false);
  const [levelTwoCategories, setLevelTwoCategories] = useState([]);
  const [levelThreeLoading, setLevelThreeLoading] = useState(false);
  const [levelThreeCategories, setLevelThreeCategories] = useState([]);
  const [levelFourLoading, setlevelFourLoading] = useState(false);
  const [levelFourCategories, setLevelFourCategories] = useState([]);

  const [selectedBaseCategoryId, setselectedBaseCategoryId] = useState(null);
  const [selectedLevelTwoCategoryId, setselectedLevelTwoCategoryId] =
    useState(null);
  const [selectedLevelThreeCategoryId, setselectedLevelThreeCategoryId] =
    useState(null);
  const [selectedLevelFourCategoryId, setselectedLevelFourCategoryId] =
    useState(null);

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isRoot, setIsRoot] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    parentwarning: false,
  });

  //function for get all base categories
  const getAllCategoryList = async (parentId = null, parentColumnSlug = "") => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_CATEGORIES + `?token=${token}`;

      if (parentId === null) {
        requestURL += `&filterisroot=true`;
      } else {
        requestURL += `&filterparents=${parentId}`;
        if ((parentId !== null && parentColumnSlug) === "BASE") {
          setlevelTwoLoading(true);
        } else if (parentId !== null && parentColumnSlug === "LEVEL2") {
          setLevelThreeLoading(true);
        } else if (parentId !== null && parentColumnSlug === "LEVEL3") {
          setlevelFourLoading(true);
        }
      }

      const response = await getData(requestURL);

      setlevelTwoLoading(false);
      setLevelThreeLoading(false);
      setlevelFourLoading(false);

      console.log(response);

      if (response.status) {
        if (parentId === null && parentColumnSlug === "") {
          setParentBaseCategories(response.data);
        } else if ((parentId !== null && parentColumnSlug) === "BASE") {
          setLevelTwoCategories(response.data);
        } else if (parentId !== null && parentColumnSlug === "LEVEL2") {
          setLevelThreeCategories(response.data);
        } else if (parentId !== null && parentColumnSlug === "LEVEL3") {
          setLevelFourCategories(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to validate form */
  const validationHandler = () => {
    if (name === "") {
      setAlertMessage(t("Please enter category name"));
      setMessageType("error");
      return false;
    }

    if (slug === "") {
      setAlertMessage(t("Please enter category slug"));
      setMessageType("error");
      return false;
    }

    if (
      !isRoot &&
      !(
        selectedBaseCategoryId ||
        selectedLevelTwoCategoryId ||
        selectedLevelThreeCategoryId ||
        selectedLevelFourCategoryId
      )
    ) {
      setAlertMessage(
        t("Please select parent category or set as root category")
      );
      setMessageType("error");
      return false;
    }

    return true;
  };

  //function for create new tag
  const saveCategoryHandler = async () => {
    // Validate inputs
    if (!validationHandler()) {
      setShowAlert(true);
      return;
    }

    let parentId = null;

    if (selectedLevelFourCategoryId) {
      parentId = selectedLevelFourCategoryId;
    } else if (selectedLevelThreeCategoryId) {
      parentId = selectedLevelThreeCategoryId;
    } else if (selectedLevelTwoCategoryId) {
      parentId = selectedLevelTwoCategoryId;
    } else if (selectedBaseCategoryId) {
      parentId = selectedBaseCategoryId;
    } else {
      parentId = null;
    }

    try {
      // Prepare category data
      const categoryData = {
        name,
        slug: slug.trim(),
        isroot: isRoot,
        parent: parentId,
        isfeatured: isFeatured,
      };

      // console.log(categoryData);

      setIsSaving(true);

      // Construct API request URL
      const requestURL = `${url.API_BASE_URL}${url.API_ADD_CATEGORY}?token=${token}`;

      // Send data to the server
      const response = await postData(requestURL, categoryData);

      // Set success or error states based on the response
      if (response.status) {
        setMessageType("success");
        setAlertMessage(response.message);

        // Redirect after a short delay
        setTimeout(() => {
          history.push("/admin/category/manage");
        }, 1500);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      // Handle errors
      setMessageType("error");
      setAlertMessage(error.message);
    } finally {
      // Ensure state updates happen regardless of success or error
      setIsSaving(false);
      setShowAlert(true);
    }
  };

  //reset all parent categories
  const resetParentHandler = () => {
    setselectedBaseCategoryId(null);
    setselectedLevelTwoCategoryId(null);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    getAllCategoryList(null, "");
  };

  useEffect(() => {
    getAllCategoryList(null, "");
  }, []);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />

            <div className="profile_graybx_item leadsbx_area">
              <div className="leads_outer">
                <div className="leads_row survey_create_row catagory_save_form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input_fill">
                        <label>{t("Name")}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Enter Name")}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              nameWarning: false,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input_fill">
                        <label> Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Enter slug")}
                          value={slug}
                          onChange={(e) => {
                            setSlug(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              slugWarning: false,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="save_check_row">
                <ul className="profile_chkList working_list">
                  <li className="check_slct">
                    <label className="radio-check">
                      <input
                        type="checkbox"
                        checked={isFeatured}
                        onChange={(e) => setIsFeatured(e.target.checked)}
                      />
                      <span></span>
                      <p>Featured</p>
                    </label>
                  </li>
                  <li className="check_slct">
                    <label className="radio-check">
                      <input
                        type="checkbox"
                        checked={isRoot}
                        onChange={(e) => {
                          if (e.target.checked) {
                            resetParentHandler();
                            setValidation((prevState) => ({
                              ...prevState,
                              parentwarning: false,
                            }));
                          }
                          setIsRoot(e.target.checked);
                        }}
                      />
                      <span></span>
                      <p>Set as root category</p>
                    </label>
                  </li>
                  <li className="check_slct">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>Improvment area</p>
                    </label>
                  </li>
                </ul>
              </div>

              {isRoot ? null : (
                <div className="select_parent_area">
                  <div className="input_fill translation_hdng">
                    <label>{t("Map parent categories")}</label>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-2 tree-view">
                      <h4 className="sub-title">Base Categories</h4>
                      <div className="jstree jstree-7 jstree-default">
                        <ul className="jstree-container-ul jstree-children">
                          {parentbaseCategories.map((category, index) => {
                            return (
                              <li
                                className="jstree-node jstree-leaf"
                                key={index}
                              >
                                <Link
                                  className={`jstree-anchor text-gray-600 ${
                                    selectedBaseCategoryId === category._id
                                      ? "jstree-clicked"
                                      : ""
                                  }`}
                                  to="#"
                                  onClick={() => {
                                    getAllCategoryList(category._id, "BASE");
                                    setselectedBaseCategoryId(category._id);
                                    setselectedLevelTwoCategoryId(null);
                                    setselectedLevelThreeCategoryId(null);
                                    setselectedLevelFourCategoryId(null);
                                    // if (name !== "") {
                                    //   setSlug(
                                    //     `${category.slug}_${
                                    //       name === "" ? "" : name.toUpperCase()
                                    //     }`
                                    //   );
                                    // }
                                  }}
                                >
                                  {category.label}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className="col-sm-2 tree-view">
                      <h4 className="sub-title">level 2</h4>
                      <div className="jstree jstree-7 jstree-default">
                        <ul className="jstree-container-ul jstree-children">
                          {levelTwoLoading ? (
                            <li className="jstree-node jstree-leaf">
                              <Link
                                className={`jstree-anchor text-gray-600 `}
                                to="#"
                              >
                                Loading...
                              </Link>
                            </li>
                          ) : (
                            levelTwoCategories.map((category, index) => {
                              return (
                                <li
                                  className="jstree-node jstree-leaf"
                                  key={index}
                                >
                                  <Link
                                    className={`jstree-anchor text-gray-600 ${
                                      selectedLevelTwoCategoryId ===
                                      category._id
                                        ? "jstree-clicked"
                                        : ""
                                    }`}
                                    to="#"
                                    onClick={() => {
                                      getAllCategoryList(
                                        category._id,
                                        "LEVEL2"
                                      );
                                      setselectedLevelTwoCategoryId(
                                        category._id
                                      );
                                      setselectedLevelThreeCategoryId(null);
                                      setselectedLevelFourCategoryId(null);
                                      // if (name !== "") {
                                      //   setSlug(
                                      //     `${category.slug}_${
                                      //       name === "" ? "" : name.toUpperCase()
                                      //     }`
                                      //   );
                                      // }
                                    }}
                                  >
                                    {category.label}
                                  </Link>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="col-sm-2 tree-view">
                      <h4 className="sub-title">level 3</h4>
                      <div className="jstree jstree-7 jstree-default">
                        <ul className="jstree-container-ul jstree-children">
                          {levelThreeLoading ? (
                            <li className="jstree-node jstree-leaf">
                              <Link
                                className={`jstree-anchor text-gray-600 `}
                                to="#"
                              >
                                Loading...
                              </Link>
                            </li>
                          ) : (
                            levelThreeCategories.map((category, index) => {
                              return (
                                <li
                                  className="jstree-node jstree-leaf"
                                  key={index}
                                >
                                  <Link
                                    className={`jstree-anchor text-gray-600 ${
                                      selectedLevelThreeCategoryId ===
                                      category._id
                                        ? "jstree-clicked"
                                        : ""
                                    }`}
                                    to="#"
                                    onClick={() => {
                                      getAllCategoryList(
                                        category._id,
                                        "LEVEL3"
                                      );
                                      setselectedLevelThreeCategoryId(
                                        category._id
                                      );
                                      setselectedLevelFourCategoryId(null);
                                      // if (name !== "") {
                                      //   setSlug(
                                      //     `${category.slug}_${
                                      //       name === "" ? "" : name.toUpperCase()
                                      //     }`
                                      //   );
                                      // }
                                    }}
                                  >
                                    {category.label}
                                  </Link>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="col-sm-2 tree-view">
                      <h4 className="sub-title">level 4</h4>
                      <div className="jstree jstree-7 jstree-default">
                        <ul className="jstree-container-ul jstree-children">
                          {levelFourLoading ? (
                            <li className="jstree-node jstree-leaf">
                              <Link
                                className={`jstree-anchor text-gray-600 `}
                                to="#"
                              >
                                Loading...
                              </Link>
                            </li>
                          ) : (
                            levelFourCategories.map((category, index) => {
                              return (
                                <li
                                  className="jstree-node jstree-leaf"
                                  key={index}
                                >
                                  <Link
                                    className={`jstree-anchor text-gray-600 ${
                                      selectedLevelFourCategoryId ===
                                      category._id
                                        ? "jstree-clicked"
                                        : ""
                                    }`}
                                    to="#"
                                    onClick={() => {
                                      setselectedLevelFourCategoryId(
                                        category._id
                                      );
                                      // if (name !== "") {
                                      //   setSlug(
                                      //     `${category.slug}_${
                                      //       name === "" ? "" : name.toUpperCase()
                                      //     }`
                                      //   );
                                      // }
                                    }}
                                  >
                                    {category.label}
                                  </Link>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="profile_rightBtn survey_btnsOuter">
                <Link
                  to="/admin/category/manage"
                  className="basic_btn cancel_btn"
                >
                  Cancel
                </Link>
                <button
                  className="basic_btn ylw_btn"
                  onClick={saveCategoryHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save Now")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </CommonLayout>
  );
};

export default SaveCategoryBody;
