import React from "react";
import { Link } from "react-router-dom";

const ConversationHeader = ({ resetAction = () => {} }) => {
  return (
    <div className="btns_top_row">
      <div className="btns_left"></div>
      <div className="btns_right">
        {/* <Link className="btn border-0 rounded-0 shadow-none me-1 " to="#">
          <i className="material-icons">tour</i> Help
        </Link> */}

        <Link
          className="btn btn-warning rounded-0 shadow-none border-0"
          to="#"
          onClick={resetAction}
        >
          <i className="material-icons">refresh</i>
        </Link>

        <button
          type="button"
          className="btn btn-info rounded-0 shadow-none"
          data-bs-toggle="modal"
          data-bs-target="#filterConversationModal"
          id="module-filter"
        >
          <i className="material-icons">filter_alt</i>
        </button>

        <div className="new_mailbtn" id="add-new-item">
          <Link
            to="#"
            className="basic_btn grn_btn emailmdl_btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#addNewEmail"
            role="button"
            aria-controls="addNewEmail"
          >
            <i className="material-icons-outlined">add</i>
            <span>Compose Mail</span>
          </Link>
        </div>

        <div className="dropdown" id="module-mass-action">
          <button
            className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
            <li>
              <Link className="dropdown-item" to="/admin/messages/inbox">
                Inbox{" "}
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/admin/messages/sent">
                Sent Mails{" "}
              </Link>
            </li>

            <li>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#addTagModal"
              >
                Add Label
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ConversationHeader;
