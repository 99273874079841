/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import ListMemberHeader from "./Header/ListMemberHeader";
import Loading from "Components/Common/Loading/Loading";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const CrmListMemberBody = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Crm") }, { title: t("List") }];

  const [isLoading, setIsLoading] = useState(false);
  const [listTitle, setListTitle] = useState("");
  const [allMembers, setAllMembers] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get details of list
  const getDetailsOfList = async () => {
    setRowSelection({});
    setSelectedUserIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_LIST_DETAILS + `/${id}?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Member list fetched successfully"));
        setListTitle(response.data.title);
        setAllMembers(response.data.listmembers);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //save function
  const removeMemberHandler = async () => {
    const updatedMembers = allMembers
      .filter((member) => !selectedUserIds.includes(member._id))
      .map((member) => member._id);

    try {
      setIsUpdating(true);

      let listData = {
        contactids: updatedMembers,
      };

      let requestURL =
        url.API_BASE_URL + url.API_UPDATE_A_LIST + `/${id}?token=${token}`;

      const response = await putData(requestURL, listData);

      console.log(response);

      setIsUpdating(false);

      if (response.status) {
        setMessageType("success");
        setTimeout(() => {
          getDetailsOfList();
        }, 1500);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (id) {
      getDetailsOfList();
    }
  }, [id]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "username",
      header: t("Contact"),
      size: 250,
      Cell: ({ row }) => (
        <div className="user d-flex align-items-center">
          <div className="profile d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#">
                <img
                  height={35}
                  width={35}
                  src={
                    row.original.userimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.userimage
                  }
                  className="img-fluid lead-img rounded-circle"
                />
              </Link>
            </div>

            <p className="name">{row.original.username}</p>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "useremail",
      header: t("Email"),
      size: 250,
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
      size: 200,
    },
    {
      accessorKey: "position",
      header: t("Position"),
      size: 250,
    },
    {
      accessorKey: "city",
      header: t("City"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedUserIds(selectedIdsArray);
    } else {
      setSelectedUserIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout
      breadcrumbText={breadcrumbText}
      moduleName="list"
      displayName={listTitle}
    >
      <ListMemberHeader
        listTitle={listTitle}
        t={t}
        allMembers={allMembers}
        removeMemberHandler={removeMemberHandler}
        reloadData={getDetailsOfList}
      />

      <div className="table-wrapper mt-4">
        <MaterialReactTable
          columns={columns} // map columns to be displayed with api data,
          data={allMembers} // data from api to be displayed
          enableGrouping // to enable grouping of column
          enableColumnOrdering={true}
          enableRowSelection // enable showing checkbox
          getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
          onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          state={{
            rowSelection,
          }} //pass our managed row selection state to the table to use
          initialState={{
            rowSelection,
          }}
          //   onColumnVisibilityChange={onColumnVisiblityHandler}
          //   onColumnOrderChange={changeColumnOrderHandler}
          defaultColumn={{
            minSize: 20,
            maxSize: 300,
            size: 50, //make columns wider by default
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: "60vh",
            },
          }}
          enableStickyHeader
          muiSearchTextFieldProps={{
            placeholder: t("Search by Name"),
          }}
        />
      </div>

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default CrmListMemberBody;
