/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const SaveQuestionPopup = ({
  courseTitle = "",
  selectedQuestionId,
  setselectedQuestionId,
  afterPopupCLose,
  setShowAlert,
  setMessageType,
  setAlertMessage,
}) => {
  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { id: courseId } = useParams();

  const [lessonList, setlessonList] = useState([]);

  const [noteTitle, setNoteTitle] = useState("");
  const [noteDetails, setNoteDetails] = useState("");
  const [lessonValue, setlessonValue] = useState(null);
  const [selectedLessonId, setselectedLessonId] = useState(null);
  const [isPrivate, setisPrivate] = useState(false);
  const [askedBy, setaskedBy] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const getAllLessons = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_LIST_OPTIONS +
        `?token=${token}&courseid=${courseId}`;

      const response = await getData(requestURL);

      if (response.status) {
        setlessonList(response.data);
      }
    } catch (error) {}
  };

  const lessonSelectionHandler = (val) => {
    if (val) {
      setlessonValue(val);
      setselectedLessonId(val.value);
    } else {
      setlessonValue(null);
      setselectedLessonId(null);
    }
  };

  //get note details
  const getQuestionDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_NOTE_DETAILS +
        `/${selectedQuestionId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setNoteTitle(response.data.title);
        setNoteDetails(response.data.details);

        setaskedBy(response.data.moderatorname);

        if (response.data?.lessonvalue) {
          lessonSelectionHandler(response.data?.lessonvalue);
        }

        setisPrivate(response.data.isprivate);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save faq
  const saveQuestionHandler = async () => {
    try {
      setIsSaving(true);

      let noteData = {
        notetype: "2",
        title: noteTitle,
        details: noteDetails,
        rank: 1,
        isprivate: isPrivate,
        course: courseId,
        lessonid: selectedLessonId,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (selectedQuestionId) {
        requestURL +=
          url.API_UPDATE_NOTE + `/${selectedQuestionId}?token=${token}`;
        response = await putData(requestURL, noteData);
      } else {
        requestURL += url.API_ADD_NEW_NOTE + `?token=${token}`;
        response = await postData(requestURL, noteData);
      }

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        popupToggle();
        setMessageType("success");

        resetHandler();
        afterPopupCLose();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setNoteTitle("");
    setNoteDetails("");
    setaskedBy("");
    setlessonValue(null);
    setselectedLessonId(null);
    setselectedQuestionId(null);
  };

  useEffect(() => {
    if (selectedQuestionId) {
      getQuestionDetails();
    }
  }, [selectedQuestionId]);

  useEffect(() => {
    if (courseId) {
      getAllLessons();
    }
  }, [courseId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link
                to="#"
                onClick={() => {
                  popupToggle();
                  resetHandler();
                }}
              >
                <i className="material-icons-round"> close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>Respond to Subscriber Question in</h3>
              <Link to="#">{courseTitle}</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="course_form_details_innr ps-3 pe-2">
        <div className="course_detailsbx_item">
          <div className="profile_fillOuter">
            <div className="profile_fill_row course_fill_row">
              <label>Question</label>
              <div className="input_fill description_fill mb-0">
                <textarea
                  className="form-control"
                  name="question"
                  id="selected_question"
                  value={noteTitle}
                  onChange={(e) => setNoteTitle(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Answer</label>
              <div className="input_fill description_fill mb-0">
                <textarea
                  className="form-control"
                  name="answer"
                  id="selected_answer"
                  value={noteDetails}
                  onChange={(e) => setNoteDetails(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Lesson</label>
              <div className="input_fill description_fill mb-0">
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Lesson")}
                  options={lessonList}
                  value={lessonValue}
                  onChange={(val) => lessonSelectionHandler(val)}
                />
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label>Aksed By</label>
              <div className="input_fill mb-0">
                <p className="form-control border-0">{askedBy}</p>
              </div>
            </div>

            <div className="profile_fill_row course_fill_row">
              <label></label>
              <div className="input_fill">
                <div className="profile_rightBtn justify-content-end">
                  <button
                    className="basic_btn ylw_btn"
                    onClick={saveQuestionHandler}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Submit")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveQuestionPopup;
