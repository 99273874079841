/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";

const ChallengeProcessBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  //function for get job details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in job details ----->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: response.data.title },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
    }
  }, [challengeId]);

  const [processes, setProcesses] = useState([
    {
      id: 41,
      title: "Submit Application",
      order: 1,
      location: "Online (Company Website)",
      fromDate: "2025-01-24",
      toDate: "2025-01-28",
    },
    {
      id: 42,
      title: "Application Review",
      order: 2,
      location: "Remote",
      fromDate: "2025-02-01",
      toDate: "2025-02-07",
    },
    {
      id: 43,
      title: "First Interview (Technical)",
      order: 3,
      location: "Virtual (Video Call)",
      fromDate: "2025-03-10",
      toDate: "2025-03-12",
    },
    {
      id: 44,
      title: "Final Interview (HR & Team)",
      order: 4,
      location: "On-Site (San Francisco, CA)",
      fromDate: "2025-03-15",
      toDate: "2025-03-17",
    },
    {
      id: 45,
      title: "Offer & Negotiation",
      order: 5,
      location: "Remote",
      fromDate: "2025-03-20",
      toDate: "2025-03-20",
    },
  ]);

  // Handle delete operation
  const handleDelete = (id) => {
    setProcesses(processes.filter((process) => process.id !== id));
  };

  // Handle edit operation
  const handleEdit = (id) => {
    console.log(`Edit process with id: ${id}`);
    // You can add edit functionality here
  };

  return (
    <CommonLayout>
      <ChallengeDetailsCommonHeader moduleName="process" />

      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="hiring_process_wrapper">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-8">
                <div className="hiring-process-form-container">
                  {/* Saved Phases */}
                  <div className="hiring-process">
                    {processes.map((process) => (
                      <div
                        className="hiring-fields p-4 bg-gray-100 mb-4"
                        key={process.id}
                      >
                        <div className="survey_edit_delbtns mb-2">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                onClick={() => handleDelete(process.id)}
                                aria-label="Delete Phase"
                              >
                                <i className="material-icons-outlined">
                                  delete
                                </i>
                                <span>Delete</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={() => handleEdit(process.id)}
                                aria-label="Edit Phase"
                              >
                                <i className="material-icons-outlined">edit</i>
                                <span>Edit</span>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="row gx-2 mb-3">
                          <div className="col-md-10">
                            <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0">
                              <input
                                type="text"
                                value={process.title}
                                className="form-control shadow-none rounded-0 border-0"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-row d-flex align-items-center border-0">
                              <input
                                type="number"
                                value={process.order}
                                className="form-control shadow-none rounded-0 border-0"
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row gx-2">
                          <div className="col-md-6">
                            <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0">
                              <input
                                type="text"
                                value={process.location}
                                className="form-control shadow-none rounded-0 border-0"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-row d-flex align-items-center border-0">
                              <input
                                type="date"
                                value={process.fromDate}
                                className="form-control border-0 rounded-none shadow-none me-1"
                                disabled
                              />
                              <input
                                type="date"
                                value={process.toDate}
                                className="form-control border-0 rounded-none shadow-none ms-1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Add new Form */}

                  <div className="hiring-process">
                    <div className="hiring-fields p-4 bg-gray-100 mb-4">
                      <div className="row gx-2 mb-3">
                        <div className="col-md-10">
                          <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                            <input
                              type="text"
                              name="title"
                              className="form-control shadow-none rounded-0 border-0"
                              placeholder="Process Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                            <input
                              type="number"
                              name="order"
                              className="form-control shadow-none rounded-0 border-0"
                              min="1"
                              placeholder="Order"
                              value="5"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-md-6">
                          <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                            <input
                              type="text"
                              id="location"
                              name="location"
                              className="form-control shadow-none rounded-0 border-0"
                              placeholder="Location"
                            />
                            <i className="d-block material-icons text-gray-600">
                              my_location
                            </i>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                            <input
                              type="date"
                              name="fromdate"
                              className="form-control border-0 rounded-none shadow-none"
                            />
                            <input
                              type="date"
                              name="todate"
                              className="form-control border-0 rounded-none shadow-none"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <button
                      type="submit"
                      className="btn btn-yellow rounded-0 ms-auto"
                    >
                      <i className="material-icons me-2">save</i> Add Process
                    </button>
                  </div>
                </div>
              </div>

              {/* Right Column (Flow Section) */}
              <div className="col-md-4">
                <div className="hiring-flow h-100 bg-gray-100 p-4">
                  <h6 className="text-primary fw-bold mb-4">Flow</h6>
                  <ul className="flow">
                    {processes.map((process) => (
                      <li key={process.id}>
                        <h6 className="text-primary fw-bold mb-3">
                          {process.title}
                        </h6>
                        <div className="badges d-flex flex-wrap gap-2 mb-3">
                          <span className="location d-flex align-items-center bg-info text-white p-2">
                            <i className="d-block material-icons me-1">
                              location_on
                            </i>{" "}
                            {process.location}
                          </span>
                          <span className="date d-flex align-items-center bg-info text-white p-2">
                            <i className="d-block material-icons me-1">
                              date_range
                            </i>{" "}
                            {process.fromDate} - {process.toDate}
                          </span>
                        </div>
                      </li>
                    ))}
                    <li>
                      <h6 className="fw-bold text-gray">Unnamed</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default ChallengeProcessBody;
