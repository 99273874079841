import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseCommonHeader = () => {
  const { t } = useTranslation();

  return (
    <section className="course_tablinks_outer learning_coursetablinks">
      <div className="container-fluid">
        <div className="tab_links course_tablinks">
          <ul className="nav nav-tabs">
            {/* <li className="nav-item">
              <Link className="nav-link" to="/admin/trainings/manage">
                <i className="material-icons-outlined">library_books</i>
                <span>Training</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link active show" to="/admin/course/manage">
                <i className="material-icons-outlined">school</i>
                <span>{t("Courses")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CourseCommonHeader;
