/* eslint-disable */
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import * as url from "Helper/UrlHelper";

const AuthHeader = () => {
  const menuPanelRef = useRef(null);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //close menu
  const closeMenuPanelHandler = (event) => {
    // if (menuPanelRef.current && !menuPanelRef.current.contains(event.target)) {
    //   // If the click is outside the menu panel, close it
    //   document.body.classList.remove("menu_open");
    // }
    document.body.classList.remove("menu_open");
  };

  // useEffect(() => {
  //   // Adding event listener
  //   document.addEventListener("click", closeMenuPanelHandler);

  //   // Cleanup the event listener on unmount
  //   return () => {
  //     document.removeEventListener("click", closeMenuPanelHandler);
  //   };
  // }, []);

  return (
    <header className="header_outer backend_hdrOuter">
      <div className="container-fluid">
        <div className="hdr_bkendOuter" ref={menuPanelRef}>
          {/* <!-- ========== Start left logo Section ========== --> */}
          <div className="left_logo_bar">
            <div className="menu_bar">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("menu_open");
                }}
              >
                <span className="material-icons-round">menu</span>
              </Link>
            </div>
            <div className="hdr_logo">
              <Link className="navbar-brand" to={url.FRONTEND_BASE_URL}>
                <img src="/images/indra-white-logo.svg" alt="" />
              </Link>
            </div>
          </div>
          {/* <!-- ========== End left logo Section ========== --> */}

          {/* <!-- ========== Start User Unformation Section ========== --> */}
          <div className="right_profile_area">
            <div className="login_menuicon-Outer">
              <div className="loginuser_area">
                <ul>
                  <li className="dropdown">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className="usr_pnlBtn"
                    >
                      <div className="usr_text">
                        Hello{" "}
                        <span>
                          {userInfo?.name} {userInfo?.surname}
                        </span>
                      </div>
                      <div className="usr_pic">
                        <img
                          src={
                            userInfo?.photoimage
                              ? url.SERVER_URL + userInfo?.photoimage?.path
                              : "/images/default-user.png"
                          }
                          alt=""
                        />
                      </div>
                      <span className="material-icons-outlined">
                        expand_more{" "}
                      </span>
                    </Link>
                    <div className="menurightPnl dropdown-menu">
                      <div className="account_topUsr">
                        <div className="account_user_outer">
                          <div className="accont_usrPic">
                            <img
                              src={
                                userInfo?.photoimage
                                  ? url.SERVER_URL + userInfo?.photoimage?.path
                                  : "/images/default-user.png"
                              }
                              alt=""
                            />
                          </div>
                          <div className="accont_owner_name">
                            <h3>
                              {userInfo?.name} {userInfo?.surname}
                            </h3>
                          </div>
                        </div>
                        <div className="logout_btn">
                          <Link to="/logout">
                            <span className="material-icons-outlined">
                              logout
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="afterlogin_menu">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">
                                space_dashboard
                              </i>
                              <span>Dashboard</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="material-icons-outlined">chat</i>
                              <span>My Messages</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/admin/userdetails/manage/personalinfo/${userInfo?._id}`}
                            >
                              <i className="material-icons-outlined">
                                account_circle
                              </i>
                              <span>My profile</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- ========== End User Unformation Section ========== --> */}

          <div className="backend_hdrmenu">
            <div
              className="menu_txt"
              onClick={() => document.body.classList.toggle("menu_open")}
            >
              <i className="material-icons-outlined">arrow_back</i>MENU
            </div>

            {/* <!-- ========== Start left menu Section ========== --> */}
            <div className="menu_pnl">
              <ul>
                {/* <!-- ========== Start dashboard Section ========== --> */}
                <li className="active_menu">
                  <Link to="/admin/dashboard" onClick={closeMenuPanelHandler}>
                    <i className="material-icons-outlined"> home </i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                {/* <!-- ========== End dashboard Section ========== --> */}

                {/* <!-- ========== Start Administration Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">gavel</i>
                    <span>Administration</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/slides/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Slides
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/category/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Categories
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/module/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Module
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/moduleactivity/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Module Activity
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/moduleactivitypoints/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Module Activity Points
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/moduleroles/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Module Roles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/roleprivileges/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Module Privilege By User Roles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/communicationtemplate/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Mail Template
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/courses/uploaded"
                        onClick={closeMenuPanelHandler}
                      >
                        Course Upload
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Administration Section ========== --> */}

                {/* <!-- ========== Start CRM Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">workspace_premium</i>
                    <span>CRM</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/contact/allusers"
                        onClick={closeMenuPanelHandler}
                      >
                        App Users
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/contact/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Contacts
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/messages/inbox"
                        onClick={closeMenuPanelHandler}
                      >
                        Conversations
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/list/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        List
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/tags/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Labels
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End CRM Section ========== --> */}

                {/* <!-- ========== Start Survey Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined"> list_alt </i>
                    <span>Surveys</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="#" onClick={closeMenuPanelHandler}>
                        Questions
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={closeMenuPanelHandler}>
                        Survey
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={closeMenuPanelHandler}>
                        Feedbacks
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Survey Section ========== --> */}

                {/* <!-- ========== Start Group Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined"> forum </i>
                    <span>Group</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/group/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Groups
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/group/create"
                        onClick={closeMenuPanelHandler}
                      >
                        Create Group
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Group Section ========== --> */}

                {/* <!-- ========== Start Event Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">calendar_month</i>
                    <span>Event</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/event/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Events
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/event/create"
                        onClick={closeMenuPanelHandler}
                      >
                        Create Event
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Event Section ========== --> */}

                {/* <!-- ========== Start Challange Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">legend_toggle</i>
                    <span>Challenges</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/challenges/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Challenges - Moderator
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/admin/challenges/managebyrole"
                        onClick={closeMenuPanelHandler}
                      >
                        Challenges - Member
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/admin/challanges/save"
                        onClick={closeMenuPanelHandler}
                      >
                        Create Challenge
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Challange Section ========== --> */}

                {/* <!-- ========== Start Course Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">school</i>
                    <span>Course</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/course/manage"
                        onClick={closeMenuPanelHandler}
                      >
                        Courses
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Course Section ========== --> */}

                {/* <!-- ========== Start Academic certification Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">class</i>
                    <span>Academy Certification</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="#" onClick={closeMenuPanelHandler}>
                        Manage Certification
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End Academic certification Section ========== --> */}

                {/* <!-- ========== Start CMS/Blog Section ========== --> */}
                <li className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown">
                    <i className="material-icons-outlined">article</i>
                    <span>CMS</span>
                    <span className="material-icons-outlined caret">
                      expand_more
                    </span>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/admin/blog/post/list"
                        onClick={closeMenuPanelHandler}
                      >
                        List Post
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/blog/post/create"
                        onClick={closeMenuPanelHandler}
                      >
                        Create Post
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <!-- ========== End CMS/Blog Section ========== --> */}

                {/* <!-- ========== Start Settings Section ========== --> */}
                <li className="">
                  <Link to="/admin/faq/manage" onClick={closeMenuPanelHandler}>
                    <i className="material-icons-outlined">quiz</i>
                    <span>FAQ</span>
                  </Link>
                </li>
                {/* <!-- ========== End Settings Section ========== --> */}
              </ul>
            </div>
            {/* <!-- ========== End left menu Section ========== --> */}
          </div>
        </div>
      </div>

      <div className="overlay"></div>
    </header>
  );
};

export default AuthHeader;
