import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import CourseCommonHeader from "./Header/CourseCommonHeader";

const CourseCommonLayout = ({ children }) => {
  return (
    <>
      <AuthHeader />
      <CourseCommonHeader />
      {children}
    </>
  );
};

export default CourseCommonLayout;
