/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import EventDetailsCommonHeader from "../Common/EventDetailsCommonHeader";
import { downloadFileHandler } from "Helper/CommonHelper/CommonHelper";

const EventGalleryBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: eventId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Event"), link: "/admin/event/manage" },
    { title: "" },
  ]);

  const [imageUploading, setImageUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id

  const [uploadedDoc, setUploadedDoc] = useState(null);

  const [galleryImages, setGalleryImages] = useState([]);
  const [gallerImageIds, setgallerImageIds] = useState([]);

  const [eventDocList, seteventDocList] = useState([]);
  const [eventDocIds, seteventDocIds] = useState([]);

  const [isGallerySaving, setIsGallerySaving] = useState(false);
  const [isDocAdding, setIsDocAdding] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //upload image
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setImageUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setImageUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setUploadedImageId(response.data._id);
        setUploadedPreviewImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("image_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //delete image
  const deleteImageHandler = async (index) => {
    const filteredBlock = [...galleryImages];
    const filterIds = [...gallerImageIds];
    filteredBlock.splice(index, 1);
    filterIds.splice(index, 1);
    setgallerImageIds(filterIds);
    setGalleryImages(filteredBlock);

    try {
      let groupData = {
        gallery: filterIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (eventId) {
        requestURL += url.API_UPDATE_EVENT + `/${eventId}?token=${token}`;
        response = await putData(requestURL, groupData);
      }

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Image deleted successfully"));
        setTimeout(() => {
          setUploadedImageId(null);
          setUploadedPreviewImage("");
          getEventDetails();
        }, 1500);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //delete doc
  const deleteDocHandler = async (index) => {
    const filteredBlock = [...eventDocList];
    const filterIds = [...eventDocIds];

    filteredBlock.splice(index, 1);
    filterIds.splice(index, 1);

    seteventDocIds(filterIds);
    seteventDocList(filteredBlock);

    try {
      let apiData = {
        uploadids: filterIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (eventId) {
        requestURL += url.API_UPDATE_EVENT + `/${eventId}?token=${token}`;
        response = await putData(requestURL, apiData);
      }

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Image deleted successfully"));
        setTimeout(() => {
          getEventDetails();
        }, 1500);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //reset doc
  const resetDoc = () => {
    const file = document.getElementById("eventdocumentupload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${eventId}?token=${token}`;

      console.log("url of group details------>", requestURL);

      const response = await getData(requestURL);

      console.log("response of group details------>", response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Event"), link: "/admin/manage/manage" },
          { title: response.data.title },
        ]);

        setGalleryImages(response.data.gallery);
        setgallerImageIds(response.data.gallery.map((item) => item._id));

        seteventDocIds(response.data.uploadedfileids);
        seteventDocList(response.data.uploadedfiles);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save gallery in event
  const saveGalleryEventHandler = async () => {
    try {
      setIsGallerySaving(true);

      let eventData = {
        gallery: [...gallerImageIds, uploadedImageId],
      };

      console.log(eventData);

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (eventId) {
        requestURL += url.API_UPDATE_EVENT + `/${eventId}?token=${token}`;
        response = await putData(requestURL, eventData);
      }

      setIsGallerySaving(false);

      if (response.status) {
        setMessageType("success");
        setTimeout(() => {
          setUploadedImageId(null);
          setUploadedPreviewImage("");
          getEventDetails();
        }, 1500);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //function for add to event
  const addToEventHandler = async () => {
    try {
      setIsDocAdding(true);

      const filterequestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const fileResponse = await uploadSingleFile(filterequestURL, uploadedDoc);

      if (fileResponse.status) {
        const eventData = {
          uploadids: [...eventDocIds, fileResponse.data._id],
        };

        if (eventId) {
          const requestURL =
            url.API_BASE_URL +
            url.API_UPDATE_EVENT +
            `/${eventId}?token=${token}`;

          const response = await putData(requestURL, eventData);

          setIsDocAdding(false);

          if (response.status) {
            setMessageType("success");
            setTimeout(() => {
              setUploadedDoc(null);
              resetDoc();
              getEventDetails();
            }, 1500);
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        }
      } else {
        setMessageType("error");
        setAlertMessage(fileResponse.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (eventId) {
      getEventDetails();
    } else {
      setBreadCrumbText([
        { title: t("Event"), link: "/admin/event/manage" },
        { title: t("Save") },
        { title: "" },
      ]);
    }
  }, [eventId]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {eventId ? (
        <EventDetailsCommonHeader groupId={eventId} moduleName="gallery" />
      ) : null}

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="event_gallery_wrapper" className="p-4 bg-gray-100">
            <div className="image-upload-container mb-5">
              <label
                htmlFor="featuredImage"
                className="d-block fw-bold text-primary mb-3"
              >
                Gallery Image
              </label>

              <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                <label
                  htmlFor="logoUpload"
                  className="text-nowrap me-4 text-primary"
                >
                  Upload Images
                </label>
                <div className="fields-container d-flex flex-fill">
                  <input
                    type="file"
                    id="image_upload"
                    name="galleryphoto"
                    className="form-control rounded-0 shadow-none"
                    placeholder="JPG, PNG, GIF"
                    accept="image/*"
                    onChange={imageUploadHandler}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={saveGalleryEventHandler}
                  disabled={isGallerySaving ? true : false}
                  style={{
                    cursor: isGallerySaving ? "not-allowed" : "pointer",
                  }}
                >
                  Add to gallery
                  {isGallerySaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>

              {imageUploading ? (
                <div className="ms-2 spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : null}

              {uploadedPreviewImage === "" ? null : (
                <div className="img-container position-relative">
                  <img
                    src={url.SERVER_URL + uploadedPreviewImage}
                    id="photopreview"
                    className="img-fluid"
                  />
                  <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                    <Link
                      to="#"
                      className="text-white"
                      onClick={() => {
                        setUploadedPreviewImage("");
                        setUploadedImageId(null);
                      }}
                    >
                      <i className="d-block material-icons icon-sm">cancel</i>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <div className="gallery-images">
              <h6 className="text-primary fw-bold mb-3">All Gallery Images</h6>

              <ul className="images d-flex flex-wrap">
                {galleryImages.map((galleryImage, index) => (
                  <li className="image" key={index}>
                    <div className="img-container position-relative">
                      <img
                        src={url.SERVER_URL + galleryImage.path}
                        alt="Event Gallery"
                        className="img-fluid"
                        width={600}
                      />
                      <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                        <a
                          href={url.SERVER_URL + galleryImage.path}
                          className="text-white"
                          target="_blank"
                        >
                          <i className="d-block material-icons icon-sm">
                            visibility
                          </i>
                        </a>
                        <Link
                          to="#"
                          className="text-white"
                          onClick={() => {
                            deleteImageHandler(index);
                          }}
                        >
                          <i className="d-block material-icons icon-sm">
                            delete
                          </i>
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="event_gallery_wrapper" className="p-4 bg-gray-100">
            <div className="image-upload-container mb-5">
              <label
                htmlFor="featuredImage"
                className="d-block fw-bold text-primary mb-3"
              >
                Documents
              </label>

              <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                <label
                  htmlFor="logoUpload"
                  className="text-nowrap me-4 text-primary"
                >
                  Upload Document
                </label>
                <div className="fields-container d-flex flex-fill">
                  <input
                    type="file"
                    id="eventdocumentupload"
                    name="eventdocuments"
                    className="form-control rounded-0 shadow-none"
                    onChange={(e) => {
                      setUploadedDoc(e.target.files[0]);
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={addToEventHandler}
                  disabled={isDocAdding ? true : false}
                  style={{
                    cursor: isDocAdding ? "not-allowed" : "pointer",
                  }}
                >
                  Add to Event
                  {isDocAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>

            <div className="gallery-images">
              <h6 className="text-primary fw-bold mb-3">Add Documents</h6>
              <ul className="images d-flex flex-wrap">
                {eventDocList.map((doc, index) => {
                  const fileExtension = doc.name.split(".").pop();

                  const updatedDoc = {
                    ...doc,
                    path: url.SERVER_URL + doc.path,
                  };
                  return (
                    <li className="image" key={index}>
                      <div className="img-container position-relative">
                        <img
                          src={
                            fileExtension === "pdf"
                              ? "/images/pdf.svg"
                              : fileExtension === "xlsx"
                              ? "/images/xls.svg"
                              : "/images/doc.svg"
                          }
                          className="img-fluid docthumb"
                        />

                        <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                          <Link
                            to="#"
                            className="text-white"
                            onClick={() => {
                              downloadFileHandler(updatedDoc);
                            }}
                          >
                            <i className="d-block material-icons icon-sm">
                              download
                            </i>
                          </Link>

                          <Link
                            to="#"
                            className="text-white"
                            onClick={() => deleteDocHandler(index)}
                          >
                            <i className="d-block material-icons icon-sm">
                              delete
                            </i>
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default EventGalleryBody;
