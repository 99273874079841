/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const ViewSubsriberPopup = ({ selectedSubscriberId }) => {
  const { id: courseId } = useParams();

  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  const [courseTitle, setcourseTitle] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [joinedDate, setjoinedDate] = useState("");
  const [completePercent, setcompletePercent] = useState(0);
  const [certificateList, setCertificateList] = useState([]);
  const [totalScore, settotalScore] = useState("");
  const [obtainedScore, setobtainedScore] = useState("");
  const [isCertified, setisCertified] = useState(false);
  const [certificateFeedback, setCertificateFeedback] = useState("-");

  //function for get details of event member
  const getSubscriberDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_SUBSCRIBER_DETAILS +
        `/${selectedSubscriberId}?token=${token}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setcourseTitle(response.data.coursetitle);
        setSubscriberName(response.data.name);
        setjoinedDate(response.data.requestdate);
        setcompletePercent(response.data.completepercent);
        setCertificateList(response.data.certificatelist);
        settotalScore(response.data.subscribertotalscore);
        setobtainedScore(response.data.subscriberobtainedscore);
        setisCertified(response.data.subsribercertified);
        setCertificateFeedback(response.data.certificationcomment || "-");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedSubscriberId) {
      getSubscriberDetails();
    }
  }, [selectedSubscriberId]);

  return (
    <div className="email_poupPnl email_popup_view">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link to="#" onClick={popupToggle}>
                <i className="material-icons-round">close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3>Subscriber Activity</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="table-wrapper course-certification-table">
        <table
          id="course_certification_details"
          className="table display nowrap subsriber_popup_table"
          style={{ width: "100%" }}
        >
          <tbody>
            <tr className="course">
              <th className="text-blue">Course</th>
              <td>
                <span className="text-blue">{courseTitle}</span>
              </td>
            </tr>
            <tr className="Academy">
              <th className="text-blue">Subscriber</th>
              <td>
                <div className="profile d-flex align-items-center">
                  <p className="name resetSubscriber" id="sub_name">
                    {subscriberName}
                  </p>
                </div>
              </td>
            </tr>
            <tr className="total-score">
              <th className="text-blue">Joining Date</th>
              <td>
                <span
                  className="text-blue resetSubscriber"
                  id="sub_joiningdate"
                >
                  {joinedDate}
                </span>
              </td>
            </tr>
            <tr className="total-score">
              <th className="text-blue">Completion %</th>
              <td>
                <span className="text-blue resetSubscriber" id="sub_completion">
                  {completePercent}
                </span>
                %
              </td>
            </tr>
            <tr className="lesson-completed">
              <th className="text-blue">Activity</th>
              <td>
                <table className="inner_course_table">
                  <thead>
                    <tr>
                      <th
                        className="summary fw-bold text-primary"
                        style={{ width: "50%" }}
                      >
                        Lesson
                      </th>
                      <th
                        className="score fw-bold text-primary"
                        style={{ width: "10%" }}
                      >
                        {" "}
                        Finish Date
                      </th>
                      <th
                        className="date-of-approval fw-bold text-primary"
                        style={{ width: "10%" }}
                      >
                        All Task{" "}
                      </th>
                      <th
                        className="date-of-approval fw-bold text-primary"
                        style={{ width: "10%" }}
                      >
                        Accepted Task
                      </th>
                      <th
                        className="date-of-approval fw-bold text-primary"
                        style={{ width: "10%" }}
                      >
                        Total Score{" "}
                      </th>
                      <th
                        className="date-of-approval fw-bold text-primary"
                        style={{ width: "10%" }}
                      >
                        Obtain Score
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-blue resetSubscriber"
                    id="subscribeLessonData"
                  >
                    {certificateList.map((certificate, index) => {
                      return (
                        <tr key={index}>
                          <td>{certificate.title}</td>
                          <td>{certificate.lessoncompletedate}</td>
                          <td>{certificate.totaltaskcount}</td>
                          <td>{certificate.acceptedtaskcount}</td>
                          <td>{certificate.totalscore}</td>
                          <td>{certificate.obtainedscore}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="total-score">
              <th className="text-blue">Subscriber score</th>
              <td>
                <span
                  className="text-blue resetSubscriber"
                  id="subscriberscore"
                >
                  {obtainedScore}
                </span>
              </td>
            </tr>
            <tr className="passing-score">
              <th className="text-blue">Certification approval</th>
              <td>
                {isCertified ? (
                  <span className="passed align-items-center text-success certificationapproval">
                    <i className="material-icons me-1">check_circle</i>Approved
                  </span>
                ) : null}
                <span
                  className="text-blue resetSubscriber ms-5"
                  id="certificationapprovaldate"
                ></span>
              </td>
            </tr>

            <tr className="academy-feedback">
              <th className="text-blue">Certificaton feedback</th>
              <td>
                <p
                  className="feedback resetSubscriber mb-3"
                  id="certificationapprovalcomment"
                >
                  {certificateFeedback}
                </p>
              </td>
            </tr>
            <tr className="action">
              <th></th>
              <td>
                <div className="d-flex flex-wrap justify-content-end py-5 gap-2">
                  <Link
                    to="#"
                    className="btn btn-gray rounded-0 shadow-none cancel"
                  >
                    Cancel
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewSubsriberPopup;
