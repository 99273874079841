/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import EditUploadContentModal from "Components/Common/Modal/EditUploadContentModal";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import GroupDetailsCommonHeader from "../Common/GroupDetailsCommonHeader";

import fileTypeOptions from "data/Prod/FileTypeOptions.json";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

const GroupInfoBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: groupId } = useParams();
  const history = useHistory();

  const smapleFileRef = useRef(null);
  const editor = useRef(null);

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Group"), link: "/admin/group/manage" },
    { title: "" },
  ]);

  const [selectedFileType, setSelectedFileType] = useState("");

  const [contentUploading, setcontentUploading] = useState(false);
  const [uploadedContentFileId, setuploadedContentFileId] = useState(null);
  const [uploadedContentFilePath, setuploadedContentFilePath] = useState("");

  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);
  const [eventList, seteventList] = useState([]);

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [objective, setobjective] = useState("");
  const [joiningRules, setjoiningRules] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);
  const [searchingValue, setsearchingValue] = useState(null);
  const [selectedSearchings, setselectedSearchings] = useState([]);
  const [interestValue, setinterestValue] = useState(null);
  const [selectedInterests, setselectedInterests] = useState([]);
  const [eventValue, seteventValue] = useState(null);
  const [selectedEvents, setselectedEvents] = useState([]);
  const [autoJoin, setautoJoin] = useState(false);
  const [uploadedContents, setuploadedContents] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id

  const [isSaving, setIsSaving] = useState(false);

  const [deleteContentIds, setDeleteContentIds] = useState([]);
  const [removedEvents, setRemovedEvents] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    titlewarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (title == "") {
      setValidation((prevState) => ({ ...prevState, titlewarning: true }));
      isValid = false;
    }

    return isValid;
  };

  const descriptionEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const objectiveEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setobjective(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const joiningRulesEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setjoiningRules(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const descriptionEditorConfig = useMemo(
    () => descriptionEditorCustomConfig,
    []
  );
  const objectiveEditorConfig = useMemo(() => objectiveEditorCustomConfig, []);
  const joiningRulesEditorConfig = useMemo(
    () => joiningRulesEditorCustomConfig,
    []
  );

  // file type selection
  const fileTypeSelectionHandler = (val) => {
    let fileType = "";
    switch (val.value) {
      case "video":
        fileType = "video/*";
        break;
      case "pdf":
        fileType = "application/pdf";
        break;
      case "doc":
        fileType =
          "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "ppt":
        fileType =
          "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      default:
        fileType = "application/pdf";
    }
    setSelectedFileType(fileType); // Update the accept attribute
  };

  // content upload function
  const contentUploadHandler = async (e) => {
    const file = e.target.files[0];

    setcontentUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setcontentUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetContentFile();
        setuploadedContentFileId(response.data._id);
        setuploadedContentFilePath(response.data.path);

        const editContentModal = new bootstrap.Modal(
          document.getElementById("modalSavevideo")
        );
        editContentModal.show();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //reset content file
  const resetContentFile = () => {
    const file = document.getElementById("sampleFileContent");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for change value in content block
  const changeContentBlock = (index, field, value) => {
    const updatedFormValues = uploadedContents.map((block, i) =>
      index === i ? { ...block, [field]: value } : block
    );
    setuploadedContents(updatedFormValues);
  };

  //delete content block
  const onDeleteContentBlock = (index, id) => {
    const filteredBlock = [...uploadedContents];
    filteredBlock.splice(index, 1);
    setuploadedContents(filteredBlock);
    setDeleteContentIds([...deleteContentIds, id]);
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "CAT_GROUP";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all searching
  const getAllSearchingList = async () => {
    try {
      const parentSlug = "CAT_SEARCHING";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setsearchingList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all interest
  const getAllInterestList = async () => {
    try {
      const parentSlug = "CAT_INTEREST";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setinterestList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all events
  const getAllEventsList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&modulefrom=backend`;

      const response = await getData(requestURL);

      if (response.status) {
        seteventList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select categroy
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    setselectedCategories(val.map((item) => item.value));
  };

  //select searchings
  const searchingSelectionHandler = (val) => {
    setsearchingValue(val);
    setselectedSearchings(val.map((item) => item.value));
  };

  //select interest
  const interestSelectionHandler = (val) => {
    setinterestValue(val);
    setselectedInterests(val.map((item) => item.value));
  };

  //select events
  const eventSelectionHandler = (val) => {
    const newSelectedEventValues = val.map((item) => item.value);

    // Find the removed items by comparing previous selectedGroups with the new selected values
    const removed = selectedEvents.filter(
      (item) => !newSelectedEventValues.includes(item)
    );

    // Update removed items state
    if (removed.length > 0) {
      setRemovedEvents((prevRemoved) => [...prevRemoved, ...removed]);
    }

    seteventValue(val);
    setselectedEvents(newSelectedEventValues);
  };

  //upload banner
  const bannerUploadHandler = async (e) => {
    const file = e.target.files[0];

    setImageUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      setImageUploading(false);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setUploadedImageId(response.data._id);
        setUploadedPreviewImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("logo_upload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get group details
  const getgroupDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${groupId}?token=${token}`;

      console.log("url of group details------>", requestURL);

      const response = await getData(requestURL);

      console.log("response of group details------>", response);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Group"), link: "/admin/group/manage" },
          { title: response.data.title },
        ]);

        //assign title
        response.data?.title && settitle(response.data?.title);

        //assign image
        response.data?.grouplogoid &&
          setUploadedImageId(response.data?.grouplogoid);
        setUploadedPreviewImage(response.data?.grouplogoimage);

        //assign details
        setdescription(response.data?.description);

        //assign objective
        setobjective(response.data?.objective);

        //assign joining rules
        setjoiningRules(response.data?.joiningrules);

        //assign zipcode
        setzipCode(response.data?.zipcode);

        //assign city
        setcity(response.data?.city);

        //assign country
        setcountry(response.data?.country);

        //assign location
        setaddress(response.data?.address);

        //assign category
        if (
          response.data?.categorylist &&
          response.data?.categorylist.length > 0
        ) {
          categorySelectionHandler(response.data?.categorylist);
        }

        //assign searchings
        if (
          response.data?.searchinglist &&
          response.data?.searchinglist.length > 0
        ) {
          searchingSelectionHandler(response.data?.searchinglist);
        }

        //assign interests
        if (
          response.data?.interestlist &&
          response.data?.interestlist.length > 0
        ) {
          interestSelectionHandler(response.data?.interestlist);
        }

        setuploadedContents(response.data.uploads);

        //assing auto accept
        setautoJoin(response.data?.autoapproval);

        //assing groups
        if (response.data?.eventlist && response.data?.eventlist.length > 0) {
          eventSelectionHandler(response.data.eventlist);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save group
  const saveGroupHandler = async () => {
    const todayValue = new Date().toISOString().split("T")[0];

    if (validationHandler()) {
      try {
        setIsSaving(true);

        let groupData = {
          title,
          slug: "",
          description,
          address,
          city,
          country,
          zipcode: zipCode,
          objective,
          joiningrules: joiningRules,
          categories: selectedCategories,
          searchings: selectedSearchings,
          interests: selectedInterests,
          uploadedcontent: uploadedContents,
          logoid: uploadedImageId,
          autoapproval: autoJoin,
          createddate: todayValue,
          eventids: selectedEvents,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (groupId) {
          groupData.deletedcontentids = deleteContentIds;
          groupData.deletedevents = removedEvents;

          requestURL += url.API_UPDATE_GROUP + `/${groupId}?token=${token}`;
          response = await putData(requestURL, groupData);
        } else {
          requestURL += url.API_CREATE_GROUP + `?token=${token}`;
          response = await postData(requestURL, groupData);
        }

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            history.push("/admin/group/manage");
          }, 1500);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up the required fields"));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (groupId) {
      getgroupDetails();
    } else {
      setBreadCrumbText([
        { title: t("Group"), link: "/admin/group/manage" },
        { title: t("Save") },
        { title: "" },
      ]);
    }
  }, [groupId]);

  useEffect(() => {
    getAllCategoryList();
    getAllSearchingList();
    getAllInterestList();
    getAllEventsList();
  }, []);

  useEffect(() => {
    if (selectedFileType !== "") {
      smapleFileRef.current.click(); // Trigger the file input click
    }
  }, [selectedFileType]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {groupId ? <GroupDetailsCommonHeader moduleName="details" /> : null}
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="event_info_form_wrapper" className="p-4 bg-gray-100">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* name  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="title"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control rounded-0 shadow-none"
                  placeholder=""
                  value={title}
                  onChange={(e) => {
                    settitle(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      titlewarning: false,
                    }));
                  }}
                />
                {validation.titlewarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter title")}!</span>
                    </p>
                  </div>
                )}
              </div>

              {/* details  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="groupSummary"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Describe about group
                </label>

                <JoditEditor
                  ref={editor}
                  value={description}
                  config={descriptionEditorConfig}
                  onBlur={(newContent) => {
                    setdescription(newContent);
                  }}
                  onChange={(newContent, event) => {}}
                />
              </div>

              {/* address  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="groupSummary"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Address
                </label>
                <textarea
                  className="form-control rounded-0 shadow-none"
                  placeholder=""
                  rows="4"
                  value={address}
                  onChange={(e) => {
                    setaddress(e.target.value);
                  }}
                ></textarea>
              </div>

              <div className="row mb-4">
                {/* city  */}
                <div className="col-md-4">
                  <div className="form-row mb-4 mb-md-0 d-flex align-items-center bg-white border border-gray-600 ps-3">
                    <label
                      htmlFor="zipCode"
                      className="text-nowrap me-4 text-primary"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-0 border-0 border-start shadow-none"
                      placeholder=""
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* country  */}
                <div className="col-md-4">
                  <div className="form-row mb-4 mb-md-0 d-flex align-items-center bg-white border border-gray-600 ps-3">
                    <label
                      htmlFor="zipCode"
                      className="text-nowrap me-4 text-primary"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-0 border-0 border-start shadow-none"
                      placeholder=""
                      value={country}
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* zipcode  */}
                <div className="col-md-4">
                  <div className="form-row mb-4 mb-md-0 d-flex align-items-center bg-white border border-gray-600 ps-3">
                    <label
                      htmlFor="zipCode"
                      className="text-nowrap me-4 text-primary"
                    >
                      Zipcode
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-0 border-0 border-start shadow-none"
                      placeholder=""
                      value={zipCode}
                      onChange={(e) => {
                        setzipCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* objective  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="objective"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Objective
                </label>
                <JoditEditor
                  ref={editor}
                  value={objective}
                  config={objectiveEditorConfig}
                  onBlur={(newContent) => {
                    setobjective(newContent);
                  }}
                  onChange={(newContent, event) => {}}
                />
              </div>

              {/* joining rules  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="objective"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Joining rules
                </label>
                <JoditEditor
                  ref={editor}
                  value={joiningRules}
                  config={joiningRulesEditorConfig}
                  onBlur={(newContent) => {
                    setjoiningRules(newContent);
                  }}
                  onChange={(newContent, event) => {}}
                />
              </div>

              {/* catagory  */}
              <div className="form-row mb-4 d-flex align-items-center bg-white border border-gray-600 ps-3">
                <label
                  htmlFor="catagory"
                  className="text-nowrap me-4 text-primary"
                >
                  Category
                </label>

                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="w-100"
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => categorySelectionHandler(val)}
                />
              </div>

              {/* searching  */}
              <div className="form-row mb-4 d-flex align-items-center bg-white border border-gray-600 ps-3">
                <label
                  htmlFor="catagory"
                  className="text-nowrap me-4 text-primary"
                >
                  Searching
                </label>
                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="w-100"
                  isMulti
                  options={searchingList}
                  value={searchingValue}
                  onChange={(val) => searchingSelectionHandler(val)}
                />{" "}
              </div>

              {/* Interest  */}
              <div className="form-row mb-4 d-flex align-items-center bg-white border border-gray-600 ps-3">
                <label
                  htmlFor="catagory"
                  className="text-nowrap me-4 text-primary"
                >
                  Interest
                </label>

                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="w-100"
                  isMulti
                  options={interestList}
                  value={interestValue}
                  onChange={(val) => interestSelectionHandler(val)}
                />
              </div>

              {/* events  */}
              <div className="form-row mb-4 d-flex align-items-center bg-white border border-gray-600 ps-3">
                <label
                  htmlFor="events"
                  className="text-nowrap me-4 text-primary"
                >
                  Events Associated
                </label>

                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="w-100"
                  isMulti
                  options={eventList}
                  value={eventValue}
                  onChange={(val) => eventSelectionHandler(val)}
                />
              </div>

              {/* auto approval  */}
              <div className="row col-12">
                <div className="col-sm-3 ps-0">
                  <label
                    htmlFor="type"
                    className="d-block fw-bold text-primary mb-3"
                  >
                    Auto membership approval
                  </label>
                </div>
                <div className="col-sm-2 ps-0">
                  <input
                    type="checkbox"
                    className=""
                    checked={autoJoin}
                    onChange={(e) => setautoJoin(e.target.checked)}
                  />
                </div>
              </div>

              {/* upload contents  */}
              <div className="profile_fill_row course_fill_row mt-3">
                <label className="course_attach_content_label">
                  {t("Attach Content")}
                </label>
                <div className="input_fill">
                  <Select
                    placeholder={t("No file type selected")}
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={fileTypeOptions}
                    onChange={(val) => {
                      fileTypeSelectionHandler(val);
                    }}
                  />

                  {contentUploading ? (
                    <div className="fs-6">
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  <input
                    id="sampleFileContent"
                    ref={smapleFileRef}
                    type="file"
                    className="d-none"
                    accept={selectedFileType}
                    onChange={contentUploadHandler}
                  />
                </div>
              </div>

              {/* content mapping here  */}
              <div className="profile_fill_row course_fill_row ">
                <div className="upload_file_outer col-12 mb-4">
                  {uploadedContents.map((item, index) => {
                    return (
                      <div
                        className="video_upload_inr my-2"
                        style={{ width: "100%" }}
                        id="videoblock_5882"
                        key={index}
                      >
                        <div className="video_upload" width="180" height="140">
                          {item.filetype === "video/*" ? (
                            <video
                              controls=""
                              src="http://dev.admin.habitatm.es/uploads/documents/1735015686-sample_vid01.mp4"
                              className="shblock video_upload appendvideopath uploadContentContainer"
                              width="180"
                              height="140"
                            ></video>
                          ) : (
                            <div className="shblock pdf_upload">
                              <img
                                alt=""
                                className="wh-100"
                                height={140}
                                src={
                                  item.filetype === "application/pdf"
                                    ? "/images/pdf-thumb.png"
                                    : item.filetype ===
                                      "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    ? "/images/doc-thumb.png"
                                    : item.filetype ===
                                      "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    ? "/images/ppt-thumb.png"
                                    : item.filetype ===
                                      "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    ? "/images/ppt-thumb"
                                    : ""
                                }
                              />
                            </div>
                          )}

                          <div className="input_fill white_fill">
                            <label className="text-warning">{t("Order")}</label>
                            <input
                              type="number"
                              name="order"
                              className="form-control"
                              placeholder="Order"
                              value={item.order}
                              onChange={(e) => {
                                changeContentBlock(
                                  index,
                                  "order",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div style={{ width: "80%" }}>
                          <div className="input_fill mb-1">
                            <label className="text-warning">{t("Name")}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="title"
                              value={item.title}
                              onChange={(e) => {
                                changeContentBlock(
                                  index,
                                  "title",
                                  e.target.value
                                );
                              }}
                            />
                          </div>

                          <div className="input_fill description_fill mt-3">
                            <label className="text-warning">
                              {t("Details")}
                            </label>
                            <textarea
                              className="form-control appendvideodetails"
                              name="details"
                              value={item.description}
                              onChange={(e) => {
                                changeContentBlock(
                                  index,
                                  "description",
                                  e.target.value
                                );
                              }}
                            ></textarea>
                          </div>
                        </div>

                        <div className="delete_vdo removeblock">
                          <Link
                            to="#"
                            onClick={() => {
                              onDeleteContentBlock(index, item._id);
                            }}
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </Link>
                        </div>

                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* group logo  */}
              <div className="form-row mb-4">
                <label
                  htmlFor="featuredImage"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Featured Image
                </label>

                <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                  <label
                    htmlFor="logoUpload"
                    className="text-nowrap me-4 text-primary"
                  >
                    Upload banner
                  </label>

                  <div className="fields-container d-flex flex-fill">
                    <input
                      type="file"
                      id="logo_upload"
                      className="form-control border-0 rounded-0 shadow-none"
                      placeholder="JPG, PNG, GIF"
                      name="logo_upload"
                      onChange={bannerUploadHandler}
                    />
                  </div>
                </div>

                {imageUploading ? (
                  <div className="ms-2 spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : null}

                {uploadedPreviewImage === "" ? null : (
                  <div className="upload-thumbnail-preview d-flex gap-2">
                    <div className="preview position-relative">
                      <figure>
                        <img
                          src={url.SERVER_URL + uploadedPreviewImage}
                          id="photopreview"
                          height={100}
                        />
                      </figure>

                      <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                        <Link
                          to="#"
                          className="text-white"
                          onClick={() => {
                            setUploadedPreviewImage("");
                            setUploadedImageId(null);
                          }}
                        >
                          <i className="d-block material-icons icon-sm">
                            cancel
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="action-container d-flex align-items-center justify-content-end">
                <Link
                  to="/admin/group/manage"
                  className="btn btn-gray rounded-0 me-3"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={saveGroupHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* modal  */}
      <EditUploadContentModal
        moduleName="group"
        selectedFileType={selectedFileType}
        uploadedContentFileId={uploadedContentFileId}
        uploadedContentFilePath={uploadedContentFilePath}
        uploadedContents={uploadedContents}
        setuploadedContents={setuploadedContents}
        onModalClose={() => {
          setuploadedContentFileId(null);
          setuploadedContentFilePath("");
        }}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default GroupInfoBody;
