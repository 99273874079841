/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";
import ChallengeMemberHeader from "./Header/ChallengeMemberHeader";
import SaveChallengeMemberModal from "./Modal/SaveChallengeMemberModal";

const ChallengeMemberBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  const [memberList, setMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);

  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get job details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in job details ----->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: response.data.title },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllChallengeMembers = async () => {
    setRowSelection({});
    setSelectedMemberIds([]);

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_MEMBERS +
        `?token=${token}&challenge=${challengeId}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
      getAllChallengeMembers();
    }
  }, [challengeId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => {
          if (row.original.role === "Moderator") {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="modal"
                data-bs-target="#saveChallengeMemberModal"
                aria-controls="saveChallengeMemberModal"
                onClick={() => {
                  setSelectedMemberId(row.original._id);
                }}
              >
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: t("Member"),
        size: 300,
        Cell: ({ row }) => (
          <div className="member-profile d-flex align-items-center">
            <div className="img-container me-3">
              <Link to="#">
                <img
                  src={
                    row.original.image == ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.image
                  }
                  className="img-fluid rounded-circle"
                  width="40"
                  height="40"
                />
              </Link>
            </div>
            <div className="info">
              <h6 className="name fw-bold d-flex align-items-center mb-1">
                <Link to="" className="text-primary">
                  {row.original.name}
                </Link>
              </h6>
              <Link to="#" className="email text-gray-600">
                {row.original.email}
              </Link>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "role",
        header: t("Role"),
        size: 200,
      },
      {
        accessorKey: "moderatorstatus",
        header: t("Status"),
        size: 250,
        Cell: ({ row }) => (
          <div className="d-flex gap-3">
            {row.original.moderatorstatus == "" ? (
              <p>-</p>
            ) : (
              <>
                <div className="moderator d-flex align-items-center">
                  <i
                    className={`material-icons ${
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-warning"
                        : "text-gray-600"
                    } me-2`}
                  >
                    {row.original.moderatorstatus == "1"
                      ? "check_circle"
                      : row.original.moderatorstatus == "0"
                      ? "cancel"
                      : ""}
                  </i>
                  <span
                    className={
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    }
                  >
                    {t("Moderator")}
                  </span>
                </div>

                <div className="member d-flex align-items-center text-gray-600">
                  <i
                    className={`material-icons ${
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-warning"
                        : "text-gray-600"
                    } me-2`}
                  >
                    {row.original.memberstatus == "1"
                      ? "check_circle"
                      : row.original.memberstatus == "0"
                      ? "cancel"
                      : ""}
                  </i>
                  <span
                    className={
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-warning"
                        : "text-gray-600"
                    }
                  >
                    {t("Member")}
                  </span>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        accessorKey: "requestdate",
        header: t("Date"),
        size: 200,
      },
    ],
    [memberList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedMemberIds(selectedIdsArray);
    } else {
      setSelectedMemberIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <ChallengeDetailsCommonHeader moduleName="members" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <ChallengeMemberHeader />

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={memberList} // data from api to be displayed
              positionActionsColumn="last"
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
              defaultColumn={{
                minSize: 20,
                maxSize: 400,
                size: 100, //make columns wider by default
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by Member Name"),
              }}
            />
          </div>
        </div>
      </section>

      <SaveChallengeMemberModal
        afterCloseModalHandler={getAllChallengeMembers}
        selectedMemberId={selectedMemberId}
        setSelectedMemberId={setSelectedMemberId}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeMemberBody;
