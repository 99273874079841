import React from "react";
import { Link } from "react-router-dom";

const GroupListHeader = ({
  refreshHandler = () => {},
  deleteHandler = () => {},
  updateBulkHandler = () => {},
}) => {
  return (
    <div className="filter-container mb-3">
      <div className="d-flex justify-content-sm-end align-items-center">
        {/* <a
          className="btn border-0 rounded-0 shadow-none me-1 "
          href="javascript:moduleTour();"
        >
          <i className="material-icons">tour</i> Help
        </a> */}

        <button
          type="button"
          className="btn btn-secondary rounded-0 shadow-none me-2"
          onClick={refreshHandler}
        >
          <i className="material-icons">refresh</i>
        </button>

        <button
          type="button"
          className="btn btn-info rounded-0 shadow-none me-1"
          id="module-filter"
          data-bs-toggle="modal"
          data-bs-target="#groupfilterModal"
        >
          <i className="material-icons">filter_alt</i>
        </button>

        <Link
          to="/admin/group/create"
          className="btn btn-info d-flex align-items-center rounded-0 shadow-none me-3"
          id="add-new-item"
        >
          <i className="material-icons me-2">add</i> Add New
        </Link>

        <div className="dropdown" id="module-mass-action">
          <button
            className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end rounded-0 border-0"
            style={{}}
          >
            <li>
              <Link className="dropdown-item" to="/admin/group/create">
                Add New
              </Link>
            </li>

            <li>
              <Link to="#" className="dropdown-item" onClick={deleteHandler}>
                Delete Selected
              </Link>
            </li>

            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={updateBulkHandler}
              >
                Update rank
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GroupListHeader;
