import React from "react";
import { Link } from "react-router-dom";

const AppUserListHeader = ({
  reloadData = () => {},
  bulkUpdateHandler = () => {},
}) => {
  return (
    <div className="filter-container mb-3">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div className="d-flex justify-content-sm-end align-items-center gap-2">
            <button
              type="button"
              className="btn btn-secondary rounded-0 shadow-none me-1"
              onClick={reloadData}
            >
              <i className="material-icons">refresh</i>
            </button>

            <button
              type="button"
              className="btn btn-info rounded-0 shadow-none"
              data-bs-toggle="modal"
              data-bs-target="#appUserFilter"
            >
              <i className="material-icons">filter_alt</i>
            </button>

            <div className="dropdown">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                <li>
                  <Link className="dropdown-item" to="#">
                    Send Mail
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addTagModal"
                  >
                    Add Label
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addToListModal"
                  >
                    Add to List
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      bulkUpdateHandler("1", true);
                    }}
                  >
                    Update Ranking
                  </Link>
                </li>

                {/* <li>
                  <Link className="dropdown-item" to="#">
                    Connect with me
                  </Link>
                </li> */}

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      bulkUpdateHandler("1", false);
                    }}
                  >
                    Enable User
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      bulkUpdateHandler("0", false);
                    }}
                  >
                    Disable User
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserListHeader;
