/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData } from "Utils/Gateway";
import { ecosystemSlug } from "Config/Config";

const AuthValidateBody = () => {
  let token = localStorage.getItem("token");

  const location = useLocation();

  let searchQuery = queryString.parse(location.search);

  const userToken = searchQuery?.token;
  const redirectTo = searchQuery?.redirectto;

  const validateUserHandler = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_AUTH_VALIDATE +
        `?token=${userToken}&ecosystemslug=${ecosystemSlug}`;

      console.log("request url -->", requestURL);

      const response = await getData(requestURL);

      console.log("response -->", response);

      if (response.status) {
        localStorage.setItem("token", userToken);
        localStorage.setItem("userInfo", JSON.stringify(response.data));

        if (redirectTo && redirectTo == "MY_PROFILE") {
          window.location.href = `/admin/userdetails/manage/personalinfo/${response.data._id}`;
        } else {
          window.location.href = "/admin/dashboard";
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const authHandler = () => {
    try {
      if (token) {
        localStorage.clear();
      }
      validateUserHandler();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    authHandler();
  }, []);

  return null;
};

export default AuthValidateBody;
