/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import ModuleListHeader from "./Header/ModuleListHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const ModuleListBody = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token"); // token

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Module") }, { title: t("Manage") }];

  const [isLoading, setIsLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectModuleIds, setSelectModuleIds] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all modules
  const getAllModules = async () => {
    setRowSelection({});
    setSelectModuleIds([]);

    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_MODULES + `?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setModuleList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedModuleList = [...moduleList];
    const rowIndex = updatedModuleList.findIndex(
      (module) => module._id === row.original._id
    );
    if (rowIndex !== -1) {
      updatedModuleList[rowIndex].rank = parseInt(value);
      setModuleList(updatedModuleList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (action = "") => {
    if (selectModuleIds.length > 0) {
      // setIsUpdating(true);
      try {
        const listToBeUpdate = moduleList.filter((item) =>
          selectModuleIds.includes(item._id)
        );

        let apiData = {
          modulelist: listToBeUpdate,
        };

        if (action === "delete") {
          apiData.status = "0";
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_MODULE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllModules();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one module");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllModules();
  }, []);

  //material table columns array
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
      },
      {
        accessorKey: "slug",
        header: t("Slug"),
      },
      {
        accessorKey: "parentname",
        header: t("Parent"),
      },
      {
        accessorKey: "feturedstring",
        header: t("Featured"),
      },
      {
        accessorKey: "privilegestring",
        header: t("Privilege"),
        Cell: ({ row }) => {
          if (row.original.isprivilege) {
            return (
              <span className="material-icons me-2">check_circle_outline</span>
            );
          }
        },
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ row }) => (
          <input
            type="number"
            className="form-control rounded-0 shadow-none bg-white"
            value={row.original.rank}
            onChange={(e) => rankInputChangeHandler(row, e.target.value)}
          />
        ),
      },
      {
        accessorKey: "#",
        header: t("Ecosystem"),
        Cell: ({ row }) => (
          <span className="material-icons me-2">check_circle_outline</span>
        ),
      },
    ],
    [moduleList, i18n.language]
  );

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectModuleIds(selectedIdsArray);
    } else {
      setSelectModuleIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />
            <ModuleListHeader updateBulkHandler={updateBulkHandler} />

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={moduleList} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                }}
                enableRowActions
                renderRowActionMenuItems={({ row }) => {
                  const menuItems = [
                    <MenuItem key="view">
                      <Link
                        className="dropdown-item"
                        to={`/admin/module/update/${row.id}`}
                      >
                        {t("View Details")}
                      </Link>
                    </MenuItem>,
                  ];

                  return menuItems;
                }}
                // enableColumnOrdering={true}
                //   onColumnVisibilityChange={onColumnVisiblityHandler}
                //   onColumnOrderChange={changeColumnOrderHandler}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Title"),
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </>
  );
};

export default ModuleListBody;
