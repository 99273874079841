/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import JoditEditor from "jodit-react";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

// import mail variables from json file
import mailVariables from "data/Prod/MailVariables.json";

import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import { reactSelectStyle } from "Config/Config";

const SaveMailTemplateBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Mail Template"), link: "/admin/communicationtemplate/manage" },
    { title: t("Save") },
  ];

  /*======== form requirement start ==========*/
  const [mailTemplateName, setMailTemplateName] = useState("");
  const [mailTemplateSlug, setMailTemplateSlug] = useState("");
  const [mailTemplateSubject, setMailTemplateSubject] = useState("");
  const [mailDescription, setMailDescription] = useState(""); //for ck editor
  /*======== form requirement end ==========*/

  //** Hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    subjectWarning: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const editor = useRef(null);

  // const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setMailDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const editorConfig = useMemo(() => editorCustomConfig, []);

  //** Validate function */
  const validationHandler = () => {
    let isValid = true;

    if (mailTemplateName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (mailTemplateSlug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (mailTemplateSubject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //** Function for get template details */
  const getTemplateDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_TEMPLATE_DETAILS +
        `/${id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status && response.data) {
        setMailTemplateName(response.data.name);
        setMailTemplateSlug(response.data.slug);
        setMailTemplateSubject(response.data.subject);
        //   setMailTemplateIsPublic(response.data.ispublic);
        setMailDescription(response.data.description);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //** Function for save mail template */
  const saveMailTemplateHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      try {
        let templateData = {
          name: mailTemplateName,
          slug: mailTemplateSlug,
          subject: mailTemplateSubject,
          description: mailDescription,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (id) {
          requestURL =
            requestURL + url.API_UPDATE_MAIL_TEMPLATE + `/${id}?token=${token}`;

          response = await putData(requestURL, templateData);
        } else {
          requestURL =
            requestURL + url.API_CREATE_MAIL_TEMPLATE + `?token=${token}`;

          response = await postData(requestURL, templateData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          // Redirect after a short delay
          setTimeout(() => {
            history.push("/admin/communicationtemplate/manage");
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up required fields");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (id) {
      getTemplateDetails();
    }
  }, [id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <div className="tab-content" id="challengeDetails_tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-job-info"
              role="tabpanel"
              aria-labelledby="job-info-tab"
              tabIndex="0"
            >
              <BreadCrumb breadCrumbText={breadcrumbText} />

              <div id="job_info_form_wrapper">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mail-body">
                      <div className="mail-body-content">
                        <div className="form-group row  mb-3">
                          <label className="col-sm-2 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              value={mailTemplateName}
                              onChange={(e) => {
                                setMailTemplateName(e.target.value);
                                setValidation((prevState) => ({
                                  ...prevState,
                                  nameWarning: false,
                                }));
                              }}
                            />
                            {/* name warning */}
                            {validation.nameWarning && (
                              <div className="error-message mt-2">
                                <p className="d-flex align-items-center gap-1 text-danger">
                                  <span className="material-icons-outlined">
                                    warning
                                  </span>
                                  <span>
                                    {t("Please enter a template name!")}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-group row  mb-3 ">
                          <label className="col-sm-2 col-form-label">
                            Slug
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              value={mailTemplateSlug}
                              onChange={(e) => {
                                setMailTemplateSlug(e.target.value);
                                setValidation((prevState) => ({
                                  ...prevState,
                                  slugWarning: false,
                                }));
                              }}
                            />
                            {/* stug warning */}
                            {validation.slugWarning && (
                              <div className="error-message mt-2">
                                <p className="d-flex align-items-center gap-1 text-danger">
                                  <span className="material-icons-outlined">
                                    warning
                                  </span>
                                  <span>{t("Please enter a slug!")}</span>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-group row  mb-3">
                          <label className="col-sm-2 col-form-label">
                            Subject
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              value={mailTemplateSubject}
                              onChange={(e) => {
                                setMailTemplateSubject(e.target.value),
                                  setValidation((prevState) => ({
                                    ...prevState,
                                    subjectWarning: false,
                                  }));
                              }}
                            />
                            {/* subject warning */}
                            {validation.subjectWarning && (
                              <div className="error-message mt-2">
                                <p className="d-flex align-items-center gap-1 text-danger">
                                  <span className="material-icons-outlined">
                                    warning
                                  </span>
                                  <span>{t("Please enter a subject!")}</span>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-group  mb-3">
                          <div className="row">
                            <div className="col-md-2">
                              <label className="col-form-label">
                                Mail variables
                              </label>
                            </div>
                            <div className="col-md-5">
                              <Select
                                styles={reactSelectStyle("6.5px", "4px")}
                                placeholder={t("Select Mail Variable")}
                                options={mailVariables}
                                onChange={(event) => {
                                  clipboardCopy(event.value);
                                }}
                              />
                            </div>
                            <div className="col-md-5">
                              <p className="form-control bg-gray-100">
                                {t(
                                  "Select the mail variable you want to add. Next just paste where you want it use it."
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="form-group  mb-3">
                          <JoditEditor
                            ref={editor}
                            value={mailDescription}
                            config={editorConfig}
                            onBlur={(newContent) => {
                              // console.log("New content in on blur----->", newContent);
                              setMailDescription(newContent);
                            }}
                            onChange={(newContent, event) => {
                              // setMailDescription(newContent);
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary m-b-0"
                            onClick={saveMailTemplateHandler}
                            style={{
                              cursor: isSaving ? "not-allowed" : "pointer",
                            }}
                            disabled={isSaving ? true : false}
                          >
                            {t("Save")}
                            {isSaving && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </>
  );
};

export default SaveMailTemplateBody;
