/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CourseCommonLayout from "Components/Course/Common/CourseCommonLayout";
import CourseDetailsTabLinks from "../Common/Header/CourseDetailsTabLinks";
import CourseDetailsBreadcrumb from "../Common/Breadcrumb/CourseDetailsBreadcrumb";
import TaskListHeader from "./Header/TaskListHeader";
import SaveTaskPopup from "./Popup/SaveTaskPopup";

const TaskListBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: courseId, lid: lessonId } = useParams();

  const [title, settitle] = useState("");
  const [breadCrumbText, setBreadCrumbText] = useState([{ title: "-" }]);

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get cuourse details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${lessonId}?token=${token}&filtercourse=${courseId}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setBreadCrumbText([
          {
            title: response.data.coursetitle,
            link: `/admin/course/details/${courseId}`,
          },
          {
            title: t("Lesson"),
          },
          {
            title: response.data.title,
          },
          {
            title: t("Assignments"),
          },
        ]);
        settitle(response.data.title);
      } else {
        setShowAlert(true);
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //get all tasks
  const getAllTask = async () => {
    setSelectedTaskIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSON_TASKS +
        `?token=${token}&courseid=${courseId}&lessonid=${lessonId}`;

      console.log(`getAllTask requestURL =============>>>`, requestURL);

      const response = await getData(requestURL);

      console.log("response in task list------->", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setTaskList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].rank = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for update rank
  const updateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      try {
        const listToBeUpdate = taskList.filter((item) =>
          selectedTaskIds.includes(item._id)
        );

        let apiData = {
          tasklist: listToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_COURSE_LESSON_TASK +
          `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllTask();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select atleast one task"));
    }
    setShowAlert(true);
  };

  //function for delete course
  const deleteMassiveTaskHandler = async () => {
    if (selectedTaskIds.length > 0) {
      const listToBeDelete = taskList.filter((item) =>
        selectedTaskIds.includes(item._id)
      );

      try {
        let apiData = {
          tasklist: listToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_COURSE_LESSON_TASKS +
          `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllTask();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select atleast one task"));
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (lessonId && courseId) {
      getLessonDetails();
      getAllTask();
    }
  }, [lessonId, courseId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("emailpopup_open");
              setSelectedTaskId(row.original._id);
            }}
          >
            <i className="material-icons horz_icon">more_horiz</i>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Name"),
        size: 400,
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        size: 100,
        Cell: ({ row }) => (
          <div>
            <input
              type="number"
              className="form-control rounded-0 shadow-none bg-white"
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />
          </div>
        ),
      },
      {
        accessorKey: "score",
        header: t("Score"),
        size: 400,
      },
      {
        accessorKey: "hours",
        header: t("Duration"),
        size: 400,
      },
    ],
    [taskList, i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CourseCommonLayout>
      <section className="course_form_Area">
        <div className="container-fluid">
          <div className="profile_graybx_Outer">
            <div className="profile_graybx_item">
              <CourseDetailsTabLinks pageName="lessons" />

              <CourseDetailsBreadcrumb breadCrumbText={breadCrumbText} />

              <TaskListHeader
                updateBulkHandler={updateBulkTaskHandler}
                deleteMassiveHandler={deleteMassiveTaskHandler}
              />

              <div className="table-wrapper course-lessons-table">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Task Name"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <SaveTaskPopup
        selectedTaskId={selectedTaskId}
        setSelectedTaskId={setSelectedTaskId}
        afterPopupClose={() => {
          setTimeout(() => {
            getAllTask();
          }, 1500);
        }}
        lessonTitle={title}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
      />

      {/* --------- alert popup ------------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CourseCommonLayout>
  );
};

export default TaskListBody;
