import React from "react";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";

const UserEcoinBody = () => {
  return (
    <AppUserDetailsLayout moduleName="ecoins">
      Some content
    </AppUserDetailsLayout>
  );
};

export default UserEcoinBody;
