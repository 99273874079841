/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import AppUserListHeader from "../Header/AppUserListHeader";
import AppUserListFilterModal from "../Modal/AppUserListFilterModal";
import Loading from "Components/Common/Loading/Loading";
import AddToListModal from "Components/Common/Modal/AddToListModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";

const AppUserListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("CRM") }, { title: t("Users") }];

  const [roleList, setRoleList] = useState([]);

  const [users, setUsers] = useState([]);

  //filter requirements
  const [filterName, setfilterName] = useState("");
  const [filterSurname, setfilterSurname] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterPhone, setfilterPhone] = useState("");
  const [filterCity, setfilterCity] = useState("");
  const [filterRole, setfilterRole] = useState(null);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get user roles

  const getUserRoles = async (userId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_ROLES +
        `?token=${token}&filterfeatured=true`;

      const response = await getData(requestURL);

      if (response.status) {
        setRoleList(response.data);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for getting all users
  const getAllAppUsers = async () => {
    setRowSelection({});
    setSelectedUserIds([]);

    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_APP_USER + `?token=${token}`;

      if (filterName != "") {
        requestURL += `&filtername=${filterName}`;
      }

      if (filterSurname != "") {
        requestURL += `&filtersurname=${filterSurname}`;
      }

      if (filterEmail != "") {
        requestURL += `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestURL += `&filterphone=${filterPhone}`;
      }

      if (filterCity != "") {
        requestURL += `&filtercity=${filterCity}`;
      }

      if (filterRole) {
        requestURL += `&filterrole=${filterRole}`;
      }

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setUsers(response.data);
        resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...users];

    const rowIndex = updatedList.findIndex(
      (user) => user._id.toString() === row.original._id.toString()
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].rank = value;
      setUsers(updatedList);
    }
  };

  //function for bulk update
  const bulkUpdateHandler = async (userStatus = "1", rankOnly = false) => {
    if (selectedUserIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = users.filter((item) =>
          selectedUserIds.includes(item._id)
        );

        let updatedData = {
          userlist: listToBeUpdated,
        };

        if (!rankOnly) {
          updatedData.status = userStatus;
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_USER + `?token=${token}`;

        const response = await putData(requestURL, updatedData);

        setIsUpdating(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllAppUsers();
          }, 1000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one user.");
    }
    setShowAlert(true);
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterSurname("");
    setfilterEmail("");
    setfilterPhone("");
    setfilterCity("");
    setfilterRole(null);
  };

  useEffect(() => {
    getAllAppUsers();
    getUserRoles();
  }, []);

  useEffect(() => {
    if (
      filterName != "" ||
      filterSurname != "" ||
      filterEmail != "" ||
      filterPhone != "" ||
      filterCity != "" ||
      filterRole
    ) {
      getAllAppUsers();
    }
  }, [
    filterName,
    filterSurname,
    filterEmail,
    filterPhone,
    filterCity,
    filterRole,
  ]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "username",
      header: t("Name"),
      size: 300,
      Cell: ({ row }) => (
        <div className="user d-flex align-items-center">
          <div className="profile d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#">
                <img
                  height={35}
                  width={35}
                  src={
                    row.original.userimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.userimage
                  }
                  className="img-fluid lead-img rounded-circle"
                />
              </Link>
            </div>

            <p className="name">
              <span
                className={row.original.status === "0" ? "text-danger" : ""}
              >
                {row.original.username}
              </span>

              {row.original.status === "0" ? (
                <span className="ms-1 material-icons-outlined text-warning">
                  error_outline
                </span>
              ) : row.original.connectstatus ? (
                <span className="ms-1 material-icons-outlined text-success">
                  how_to_reg
                </span>
              ) : null}
            </p>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="text"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.rank}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "email",
      header: t("Email"),
      size: 300,
    },
    {
      accessorKey: "userrole",
      header: t("Role"),
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
    },
    {
      accessorKey: "position",
      header: t("Position"),
      size: 250,
    },
    {
      accessorKey: "city",
      header: t("City"),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      size: 200,
    },
    {
      accessorKey: "registereddate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [users, i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedUserIds(selectedIdsArray);
    } else {
      setSelectedUserIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout breadcrumbText={breadcrumbText} moduleName="appUsers">
      <AppUserListHeader
        reloadData={getAllAppUsers}
        bulkUpdateHandler={bulkUpdateHandler}
      />

      <div className="table-wrapper">
        <MaterialReactTable
          columns={columns} // map columns to be displayed with api data,
          data={users} // data from api to be displayed
          enableGrouping // to enable grouping of column
          enableRowSelection // enable showing checkbox
          getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
          onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          state={{
            rowSelection,
          }} //pass our managed row selection state to the table to use
          initialState={{
            rowSelection,
          }}
          enableRowActions // show the action column
          enableColumnOrdering={true}
          renderRowActionMenuItems={({ row }) => {
            const menuItems = [
              <MenuItem key="edit">
                <Link
                  className="dropdown-item"
                  to={`/admin/userdetails/manage/personalinfo/${row.id}`}
                >
                  {t("Manage Profile")}
                </Link>
              </MenuItem>,
            ];

            return menuItems;
          }} // action columns menu items
          //   onColumnVisibilityChange={onColumnVisiblityHandler}
          //   onColumnOrderChange={changeColumnOrderHandler}
          muiTableContainerProps={{
            sx: {
              maxHeight: "60vh",
            },
          }}
          enableStickyHeader
          muiSearchTextFieldProps={{
            placeholder: t("Search by name / email / phone / location"),
          }}
        />
      </div>

      <AppUserListFilterModal
        roleList={roleList}
        setfilterName={setfilterName}
        setfilterSurname={setfilterSurname}
        setfilterEmail={setfilterEmail}
        setfilterPhone={setfilterPhone}
        setfilterCity={setfilterCity}
        setfilterRole={setfilterRole}
      />

      <AddToListModal
        afterModalClose={() => {
          setTimeout(() => {
            getAllAppUsers();
          }, 2000);
        }}
        selectedIds={selectedUserIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <AddTagModal
        moduleName="appuser"
        selectedIds={selectedUserIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllAppUsers();
          }, 2000);
        }}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default AppUserListBody;
