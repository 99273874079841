import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GroupMemberHeader = ({ updateGroupMember = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="row align-items-center">
      <div className="col-sm-12">
        <div className="filter-container mb-3">
          <div className="d-flex flex-wrap justify-content-md-end align-items-center gap-3">
            <Link
              to="#"
              type="button"
              className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
              data-bs-toggle="modal"
              data-bs-target="#saveGroupMemberModal"
              area-label="Invite saveGroupMemberModal"
            >
              <i className="material-icons me-2">add</i>
              {t("Invite Contact")}
            </Link>

            <div className="dropdown" id="module-mass-action">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end rounded-0 border-0"
                style={{}}
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#saveGroupMemberModal"
                  >
                    Invite Contact
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Send Mail
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      updateGroupMember("1");
                    }}
                  >
                    Approve Selected
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      updateGroupMember("0");
                    }}
                  >
                    Disallow Selected
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupMemberHeader;
