import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseTeamHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="row align-items-center">
      <div className="col-sm-12">
        <div className="filter-container mb-3">
          <div className="d-flex flex-wrap justify-content-md-end align-items-center gap-2">
            <Link
              to="#"
              type="button"
              className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
              data-bs-toggle="modal"
              data-bs-target="#saveCourseMemberModal"
              area-label="Invite Contact"
            >
              <i className="material-icons me-2">add</i>
              {t("Invite Contact")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTeamHeader;
